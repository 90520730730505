import { useEffect } from "react";

function isHTMLElement(element: Element | null): element is HTMLElement {
    return element instanceof HTMLElement;
}

function scrollingElement(): HTMLElement {
    const element = document.scrollingElement || document.documentElement;
    if (isHTMLElement(element)) {
        return element;
    }
    throw new Error("Expected document.scrollingElement or document.documentElement to be an HTMLElement.");
}

function getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
}

function useScrollingElement(trigger: boolean): void {
    useEffect(() => {
        const element = scrollingElement();
        if (trigger) {
            element.style.paddingRight = `${getScrollbarWidth()}px`;
            element.style.overflow = "hidden";
        } else {
            element.style.overflow = "auto";
            element.style.paddingRight = "0";
        }
    }, [trigger]);
}

export default useScrollingElement;
