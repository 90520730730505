import { useState, useEffect, MouseEvent } from "react";

interface PaginationItem {
    id: number;
    current: boolean;
    ellipsis: boolean;
    value: number;
}

interface UsePaginationReturn {
    pagination: PaginationItem[];
    prevPage: (e: MouseEvent<HTMLAnchorElement>) => void;
    nextPage: (e: MouseEvent<HTMLAnchorElement>) => void;
    changePage: (page: number, e?: MouseEvent<HTMLAnchorElement>) => void;
    currentPage: number;
}

const usePagination = (startFrom: number, total: number): UsePaginationReturn => {
    const pagination: PaginationItem[] = [];
    const [currentPage, setCurrentPage] = useState<number>(startFrom);
    let ellipsisLeft = false;
    let ellipsisRight = false;

    for (let i = 0; i < total; i += 1) {
        if (i === currentPage) {
            pagination.push({
                id: i,
                current: true,
                ellipsis: false,
                value: i + 1,
            });
        } else if (i < 2 || i === total - 1 || i === currentPage - 1 || i === currentPage + 1) {
            pagination.push({
                id: i,
                current: false,
                ellipsis: false,
                value: i + 1,
            });
        } else if (i > 1 && i < currentPage && !ellipsisLeft) {
            pagination.push({
                id: i,
                current: false,
                ellipsis: true,
                value: i + 1,
            });
            ellipsisLeft = true;
        } else if (i < total && i > currentPage && !ellipsisRight) {
            pagination.push({
                id: i,
                current: false,
                ellipsis: true,
                value: i + 1,
            });
            ellipsisRight = true;
        }
    }

    useEffect(() => {
        if (total && currentPage === total) {
            setCurrentPage(prev => prev - 1);
        }
    }, [total]);

    const changePage = (page: number, e?: MouseEvent<HTMLAnchorElement>): void => {
        if (e) {
            e.preventDefault();
        }
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const goToPrevPage = (e: MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        setCurrentPage(prevVal => (prevVal === 0 ? prevVal : prevVal - 1));
    };

    const goToNextPage = (e: MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        setCurrentPage(prevVal => (total - 1 === prevVal ? prevVal : prevVal + 1));
    };

    return {
        pagination,
        prevPage: goToPrevPage,
        nextPage: goToNextPage,
        changePage,
        currentPage,
    };
};

export default usePagination;