import AboutUs from "src/static/AboutUs";
import FairnessCalculation from "src/containers/FairnessCalculation/FairnessCalculation";
import ProvablyFair from "src/static/ProvablyFair";
import BitcoinSV from "src/static/BitcoinSV";
import ResponsibleGaming from "src/static/ResponsibleGaming";
import PrivacyPolicy from "src/static/PrivacyPolicy";
import TermsOfService from "src/static/TermsOfService";
import Payments from "src/static/Payments";
import Cookies from "src/static/Cookies";
import Complaints from "src/static/Complaints";
import KycPolicy from "src/static/KycPolicy";

export default [
    {
        path: "/about-us",
        component: <AboutUs />,
        title: "About Us",
        category: "product",
    },
    {
        path: "/fairness-calculation",
        component: <FairnessCalculation />,
        title: "Fairness Calculation",
        category: "product",
        hideTitle: true,
    },
    {
        path: "/provably-fair",
        component: <ProvablyFair />,
        title: "Provably Fair",
        category: "product",
    },
    {
        path: "/bitcoinsv",
        component: <BitcoinSV />,
        title: "Bitcoin SV",
        category: "product",
    },
    {
        path: "/responsible-gaming",
        component: <ResponsibleGaming />,
        title: "Responsible Gaming",
        category: "product",
    },
    {
        path: "/privacy-policy",
        component: <PrivacyPolicy />,
        title: "Privacy Policy",
        category: "service",
    },
    {
        path: "/terms-of-service",
        component: <TermsOfService />,
        title: "Terms of Service",
        category: "service",
    },
    {
        path: "/payments",
        component: <Payments />,
        title: "Payments",
        category: "product",
    },
    {
        path: "/cookies",
        component: <Cookies />,
        title: "Cookies",
        category: "service",
    },
    {
        path: "/complaints",
        component: <Complaints />,
        title: "Complaints",
        category: "service",
    },
    {
        path: "/kyc-policy",
        component: <KycPolicy />,
        title: "AML/KYC Policy",
        category: "service",
    },
];
