import React, { ReactElement } from "react";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";

import IconCopy from "./assets/copy.svg?inline";
import IconLink from "./assets/link.svg?inline";
import copyToClipboard from "src/helpers/copyToClipboard";

interface IDetailProps {
    label: string
    content: string | number | ReactElement,
    inline?: boolean,
    className?: string,
    contentClassName?: string,
    copiable?: boolean,
    href?: string,
    copyText?: string,
}

const cx = classNames.bind(styles);

export default function Detail({
    label,
    inline = false,
    className = "",
    content = "",
    contentClassName = "",
    copiable = false,
    href = "",
    copyText = "",
}: IDetailProps) {
    const labelComponent = <span className={cx("small", "label")}>{label}</span>;

    function getContent() {
        if (React.isValidElement(content)) {
            return content;
        }
        return <span className={`${cx("left")} ${contentClassName}`}>{content}</span>;
    }

    return (
        <div className={`${cx("detail", { inline })} ${className}`}>
            {!inline && labelComponent}
            <div className={cx("content")}>
                {inline && labelComponent}
                {getContent()}
                <div className={cx("right")}>
                    {copiable && (
                        <button type="button" className={cx("button")} onClick={() => copyToClipboard(copyText || "")}>
                            <IconCopy />
                        </button>
                    )}
                    {!!href && (
                        <a href={href} className={cx("button")} target="_blank" rel="noreferrer">
                            <IconLink />
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}
