import { PickByType, OmitByType } from "@ntropy/utils";
import { CryptoCurrency } from "src/core/currency/currency.model";

export const Endpoint = {
    AuthMeV2: "/auth/v2/me",
    Accounts: "/cashier/accounts",
    Logout: "/cashier/auth/logout",
    Rates: "/cashier/rates",
    CryptoChains: "/cashier/currencies/crypto/chains",
    DepositAddress: (currency: CryptoCurrency) => `/cashier/accounts/${currency.toLowerCase()}/depositAddress` as const,
    Withdraw: (currency: CryptoCurrency) => `/cashier/accounts/${currency.toLowerCase()}/withdraw` as const,
    AuthChallenge: "/cashier/auth/challenge",
    AuthChallengeVerify: "/cashier/auth/challenge/verify",
} as const;

type endpointType = typeof Endpoint;
type FnEndpoints = OmitByType<endpointType, string>
type StringEndpoints = PickByType<endpointType, string>

export type ApiEndpoint = StringEndpoints[keyof StringEndpoints] | ReturnType<FnEndpoints[keyof FnEndpoints]>