import config from "src/config";
import { axiosInstance } from "src/core/http/axios-instance";
import { IAuthMeV2Response } from "src/core/auth/auth.model";
import { IClientMiddlewareAction } from "src/redux/middleware/clientMiddleware";
import { CvApiWalletProvider } from "src/core/wallet/wallet.model";
import { Endpoint } from "src/core/http/endpoint";

export interface IAuthState {
    loaded: boolean
    user: IUser | null
    moneyButtonUserIdHash: string | null
}

export interface IUser {
    nickname?: string;
    profile_image?: string;
}

// Action types
export const LOAD = "game/auth/LOAD";
export const LOAD_SUCCESS = "game/auth/LOAD_SUCCESS";
export const LOAD_FAIL = "game/auth/LOAD_FAIL";
export const LOGOUT = "game/auth/LOGOUT";
export const SET_MONEY_BUTTON_USER_ID_HASH = "game/auth/SET_MONEY_BUTTON_USER_ID_HASH";
export const SET_NICKNAME = "set/nickname";
export const SET_AVATAR = "set/avatar";
export const LOGOUT_SUCCESS = "game/auth/LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "game/auth/LOGOUT_FAIL";

// Action interfaces
interface ILoadSuccessAction {
    type: typeof LOAD_SUCCESS;
    result: IUser;
}

interface ILoadFailAction {
    type: typeof LOAD_FAIL;
}

interface ILogoutAction {
    type: typeof LOGOUT;
}

interface ISetNicknameAction {
    type: typeof SET_NICKNAME;
    nickname: string;
}

interface ISetAvatarAction {
    type: typeof SET_AVATAR;
    newUrl: string;
}

interface ISetMoneyButtonUserIdHashAction {
    type: typeof SET_MONEY_BUTTON_USER_ID_HASH;
    moneyButtonUserIdHash: string;
}

type TAuthActionTypes =
    | ILoadSuccessAction
    | ILoadFailAction
    | ILogoutAction
    | ISetNicknameAction
    | ISetAvatarAction
    | ISetMoneyButtonUserIdHashAction;

const initialState: IAuthState = {
    loaded: false,
    user: null,
    moneyButtonUserIdHash: null,
};

export default function authReducer(
    state = initialState,
    action: TAuthActionTypes
): IAuthState {
    switch (action.type) {
        case LOAD_SUCCESS:
            return {
                ...state,
                loaded: true,
                user: action.result,
            };
        case LOAD_FAIL:
            return {
                ...state,
                loaded: true,
            };
        case LOGOUT:
            return { ...initialState, loaded: true };
        case SET_NICKNAME:
            return { ...state, user: { ...state.user, nickname: action.nickname } };
        case SET_AVATAR:
            return {
                ...state,
                user: { ...state.user, profile_image: action.newUrl },
            };
        case SET_MONEY_BUTTON_USER_ID_HASH:
            return {
                ...state,
                moneyButtonUserIdHash: action.moneyButtonUserIdHash,
            };
        default:
            return state;
    }
}

export function load(): IClientMiddlewareAction<IAuthMeV2Response> {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: async () => {
            const { data } = await axiosInstance.get<{ data: IAuthMeV2Response }>(Endpoint.AuthMeV2);
            return data;
        },
    };
}

export function setNickname(nickname: string) {
    return {
        type: SET_NICKNAME,
        nickname,
    };
}

export function setAvatar(newUrl: string) {
    return {
        type: SET_AVATAR,
        newUrl,
    };
}

export function setMoneyButtonUserIdHash(moneyButtonUserIdHash: string) {
    return {
        type: SET_MONEY_BUTTON_USER_ID_HASH,
        moneyButtonUserIdHash,
    };
}

export function getLoginUrl(provider: CvApiWalletProvider): string {
    return `${config.apiHost}/auth/v2/${provider?.toLowerCase()}/login?redirectUrl=${document.URL}`;
}

export function logout(): IClientMiddlewareAction {
    return {
        types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
        promise: async () => axiosInstance.post("/auth/v2/logout"),
    };
}
