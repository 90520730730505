import { StyledBorderedButton, StyledButton } from "src/affiliate/components/reusable/assets/styles/button.styled";
import { ComponentPropsWithoutRef } from "react";

interface IButtonProps extends ComponentPropsWithoutRef<"button"> {
    width?: string;
    buttonShape?: string;
    hoverTextColor?: string;
    onClick?: () => void;
}

const Button = ({
    children,
    disabled = false,
    type = "button",
    width = "",
    buttonShape = "",
    onClick,
    hoverTextColor = "",
    className = "",
}: IButtonProps) => {
    if (buttonShape === "bordered") {
        return (
            <StyledBorderedButton
                className={className}
                hoverTextColor={hoverTextColor}
                width={width}
                type={type}
                onClick={onClick ? () => onClick() : undefined}
            >
                {children}
            </StyledBorderedButton>
        );
    }
    return (
        <StyledButton
            className={className}
            onClick={onClick ? () => onClick() : undefined}
            width={width}
            type={type}
            disabled={disabled}
        >
            {children}
        </StyledButton>
    );
};

export default Button;
