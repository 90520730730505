import { Redirect } from "react-router-dom";
import Loader from "src/components/loader/Loader";
import { ReactNode } from "react";
import { useAppSelector } from "src/redux/reducer";

interface IProtectedProps {
    children: ReactNode;
}

const Protected = ({ children }: IProtectedProps) => {
    const loggedIn = useAppSelector(state => !!state.auth.user);
    const isLoading = useAppSelector(({ auth, affiliate }) => !auth.loaded || (!!auth.user && !affiliate.loaded));

    /*  if (errorLoader && error?.status === 401) {
      return <Redirect to="/login" />;
    } */
    if (isLoading) {
        return <Loader />;
    }
    if (!loggedIn) {
        return <Redirect to="login" />;
    }
    return <>{children}</>;
};

export default Protected;
