import { DialogHeader, DialogTitle } from "src/components/dialog/Dialog";
import { Separator } from "src/components/separator/Separator";
import { WalletListOfCurrencies } from "src/feature/wallet/components/list-of-currencies/ListOfCurrencies";
import IconWallet from "src/feature/auth/assets/images/wallet-with-plus.svg?inline";

import { useSumToUsd } from "src/core/currency/hooks/useSumToUSD";
import { Button } from "src/components/shadcn-button/Button";
import { DialogContentType } from "src/feature/wallet/hooks/useWalletDialogContentParam";
import { useWalletAccounts } from "src/core/wallet/hooks/useWalletAccounts";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";

// Probably will be fetched by react-query and not passed as props
interface IWalletBalanceViewProps {
    setWalletDialog(type: DialogContentType): void
}

export const WalletBalanceView = ({ setWalletDialog }: IWalletBalanceViewProps) => {
    const {isAuthorized} = useAuthorizedState(WalletProvider.WalletConnect);

    const accounts = useWalletAccounts({
        enabled: isAuthorized,
    })

    const estimatedBalance = useSumToUsd({ accounts });

    return (
        <>
            <DialogHeader>
                <DialogTitle className="text-left inline-flex flex-row items-center">
                    {IconWallet({ className: "w-10 mr-2" })}
                    Wallet
                </DialogTitle>
            </DialogHeader>

            <section className="flex flex-col max-h-[calc(100vh-112px)]">
                <p className="mb-2">Estimated Balance</p>

                <h3 className="inline-flex bg-brand-primary-200 p-2 rounded-lg mb-6 self-start">{estimatedBalance} USD</h3>

                <div className="flex justify-between">
                    <p>Your currencies</p>
                    <p>Value</p>
                </div>
                <Separator className="bg-foreground mt-4" />
                <WalletListOfCurrencies currencies={accounts} />

                <section className="flex flex-col md:flex-row justify-between gap-2 mt-6">
                    <Button onClick={() => setWalletDialog(DialogContentType.Deposit)} className="w-full text-md text-white">Deposit</Button>
                    <Button onClick={() => setWalletDialog(DialogContentType.Withdrawal)} className="w-full text-md text-white">Withdraw</Button>
                </section>
            </section>
        </>
    )
}