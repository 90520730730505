import React from "react";
import rightIcon from "./assets/Seen.svg";
import { StyledH3, StyledP } from "src/affiliate/components/email-verification/assets/styles/verification.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

const Verification = () => (
    <FlexContainer flexDirection="column" alignItems="center">
        <img src={rightIcon} alt="right-icon" />
        <StyledH3>Application Successful</StyledH3>
        <StyledP>
            Thanks for joining Peergame Partners. We will review your application and notify you via email. Review period
            takes 24~48 hours.
        </StyledP>
    </FlexContainer>
);

export default Verification;
