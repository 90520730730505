import { useContext, useState } from "react";
import classNames from "classnames/bind";
import { useQuery } from "@tanstack/react-query";
import { useGameStore } from "src/components/game/hooks/useGameStore";
import styles from "./assets/styles.scss";
import Races from "src/components/stats-table/Races/Races";
import AllBetsHighRollersRow from "src/components/stats-table/AllBetsHighRollersRow";
import AuthButton from "src/feature/auth/AuthButton";
import MyBetsRow from "src/components/stats-table/MyBetsRow";
import GameCodeContext from "src/containers/Games/GameCodeContext";
import TableWrapper from "src/components/table-wrapper/TableWrapper";
import { useAppSelector } from "src/redux/reducer";
import { getGameHistory } from "src/containers/Account/History/game-history.functions";

const cx = classNames.bind(styles);

const tabs = ["MY BETS", "ALL BETS", "HIGH ROLLERS", "RACES"] as const;

export default function StatsTable() {
    const gameCode = useContext(GameCodeContext);
    const user = useAppSelector(state => state.auth.user);
    const [selectedTab, setSelectedTab] = useState(1);
    const { data: myBets = [], isLoading: myBetsLoading } = useQuery({
        queryKey: ["mybets", gameCode],
        queryFn: () => getGameHistory(gameCode || "", { count: 15, withGameResultOnly: true }),
        enabled: selectedTab === 0 && !!user,
        retry: false,
    });

    const allBets = useGameStore(state => state.allBets);
    const highRollers = useGameStore(state => state.highRollers);

    function getContent() {
        if ([0, 1, 2].includes(selectedTab)) {
            // FIXME add types
            let tableRows;
            let columns;
            let emptyPlaceholder;

            if ([1, 2].includes(selectedTab)) {
                tableRows = (selectedTab === 1 ? allBets : highRollers).map((result, index) => (
                    <AllBetsHighRollersRow
                        result={result}
                        key={`${selectedTab}${result.asset}${result.game}${result.player_id}`}
                        className={index % 2 === 0 ? "even" : "odd"}
                    />
                ));

                columns = [
                    { label: "Game" },
                    { label: "User" },
                    { label: "Bet", hideMobile: true },
                    { label: "Multiplier", hideMobile: true },
                    { label: "Pick", hideMobile: true },
                    { label: "Result", hideMobile: true },
                    { label: "Payout (BSV)" },
                ];
            } else {
                columns = [
                    { label: "Round ID" },
                    { label: "Bet" },
                    { label: "Multiplier", hideMobile: true },
                    { label: "My Pick", hideMobile: true },
                    { label: "Result", hideMobile: true },
                    { label: "Payout (BSV)" },
                ];

                if (!user) {
                    emptyPlaceholder = <AuthButton />;
                } else if (!myBets.length) {
                    emptyPlaceholder = "You do not have any history";
                } else {
                    tableRows = myBets.map((result, index) => (
                        <MyBetsRow
                            key={`${result.asset}${result.game}${result.player_id}`}
                            result={result}
                            gameCode={gameCode || ""}
                            className={index % 2 === 0 ? "even" : "odd"}
                        />
                    ));
                }
            }

            return (
                <TableWrapper
                    columns={columns}
                    isLoading={selectedTab === 0 && myBetsLoading}
                    className={cx("table-wrapper", {
                        "all-bets": selectedTab === 1 || selectedTab === 2,
                    })}
                    emptyPlaceholder={emptyPlaceholder}
                >
                    {tableRows}
                </TableWrapper>
            );
        }

        return <Races />;
    }

    return (
        <div className={cx("stats-table")}>
            <div className={cx("header")}>
                {tabs.map((label, index) => (
                    <button
                        key={label}
                        type="button"
                        className={cx({ active: selectedTab === index })}
                        onClick={() => setSelectedTab(index)}
                    >
                        {label}
                    </button>
                ))}
            </div>
            {getContent()}
        </div>
    );
}
