import styled from "styled-components";
import { Caption } from "src/affiliate/styled/typography.styled";

export const StyledTitle = styled.h4`
    color: #22096c;
    @media only screen and (max-width: 568px) {
        font-size: 20px;
        line-height: 1.4;
    }
`;

export const StyledCaption = styled(Caption)`
    color: #1f0d53;
`;