import classNames from "classnames/bind";

import ImgPeergameLogo from "src/components/header/assets/images/peergame-logo-dark.svg";
import PageContainer from "src/containers/PageContainer/PageContainer";

const styles = require("./assets/styles.scss").default;

const cx = classNames.bind(styles);

const pageTitle = "Country Restriction";
const description = "Peergame is not available in your region.";

const CountryRestriction = () => (
    <>
        <PageContainer pageTitle={pageTitle} pageDescription={description} contentClass={cx("restriction")} dark>
            <div className={cx("logo")}>
                <img className={cx("peergame-logo")} src={ImgPeergameLogo} alt="logo" />
            </div>
            <p className={cx("description", "xlarge", "bold")}>
                {description} <br />
                Should you have questions please contact us &nbsp;
                <a href="mailto:contact@peergame.com" target="_blank" rel="noreferrer noopener" className={cx("email-address")}>
                    contact@peergame.com
                </a>
            </p>
        </PageContainer>
    </>
);

export default CountryRestriction;
