import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledItemContainer = styled(FlexContainer)<{ visited?: boolean }>`
    display: flex;
    align-items: center;
    width: 210px;
    height: 48px;
    background: ${({ visited }) => (visited ? "#22096c" : "transparent")};
    cursor: pointer;
    padding: 12px 0 12px 24px;
    margin-bottom: 16px;
    border-radius: ${({ visited }) => (visited ? "4px" : "0px")};

    &:hover {
        background-color: #22096c;
        border-radius: 4px;
    }

    img {
        width: 18px;
    }

    transition: all 0.3s ease;
`;

export const StyledP = styled.p`
    color: #f0ecff;
    margin: 0;
    padding-left: 9px;
`;