import Picture from "src/affiliate/components/reusable/Picture";
import heroImage from "./assets/Hero Image.png";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import PaidAffiliate from "src/affiliate/components/affiliate-landing/section-one/PaidAffiliate";

const SectionOneMain = () => (
    <FlexContainer
        width="100%"
        flexDirection="column"
        padding="30px 0 120px 0"
        mobilePadding="24px 0 60px 0"
        tabletPadding="60px 0 60px 0"
    >
        <FlexContainer
            padding="0 120px"
            tabletPadding="0 60px"
            mobilePadding="0 24px"
            justifyContent="space-between"
            alignItems="flex-start"
            tabletFlexWrap
            tabletJustifyContent="center"
            mobileFlexDirection="column"
            mobileAlignItems="center"
        >
            <PaidAffiliate />
            <FlexContainer width="410px" mobileWidth="240px">
                <Picture img={heroImage} />
            </FlexContainer>
        </FlexContainer>
    </FlexContainer>
);

export default SectionOneMain;
