import { useEffect, useState, useRef } from "react";
import { axiosInstance } from "src/core/http/axios-instance";

export interface ICampaign {
    id: number;
    name: string;
    created_at: string;
    visits: number;
    referrals: number;
    wagered: number;
    commission: number;
    link: string;
    code: string;
}

export interface ICampaignsResponse {
    campaign_list: ICampaign[];
    total_pages: number;
}

export interface UseCampaignReturn {
    campaignList: ICampaign[];
    totalPages: number;
    loadedCampaigns: boolean;
    getCampaigns: () => Promise<number | undefined>;
}

function useCampaign(url: string): UseCampaignReturn {
    const [campaigns, setCampaigns] = useState<ICampaignsResponse | null>(null);
    const [loadedCampaigns, setLoadedCampaigns] = useState<boolean>(false);
    const isMounted = useRef<boolean>(false);

    async function getCampaigns(): Promise<number | undefined> {
        try {
            const res = await axiosInstance.get(url) as unknown as ICampaignsResponse;

            if (isMounted.current) {
                setCampaigns(res);
                setLoadedCampaigns(true);
                return res.total_pages;
            }
        } catch (err) {
            console.log(err);
        }
        return undefined;
    }

    useEffect(() => {
        isMounted.current = true;
        getCampaigns();
        return () => {
            isMounted.current = false;
        };
    }, [url]);

    const { campaign_list: campaignList, total_pages: totalPages } = loadedCampaigns ? campaigns || {
        campaign_list: [],
        total_pages: 0,
    } : {
        campaign_list: [],
        total_pages: 0,
    };
    return {
        campaignList,
        totalPages,
        loadedCampaigns,
        getCampaigns,
    };
}

export default useCampaign;
