import coinWalletIcon from "src/affiliate/components/login/assets/images/Coin-Wallet.png";
import shieldIcon from "src/affiliate/components/login/assets/images/Shield.png";
import messageIcon from "src/affiliate/components/login/assets/images/Message.png";

export const MOBILE_LIST = [
    {
        id: 0,
        image: coinWalletIcon,
        title: "Instant Payouts",
        description: "Zero Fees",
        alt: "coin wallet",
    },
    {
        id: 1,
        image: shieldIcon,
        title: "100% Fairness",
        description: "In-house Games",
        alt: "shield",
    },
    {
        id: 2,
        image: messageIcon,
        title: "1:1 Customer",
        description: "Service Available",
        alt: "message",
    },
];