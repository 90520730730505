import React, { memo, Dispatch, SetStateAction } from "react";
import DropDown, { IDropDownValue } from "src/affiliate/components/reusable/drop-down/DropDown";
import { IPagination } from "src/affiliate/components/reusable/types/pagination";
import Table from "src/affiliate/components/reusable/Table";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import Pagination from "src/affiliate/components/reusable/Pagination";

interface IPayoutsProps {
    pagination: IPagination[];
    prevPage: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    changePage: (id: number, e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    nextPage: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    onClickHandler: (item: IDropDownValue) => void;
    value: IDropDownValue;
    trigger: boolean;
    setTrigger: Dispatch<SetStateAction<boolean>>;
    payout: any[];
    loaded: boolean;
    payoutTotalPages: number;
}

const headerTitles = {
    created_at: "Time",
    receiver_address: "Receiver Address",
    tx_id: " TxID",
    amount: "Amount",
};

const listItems = [
    { name: "Last 24 Hours", value: "24h" },
    { name: "Last 3 Days", value: "3d" },
    { name: "Last 7 Days", value: "7d" },
];

const Payouts = ({
    pagination,
    prevPage,
    nextPage,
    changePage,
    onClickHandler,
    value,
    trigger,
    setTrigger,
    payout,
    loaded,
    payoutTotalPages,
}: IPayoutsProps) => (
    <>
        <Table headerTitles={headerTitles} bodyValues={payout} rowKey="tx_id" transactions />
        {payoutTotalPages > 1 && loaded ? (
            <FlexContainer justifyContent="flex-end" alignItems="baseline" tabletJustifyContent="center" margin="16px 0 0 0">
                <FlexItem margin="0 93px 0 0" tabletMargin="0">
                    <Pagination pagination={pagination} prevPage={prevPage} nextPage={nextPage} changePage={changePage} margin />
                </FlexItem>
                <FlexContainer justifyContent="flex-end" tabletDisplay="none">
                    {payout.length ? (
                        <FlexItem width="180px">
                            <DropDown
                                onClickHandler={onClickHandler}
                                value={value}
                                listItems={listItems}
                                defaultValue="Export CSV"
                                trigger={trigger}
                                setTrigger={setTrigger}
                                transactionsIcon
                            />
                        </FlexItem>
                    ) : null}
                </FlexContainer>
            </FlexContainer>
        ) : null}
    </>
);

export default memo(Payouts);
