import styled from "styled-components";

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
    table-layout: fixed;
`;

export const StyledTr = styled.tr<{ selected?: boolean; }>`
    background-color: #22096c;
    width: 100%;
    box-shadow: ${({ selected }) => (selected ? "inset 0 0 60px 0 rgba(255, 41, 118, 0.7)" : "")};
    border-radius: 4px;
`;
export const StyledTh = styled.th`
    padding: 16px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    img {
        width: 14px;
    }

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        text-align: left;
    }

    &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }

    @media only screen and (max-width: 568px) {
        font-size: 16px;
        line-height: 1.5;
        padding: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

export const StyledTd = styled.td<{
    fontSize?: boolean;
    fontWeight?: boolean;
    color?: boolean;
}>`
    padding: 16px;
    text-align: center;
    font-size: ${({ fontSize }) => (fontSize ? "24px" : null)};
    font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : "normal")};
    color: ${({ color, theme }) => (color ? "#ffbd3e" : theme.color.white)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        text-align: left;
    }

    &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }

    @media only screen and (max-width: 568px) {
        padding: 16px;
    }
`;