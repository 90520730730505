import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";

const styles = isMobileOnly ? require("./assets/mobile.scss").default : require("./assets/desktop.scss").default;

const cx = classNames.bind(styles);

const icon = {
    Play: require("./assets/play.svg").default,
    Rules: require("./assets/rules.svg").default,
    Fairness: require("./assets/fairness.svg").default,
};

interface MenuProps {
    background?: string;
    name: string;
    to: string;
    pathname: string;
}

const Menu: React.FC<MenuProps> = ({ background, name, to, pathname }) => (
    <li style={{ background: isMobileOnly ? background : undefined }}>
        <Link className={cx({ on: to === pathname })} to={to}>
            {isMobileOnly && <img src={icon[name]} className="layer" alt={name} />}
            <p className={cx("bold")}>{name}</p>
        </Link>
    </li>
);

interface GamesHeaderProps extends RouteComponentProps {
    path?: string;
}

const GamesHeader = ({ location, path }: GamesHeaderProps) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => setOpen(false);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleClickArrow = () => {
        setOpen(!open);
    };

    return (
        <div className={cx(styles.header)}>
            <nav>
                <ul>
                    <Menu name={isMobileOnly ? "Play" : "PLAY"} to={`/games/${path}/`} pathname={location.pathname} />
                    <Menu name={isMobileOnly ? "Rules" : "RULES"} to={`/games/${path}/rules/`} pathname={location.pathname} />
                    <Menu
                        name={isMobileOnly ? "Fairness" : "FAIRNESS"}
                        to={`/games/${path}/fairness/`}
                        pathname={location.pathname}
                    />
                </ul>
            </nav>
        </div>
    );
};

export default withRouter(GamesHeader);
