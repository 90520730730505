import classNames from "classnames/bind";

import ImgPeergameLogo from "src/components/header/assets/images/peergame-logo-dark.svg";
import PageContainer from "src/containers/PageContainer/PageContainer";

const styles = require("./assets/styles.scss").default;

const cx = classNames.bind(styles);

const pageTitle = "Maintenance";
const description = "The game is currently undergoing maintenance.";

const GameMaintenance = () => (
    <PageContainer
        pageTitle={pageTitle}
        pageDescription={description}
        contentClass={cx("game_maintenance")}
        showFooter={false}
        dark
    >
        <div>
            <div className={cx("logo")}>
                <img className={cx("peergame-logo")} src={ImgPeergameLogo} alt="logo" />
            </div>
            <div className={cx("title")}>
                <div>WE'LL BE BACK SHORTLY</div>
            </div>
            <div className={cx("description")}>
                <div>
                    {description}
                    <br />
                    Thank you for your patience.
                </div>
            </div>
        </div>
    </PageContainer>
);

export default GameMaintenance;
