import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    .Toastify__toast-container {
        width: 228px !important;
    }

    .Toastify__toast-container--top-right {
        top: 6em !important;
        @media only screen and (max-width: 767px) {
            right: 1em !important;
            left: auto !important;
            top: 3em !important;
        }

        .Toastify__toast {
            min-height: 40px !important;
            padding: 0 !important;
            @media only screen and (max-width: 767px) {
                margin-bottom: 8px;
            }
        }

        .Toastify__toast--default {
            border-radius: 4px !important;
        }
    }`;

export default GlobalStyle;
