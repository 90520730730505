import { FlexContainer } from "src/affiliate/styled/layout.styled";
import {
    StyledPaidAffiliate,
    StyledGetYourProfit,
    StyledCaption,
    StyledButton,
} from "src/affiliate/components/affiliate-landing/section-one/assets/styles/paid-afiliate.styled";

const PaidAffiliate = () => (
    <>
        <FlexContainer
            flexDirection="column"
            width="50%"
            tabletWidth="100%"
            padding="113px 0 0 0"
            tabletPadding="70px 0"
            mobilePadding="37px 0 32px 0"
        >
            <StyledPaidAffiliate>HIGHEST PAID COMMISSIONS</StyledPaidAffiliate>
            <StyledGetYourProfit>INSTANT PROFITS IN REAL-TIME</StyledGetYourProfit>
            <StyledCaption className="xlarge">
                Refer your audience to Peergame and earn real-time profits without any withdrawal process. Start your lifetime
                wager(not revenue) share today!
            </StyledCaption>
            <FlexContainer mobileFlexDirection="column">
                <StyledButton
                    to="/dashboard"
                    margin="0 20px 0 0"
                    backgroundColor="#F8F5FF"
                    borderColor="#c7baf1"
                    color="#6e658a"
                    hoverColor="#6e658a"
                    hoverBackgroundColor="#F0ECFF"
                    mobileMargin="0 0 20px 0"
                >
                    DASHBOARD
                </StyledButton>
                <StyledButton to="/registration">BECOME A PARTNER</StyledButton>
            </FlexContainer>
        </FlexContainer>
    </>
);

export default PaidAffiliate;
