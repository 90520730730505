import { useSortAccountsByUsd } from "src/core/currency/hooks/useSortAccountsByUSD";
import { useMemo } from "react";
import { useGetWalletAccounts } from "src/core/wallet/hooks/useGetWalletAccounts";

interface IUseWalletAccountsInput {
    enabled?: boolean
}

export const useWalletAccounts = ({
    enabled = false,
}: IUseWalletAccountsInput = {}) => {
    const { data } = useGetWalletAccounts({ enabled });

    const sortAccounts = useSortAccountsByUsd({ enabled });

    return useMemo(() => [...data].sort(sortAccounts), [data, sortAccounts]);
}