import { useAuthorizedCookie } from "src/core/auth/hooks/useAuthorizedCookie";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { WalletProvider } from "src/core/wallet/wallet.model";

export function useAuthorizedState(provider?: WalletProvider) {
    const [authorized] = useAuthorizedCookie();
    const [walletProvider] = useWalletProvider();

    const isAuthorized = provider ? authorized && walletProvider === provider : authorized;
    const isAuthorizing = provider ? !authorized && walletProvider === provider : !authorized && !!walletProvider;

    return {
        isAuthorized,
        walletProvider,
        isAuthorizing,
    }
}