import { useState } from "react";
import classNames from "classnames/bind";
import BetDetailContainer from "src/components/stats-table/BetDetailContainer";
import styles from "./assets/styles.scss";
import { games } from "src/games/config";
import Anonymous from "./assets/images/anonymous.svg?inline";
import { CryptoCurrency } from "src/core/currency/currency.model";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";

interface IResult {
    game: string;
    area: string;
    nickname?: string;
    profile_image?: string;
    amount: number;
    multiplier: string | number;
    payout: number;
    player_id: number;
    winner: boolean;
    game_result: string;
    asset: CryptoCurrency;
}

interface IAllBetsHighRollersRowProps {
    result: IResult;
    className: string;
}

const cx = classNames.bind(styles);
export default function AllBetsHighRollersRow({
    result: {
        amount,
        area,
        asset,
        game,
        multiplier,
        nickname = "",
        payout,
        player_id: playerId,
        profile_image: profileImage,
        winner: isWin,
        game_result: result,
    },
    className,
}: IAllBetsHighRollersRowProps) {
    const gameName = game.replace(/^./, game[0].toUpperCase());
    const [isOpen, setIsOpen] = useState(false);
    const formattedMultiplier = `${Number(multiplier)?.toFixed(2)}x`;
    const formattedArea = games[game].formatPick(area);
    const formattedResult = games[game].formatResult(result);

    return (
        <tr className={cx("small", className)} onClick={() => setIsOpen(true)}>
            <td>
                <div className="flex items-center justify-start">
                    {games[game].smallIcon({})}
                    <span className={cx("small")}>{gameName}</span>
                </div>
            </td>
            <td className={cx("user-image", "justify-center")}>
                <div className="flex items-center">
                    {profileImage ? <img src={profileImage} alt="userImage" /> : <Anonymous />}
                    <span className={cx("small")}>{nickname || "hidden"}</span>
                </div>
            </td>
            <td className={cx("hide-mobile", "bet-amount")}>
                <div className="flex items-center justify-center">
                    <span className={cx("small")}>{amount}</span>
                    <CurrencyIcon currency={asset} />
                </div>
            </td>
            <td className={cx("hide-mobile")}>{isWin ? formattedMultiplier : "0.00x"}</td>
            <td className={cx("hide-mobile")}>{formattedArea}</td>
            <td className={cx("hide-mobile")}>{formattedResult}</td>
            <td className={cx(isWin ? "win" : "loss", "bold")}>{isWin ? payout : -amount}</td>
            {isOpen ? (
                <BetDetailContainer
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    betAmount={amount}
                    gameCode={game}
                    multiplier={multiplier}
                    playerId={playerId}
                    isWin={isWin}
                    asset={asset}
                />
            ) : null}
        </tr>
    );
}


