import styled from "styled-components";

export const StyledIconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #f8f5ff;
    align-items: center;

    img {
        margin: 8px;
    }
`;

export const StyledP = styled.p`
    color: ${({ theme }) => theme.color.white};
    margin: 0;
`;