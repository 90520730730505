import {useMemo} from "react";
import {createHash} from "crypto";

export default function useRandomNumber(seedHash, txIds) {
    return useMemo(() => {
        if (seedHash) {
            const r = seedHash + txIds.join("");
            return createHash("sha256")
                .update(Buffer.from(r))
                .digest()
                .toString("hex");
        }
    }, [seedHash, txIds]);
}
