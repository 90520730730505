import { useState } from "react";
import arrowDownIcon from "src/affiliate/components/affiliate-landing/section-three/assets/arrow-down.svg";
import {
    StyledCaption,
    StyledCaptionContainer,
    StyledCircle,
    StyledFlexContainer,
    StyledTitle,
} from "src/affiliate/components/affiliate-landing/section-three/frequently-aq/assets/styles/aq-card.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

interface IAqCardProps {
    title: string;
    children: string;
}

const AqCard = ({ title, children }: IAqCardProps) => {
    const [toggle, setToggle] = useState(false);
    return (
        <StyledFlexContainer flexDirection="column" justifyContent="center" margin="0 0 4px 0">
            <FlexContainer
                onClick={() => setToggle(prevState => !prevState)}
                justifyContent="space-between"
                padding="22px 24px"
                alignItems="center"
                cursor="pointer"
            >
                <StyledTitle className="bold">{title}</StyledTitle>
                <StyledCircle trigger={toggle}>
                    <img src={arrowDownIcon} alt="icon" />
                </StyledCircle>
            </FlexContainer>
            <StyledCaptionContainer collapsed={toggle}>
                <StyledCaption collapsed={toggle}>{children}</StyledCaption>
            </StyledCaptionContainer>
        </StyledFlexContainer>
    );
};

export default AqCard;
