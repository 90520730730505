import { bottomFooterLinks } from "src/components/footer/constants/bottom-footer-links-items";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

const BottomFooterLinks = () => {
    return <>
        <ul className="grid grid-cols-2 gap-x-6 gap-y-4 w-full">
            {bottomFooterLinks.map(({ text, ...props }) => {
                let innerElement: ReactElement | null = null;

                switch (true) {
                    case !!props?.to:
                        innerElement = <Link to={props.to}>{text}</Link>;
                        break;
                    case !!props?.mailTo:
                        innerElement = <a href={props.mailTo}>{text}</a>;
                        break;
                    case !!props?.href:
                        innerElement = <a href={props.href} target="_blank" rel="noopener noreferrer">{text}</a>;
                        break;
                    default:
                        innerElement = null;
                        break;
                }

                return <li className="hover:underline text-center" key={text}>
                    {innerElement}
                </li>;
            })}
        </ul>
    </>;
};

export default BottomFooterLinks;