export const testimonials = [
    {
        title: "100% Transparency",
        text:
            "I can say the innovation behind Peergame is nothing short of incredible."
            + " You can’t beat the on-chain experience with its provably fair games, 100% transparency, instant payouts.",
        authorName: "Becky Liggero",
        authorSource: "Ayre Media",
    },
    {
        title: "Like a real casino",
        text:
            "I’ve used many online casinos over the years. None of them rival the experience that Peergame offers."
            + " Like a real casino, you can walk away with your winnings at any point.",
        authorName: "Brandon Cryderman",
        authorSource: "HandCash",
    },
    {
        title: "Simply Amazing",
        text:
            "I can say without doubt that Peergame is the BEST platform I've played online. It's just a bonus"
            + " it utilises the BSV blockchain. Almost instant play and payouts to your own self-custodial wallet. ",
        authorName: "Zatoshi",
        authorSource: "Zatoshi.io",
    },
    {
        title: "Unique Experience",
        text:
            "Have been working with a lot of casinos throughout the years but none offering such exclusive and "
            + "unique services on the market. Just give it a try and you will not regret it.",
        authorName: "I. C. Daniel",
        authorSource: "BeerMoneyForum.com",
    },
];
