import { useAppSelector } from "src/redux/reducer";
import { WalletProvider } from "src/core/wallet/wallet.model";

interface IUseMaintenanceInput {
    forGame?: string // replace with enum or type
    forWallet?: WalletProvider | "deposit"
}

export function useMaintenance({ forGame, forWallet }: IUseMaintenanceInput = {}) {
    const maintenance = useAppSelector(state => state?.info?.maintenance);

    if (maintenance.code !== "0" || process.env.IGNORE_MAINTENANCE === "true") {
        return false;
    }

    if (forGame) {
        return maintenance.game.find(({ name }) => name === forGame)?.is_maintenance === "Y"
    }

    if (forWallet) {
        return maintenance?.wallet.find(({ name }) => name === forWallet)?.is_maintenance === "Y"
    }

    return maintenance.site[0].is_maintenance === "Y";
}