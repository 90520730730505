import { useState } from "react";
import classNames from "classnames/bind";
import Img1st from "./assets/images/1st.png";
import Img2nd from "./assets/images/2nd.png";
import Img3rd from "./assets/images/3rd.png";
import ImgCoins from "./assets/images/coins.svg";
import SwapIcon from "./assets/images/swap.svg?inline";
import Loader from "src/components/loader2/Loader";
import styles from "./assets/styles.scss";
import { useMobileOnly } from "src/helpers/responsive";

const rankingImages = { 1: Img1st, 2: Img2nd, 3: Img3rd };

const cx = classNames.bind(styles);

const variableCols = [
    { label: "Points", key: "point" },
    { label: "Profit (BSV)", key: "profit" },
    { label: "Multiplier", key: "multiply" },
];

const RankingsTable = ({ rewards, rankings, isLoading }) => {
    const isMobileOnly = useMobileOnly();
    // this is used in mobile only to switch between points, profit and multiplier columns
    const [variableColIndex, setVariableColIndex] = useState(0);

    let rowComponents = rewards.map((reward, index) => {
        // copy object to avoid modifying the original object
        const rowData = { ...rankings[index] } || {};

        rowData.multiply = rowData.multiply || rowData.multiply === 0 ? Math.round(rowData.multiply) : "-";
        rowData.profit = rowData.profit?.toFixed(4) || "-";
        rowData.point = rowData.point || rowData.point === 0 ? rowData.point : "-";
        rowData.paymail = rowData.paymail || "-";

        let variableColCells;
        if (isMobileOnly) {
            variableColCells = <td>{rowData[variableCols[variableColIndex].key]}</td>;
        } else {
            variableColCells = variableCols
                .slice(0)
                .reverse()
                .map(({ key }) => <td key={key}>{rowData[key]}</td>);
        }

        return (
            <tr key={reward.ranking}>
                <td className="flex items-center">
                    <span>{reward.ranking}</span>
                    {rankingImages[reward.ranking] && <img src={rankingImages[reward.ranking]} alt="ranking icon" />}
                </td>
                <td>{rowData.paymail}</td>
                {variableColCells}
                <td className="flex items-center justify-end">
                    <span>{reward.reward}</span>
                    <img src={ImgCoins} alt="coins" />
                </td>
            </tr>
        );
    });

    if (isLoading) {
        rowComponents = (
            <tr className={cx("loader")}>
                <td colSpan={6}>
                    <Loader />
                </td>
            </tr>
        );
    }

    let variableColHeaders;
    if (isMobileOnly) {
        variableColHeaders = (
            <th
                className={cx("icon-column")}
                onClick={() => setVariableColIndex(variableColIndex === variableCols.length - 1 ? 0 : variableColIndex + 1)}
            >
                {variableCols[variableColIndex].label}
                <SwapIcon />
            </th>
        );
    } else {
        variableColHeaders = variableCols
            .slice(0)
            .reverse()
            .map(({ label }) => <th key={label}>{label}</th>);
    }

    return (
        <div className={cx("rankings-table")}>
            <table>
                <thead>
                <tr>
                    <th>Place</th>
                    <th>Player</th>
                    {variableColHeaders}
                    <th>Prize (BSV)</th>
                </tr>
                </thead>
                <tbody>{rowComponents}</tbody>
            </table>
        </div>
    );
};

export default RankingsTable;
