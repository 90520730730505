import { Button } from "src/components/shadcn-button/Button";
import { Copy } from "lucide-react";
import { cn } from "src/helpers/cn";
import { useClipboardCopy } from "@ntropy/hooks";
import { successToast } from "src/helpers/toast";

interface ICopyButtonProps {
    valueToCopy: string;
    className?: string;
    message?: string
    disabled?: boolean
}

export const CopyButton = ({ valueToCopy, className, message, disabled }: ICopyButtonProps) => {
    const { handleCopy, isCopyingLocked } = useClipboardCopy({
        value: valueToCopy,
        onCopy: _ => {
            successToast(message ?? "Copied to clipboard");
        },
    });

    return (
        <div className={cn("flex items-center justify-between bg-brand-primary-200 rounded-lg p-2 pl-4 max-w-full overflow-hidden", className)}>
            <p className="max-w-[30ch] truncate md:max-w-[45ch]">{valueToCopy}</p>
            <Button variant="ghost" onClick={handleCopy} disabled={disabled || isCopyingLocked}>
                <Copy />
            </Button>
        </div>
    )
}