import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import BigNumber from "bignumber.js";
import Button from "src/components/button/Button";
import BetButton from "src/components/game/bet-button/BetButton";
import useBettingAmount from "src/components/game/hooks/useBettingAmount";
import useAppStore from "useAppStore";
import styles from "./assets/styles.scss";
import { successToast } from "src/helpers/toast";
import useTableDeposit from "./useTableDeposit";
import { useAppSelector } from "src/redux/reducer";

import IconClear from "./assets/clear-light.svg?inline";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { roundCrypto } from "src/core/currency/currency.function";
import AuthButton from "src/feature/auth/AuthButton";

const cx = classNames.bind(styles);

export default function Deposit({ closeModal }) {
    const walletBalance = useAppStore(state => state.asset.balance.BSV);
    const userHashedId = useAppSelector(state => state.auth.user?.hashedId);

    const {isAuthorized: isAuthorizedWithHandcash} = useAuthorizedState(WalletProvider.HandCash);
    const { hasPending, setPendingBalance, settings } = useTableDeposit();
    const [depositAmount, setDepositAmount] = useState(new BigNumber(0));
    const { base_amount: defaultBet, min_amount: minDeposit, decimal_place: decimalPlaces } = settings;
    const { updateBettingAmount: updateDepositAmount, inputElement } = useBettingAmount(
        depositAmount,
        minDeposit,
        walletBalance.isZero() ? 9999 : walletBalance,
        defaultBet,
        decimalPlaces,
        newAmount => setDepositAmount(newAmount),
        "tableDeposit",
    );
    const [showConnectWallet, setShowConnectWallet] = useState(false);
    const connectWalletRef = useRef();

    const depositData = {
        amount: depositAmount,
        multiplier: 1,
        script: `peergame.com|DEPOSIT|BSV|${JSON.stringify({
            v: "0",
            i: 0,
            d: {
                sender: userHashedId,
                game: "all",
                amount: depositAmount.toNumber(),
                timestamp: new Date(),
            },
        })}`,
    };

    /*
    useEffect(() => {
      useGameStore.getState().setBettingDisabled(false);
    }, [depositAmount]);
    */

    function onBetSuccess() {
        closeModal();
        successToast("Deposit successful");
        setPendingBalance();
    }

    function getButtonContent() {
        if (isAuthorizedWithHandcash) {
            if (hasPending) {
                return (
                    <div className={cx("pending-placeholder")}>
                        <span className={cx("bold")}>Pending Deposit</span>
                    </div>
                );
            }
            return (
                <BetButton
                    buttonText="Deposit"
                    className={cx("bet-button")}
                    onBetSuccess={onBetSuccess}
                    depositData={depositData}
                />
            );
        }

        return (
            <AuthButton
                className={cx("bold", "connect-wallet")}
                onClick={() => {
                    closeModal();
                }}
            />
        );
    }

    return (
        <div className={cx("deposit")}>
            <p>
                You can deposit the amount that you want and start to play Slots, Table Games and Blackjack. You can instantly
                cashout your balance whenever you want.
            </p>
            <div className={cx("balance")}>
                <span className={cx("label", "xsmall")}>WALLET BALANCE</span>
                <CurrencyIcon currency={CryptoCurrency.BSV} />
                <span className={cx("amount", "bold")}>{roundCrypto(walletBalance)}</span>
            </div>
            <span className={cx("xsmall")}>DEPOSIT AMOUNT</span>
            <div className={cx("deposit-amount")}>
                <CurrencyIcon currency={CryptoCurrency.BSV} />
                {inputElement}
                {!depositAmount.isEqualTo(defaultBet) && (
                    <button type="button" onClick={() => updateDepositAmount(new BigNumber(defaultBet))}>
                        <IconClear />
                    </button>
                )}
            </div>
            <div className={cx("deposit-controls")}>
                <button type="button" className={cx("small")} onClick={() => updateDepositAmount(depositAmount.plus(0.01))}>
                    +0.01
                </button>
                <button type="button" className={cx("small")} onClick={() => updateDepositAmount(depositAmount.plus(0.05))}>
                    +0.05
                </button>
                <button type="button" className={cx("small")} onClick={() => updateDepositAmount(depositAmount.plus(0.1))}>
                    +0.1
                </button>
                <button type="button" className={cx("small")} onClick={() => updateDepositAmount(depositAmount.plus(1))}>
                    +1
                </button>
            </div>
            <div className={cx("buttons")}>
                <Button className={cx("bold")} onClick={closeModal}>
                    Cancel
                </Button>
                {getButtonContent()}
            </div>
        </div>
    );
}
Deposit.propTypes = {
    closeModal: PropTypes.func.isRequired,
};
Deposit.defaultProps = {};
