import { forwardRef, ButtonHTMLAttributes, ReactNode } from "react";
import classNames from "classnames/bind";
import styles from "./styles.scss";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean,
    isDisabled?: boolean,
    children: ReactNode,
}

const cx = classNames.bind(styles);

const Button = forwardRef<HTMLButtonElement, IButtonProps>(({
    isLoading = false, isDisabled = false, children, className = "", ...rest
}, ref) => {
    function getContent() {
        if (isLoading) {
            return (
                <div className={cx("loader")}>
                    <span className={cx("bold")}>&bull;</span>
                    <span className={cx("bold")}>&bull;</span>
                    <span className={cx("bold")}>&bull;</span>
                </div>
            );
        }
        return children;
    }

    return (
        <button type="button" className={cx("button", className)} disabled={isDisabled || isLoading} ref={ref} {...rest}>
            {getContent()}
        </button>
    );
});

Button.displayName = "Button";

export default Button;

