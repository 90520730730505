import React, { forwardRef, Fragment, ReactNode } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import Backdrop from "src/components/backdrop/Backdrop";
import IconCloseSquare from "./assets/close-square.svg?inline";

interface ISideSliderProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    className?: string;
}

const cx = classNames.bind(styles);

const SideSlider = forwardRef<HTMLDivElement, ISideSliderProps>(({
    isOpen, onClose, children, className,
}, ref) => (
    <Fragment>
        <Backdrop visible={isOpen} onClick={onClose} />
        {createPortal(
            <div className={cx("side-slider", { open: isOpen }, className)} ref={ref}>
                <button className={cx("close-button")} type="button" onClick={onClose}>
                    <IconCloseSquare />
                </button>
                {children}
            </div>,
            document.getElementsByTagName("body")[0],
        )}
    </Fragment>
));

SideSlider.displayName = "SideSlider";

export default SideSlider;


