import {
    Select as RadixSelect,
    SelectContent,
    SelectTrigger,
    SelectValue,
} from "src/components/select/RadixSelect";
import cn from "classnames";

interface ISelectProps {
    value?: string | null
    onChange(value: string): void
    className?: string
    placeholder?: React.ReactNode
    selectContent: React.ReactNode
}

export const Select = ({ value, className, selectContent, placeholder, onChange }: ISelectProps) => {
    return (
        <RadixSelect value={value ?? undefined} onValueChange={onChange}>
            <SelectTrigger className={cn("w-full bg-brand-primary-200", className)}>
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
                {selectContent}
            </SelectContent>
        </RadixSelect>
    )
}