import { useEffect, RefObject } from "react";

type THandler = (event: MouseEvent) => void;
type TRefArray = Array<RefObject<HTMLElement>>;

// refs array length must never change
export default function useClickOutside(handler: THandler, refs: TRefArray): void {
    useEffect(() => {
        function onClick(e: MouseEvent): void {
            const clickOutside = refs.every(({ current }) => !current || !current.contains(e.target as Node));
            if (clickOutside) {
                handler(e);
            }
        }

        document.addEventListener("click", onClick);

        return () => {
            document.removeEventListener("click", onClick);
        };
    }, [handler, ...refs]);
}