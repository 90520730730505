import * as yup from "yup";

export const campaignSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, "Length must be between 3~20 characters.")
        .max(20, "Length must be between 3~20 characters.")
        .matches(/^[A-Za-z\d ]+$/, { message: "Only letters, numbers and spaces are allowed." }),
    code: yup
        .string()
        .min(3, "Length must be between 3~20 characters.")
        .max(20, "Length must be between 3~20 characters.")
        .matches(/^[A-Za-z\d]+$/, { message: "Only letters and numbers are allowed." }),
});