import * as yup from "yup";

export const registrationSchema = yup.object().shape({
    name: yup
        .string()
        .max(32, "Name is too long")
        .matches(/^[a-zA-Z ]*$/, "Name can only contain alphabets and spaces")
        .trim()
        .required("Please enter your name"),
    email: yup
        .string()
        .max(256, "Email address is too long")
        .email("Please enter a valid email address")
        .trim()
        .required("Please enter your email address"),
    sot: yup
        .string()
        .max(256, "Url is too long")
        .url("Please enter a valid url")
        .trim()
        .required("Please enter your url"),
});
