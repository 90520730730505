import { FC } from "react";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { walletProviders } from "src/core/wallet/wallet.const";
import { WalletProvider } from "src/core/wallet/wallet.model";

interface IAuthActionButtonProps {
    walletProvider: WalletProvider
    onClick?(): void
}

const AuthActionButton: FC<IAuthActionButtonProps> = ({
    walletProvider, onClick,
}) => {
    const [_, setWalletProvider] = useWalletProvider();

    const providerConfig = walletProviders[walletProvider];

    return (
        <button
            type="button"
            className="flex items-center px-4 gap-4 bg-brand-primary-200 hover:bg-brand-primary-300 transition-colors rounded h-[48px]"
            onClick={() => {
                setWalletProvider(walletProvider);
                onClick?.();
            }}
        >
            <img className="w-6 h-6" src={providerConfig.icon} alt={providerConfig.label} />
            <span className="text-md font-bold text-brand-primary-500">{providerConfig.label}</span>
        </button>
    );
};

export default AuthActionButton;