import classNames from "classnames/bind";

import ImgPeergameLogo from "src/components/header/assets/images/peergame-logo-dark.svg";

const styles = require("./assets/styles.scss").default;

const cx = classNames.bind(styles);

const description = "Our website is currently undergoing scheduled maintenance.";

const SiteMaintenance = () => (
    <>
        <div className={cx("site_maintenance")}>
            <div>
                <div className={cx("logo")}>
                    <img className={cx("peergame-logo")} src={ImgPeergameLogo} alt="logo" />
                </div>
                <div className={cx("title")}>WE'LL BE BACK SHORTLY</div>
                <div className={cx("description")}>
                    <div>
                        {description} <br />
                        Thank you for your patience.
                    </div>
                </div>
                <div className={cx("contact")}>
                    <p>Contact us &nbsp;</p>
                    <p className={cx("email-address")}>contact@peergame.com</p>
                </div>
            </div>
        </div>
    </>
);

export default SiteMaintenance;
