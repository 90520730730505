import styled from "styled-components";
import { Caption } from "src/affiliate/styled/typography.styled";

export const StyledRealTimePayment = styled.h3`
    color: #22096c;
    @media only screen and (max-width: 568px) {
        margin-bottom: 16px;
        font-size: 24px;
    }
`;

export const StyledCaption = styled(Caption)<{
    margin: string;
    tabletMargin: string;
    mobileMargin?: string;
}>`
    color: #1f0d53;
    margin: ${({ margin }) => margin};
    @media only screen and (max-width: 1157px) {
        margin: ${({ tabletMargin }) => tabletMargin};
    }
    @media only screen and (max-width: 568px) {
        margin: ${({ mobileMargin }) => mobileMargin};
    }
`;