import { StyledFrequently } from "src/affiliate/components/affiliate-landing/section-three/frequently-aq/assets/styles/frequently-aq.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import AqCard from "src/affiliate/components/affiliate-landing/section-three/frequently-aq/AqCard";

const FrequentlyAq = () => (
    <FlexContainer
        flexDirection="column"
        alignItems="center"
        mobileAlignItems="baseline"
        padding="0 0 272px 0"
        tabletPadding="0 60px 60px 60px"
        mobilePadding="0 0 120px 0"
    >
        <StyledFrequently>FREQUENTLY ASKED QUESTIONS</StyledFrequently>
        <FlexContainer flexDirection="column" width="100%" maxWidth="900px" padding="80px 0 0 0" mobilePadding="32px 0 0 0">
            <AqCard title="What is Peergame Partners program?">
                Peergame Partners program is a way for you to become a partner(affiliate) of Peergame, and earn money through
                your website or content. All you need to do is promote Peergame on your website and refer players to our
                platform. Revenue generated by those referred players will all be shared with you. That easy!
            </AqCard>
            <AqCard title="Why should I choose Peergame Partners?">
                Why not? We offer a unique product, fair gaming, and a lot of fun. You would only be proud to promote us to your
                users. And if they choose to pay for the fun we are offering — we will be happy to share it with you!
            </AqCard>
            <AqCard title="How do I sign up?">
                Just use the registration form and fill in all the fields. We will validate the information you provided and
                provide you with a personal partner account.
            </AqCard>
            <AqCard title="I signed up, what's next?">
                Log into your account, pick the banners you prefer, or create a text link. Add the link and/or banner to your
                website. There, you started!
            </AqCard>
            <AqCard title="Are there any fees to become Peergame Partners?">
                Nope, this is totally free for you. We do not charge our partners with anything!
            </AqCard>
            <AqCard title="For how long will I receive commission of the players that I referred?">
                Our commission model tags all players that come to play through your referral links as your referees, and this
                lasts for a lifetime. So the commission revenue will last forever.
            </AqCard>
            <AqCard title="How much money will I make?">
                We value our partners. This is why you may get up to 40% commission depending on your performance! And yes, all
                statistics are live, up-to-date, and fully transparent.
            </AqCard>
            <AqCard title="How do I track my profit?">
                All partners are provided with detailed statistics in their personal accounts. You can always check the number
                of users you bring to us, the revenue they generate, your own commission, and many other useful reports and
                numbers.
            </AqCard>
            <AqCard title="When do I get the commissions?">
                All payouts are processed within minutes. You can also choose the payout limit in your profile.
            </AqCard>
            <AqCard title="Where do I receive my commissions?">
                You will get your payments to the paymail address which you will provide us when registering.
            </AqCard>
            <AqCard title="If I do not have a website, can I still become a partner?">
                Yes, If you have alternative channels (like mail, social media, or others) you can also become our partner. Just
                let us know exactly what channel you are going to use when signing up and we can make it work.
            </AqCard>
            <AqCard title="What if I refer myself?">
                Anyone who refers themselves will be banned from the program. If you have done this by accident, you must
                contact our support and let us know.
            </AqCard>
            <AqCard title="How can I contact you for help, or get answers to other questions you haven't included? ">
                You can contact us anytime at contact@peergame.com.
            </AqCard>
        </FlexContainer>
    </FlexContainer>
);

export default FrequentlyAq;
