import { FC } from "react";
import styles from "./square-loader.module.scss";
import cn from "classnames";

interface ISquareLoaderProps {
    className?: string
    animationTimeInSec?: number
    background?: string
    lightColor?: string
    darkColor?: string
    squareSize?: string
}

const SquareLoader: FC<ISquareLoaderProps> = ({
    className,
    animationTimeInSec = 10,
    background,
    lightColor,
    darkColor,
    squareSize,
}) => {
    return (
        <div
            className={cn(styles.loader, className)}
            style={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                "--animation-time": `${animationTimeInSec}s`,
                "--background": background,
                "--light-color": lightColor,
                "--dark-color": darkColor,
                "--square-size": squareSize,
            }}
        >
            <div className={cn(styles.square, styles.squareOne)} />
            <div className={cn(styles.square, styles.squareTwo)} />
            <div className={cn(styles.square, styles.squareThree)} />
            <div className={cn(styles.square, styles.squareFour)} />
            <div className={cn(styles.square, styles.squareFive)} />
            <div className={cn(styles.square, styles.squareSix)} />
            <div className={cn(styles.square, styles.squareSeven)} />
            <div className={cn(styles.square, styles.squareEight)} />
            <div className={cn(styles.square, styles.squareNine)} />
        </div>
    );
};

export default SquareLoader;