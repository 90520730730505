const pageDescription = "When gambling at any online casino, you might experience some issues. If that happens"
    + " you are entitled to make a complaint with official government regulatory bodies that issue licenses or"
    + " independent regulatory bodies, such as Casino Guru.";

export default function Complaints() {
    return (
        <>
            <p>{pageDescription}</p>
            <p>
                If you are experiencing any issues with Peergame regarding
                <strong> Payment, Bonuses, Software, Deposit, Accounts</strong>, or other, you are welcome to file a complaint
                with the<a href="https://casino.guru/complaints"> Casino Guru Complaints service.</a>
            </p>
        </>
    );
}
