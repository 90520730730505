import { useState } from "react";
import classNames from "classnames/bind";
import Dropdown from "src/components/dropdown/Dropdown";
import Detail from "src/components/detail/Detail";
import styles from "../assets/styles.scss";
import useRandomNumber from "src/containers/FairnessCalculation/hooks/useRandomNumber";
import useModulusBy from "src/containers/FairnessCalculation/hooks/useModulusBy";
import useResultNumber from "src/containers/FairnessCalculation/hooks/useResultNumber";
import useFinalResult from "src/containers/FairnessCalculation/hooks/useFinalResult";
import RandomNumber from "src/containers/FairnessCalculation/RandomNumber/RandomNumber";
import Result from "src/containers/FairnessCalculation/Result/Result";
import ResultTable from "src/containers/FairnessCalculation/ResultTable/ResultTable";

interface IGame {
    game_id: number;
    next_game_id: number;
    next_seed_hash: string;
    seed_hash: string;
    txids: string[];
}

interface ICoinBattlesProps {
    data: {
        game_code: string;
        games: IGame[];
    };
    gameId: number | null;
    setGameId: (id: number) => void;
}

const cx = classNames.bind(styles);

const CoinBattles = ({ data, gameId, setGameId }: ICoinBattlesProps) => {
    const { game_code: gameCode, games } = data;

    const selectedGame = games.find(game => game.game_id === gameId);

    const {
        next_game_id: nextGameId, next_seed_hash: nextSeedHash, seed_hash: seedHash, txids,
    } = selectedGame ?
        selectedGame :
        {
            next_game_id: 0,
            next_seed_hash: "",
            seed_hash: "",
            txids: [],
        };

    const [txIds, setTxIds] = useState(txids);

    function onTxIdChange(index: number, value: string) {
        const updatedTxIds = [...txIds];
        updatedTxIds[index] = value;
        setTxIds(updatedTxIds);
    }

    const randomNumber = useRandomNumber(seedHash, txIds);
    const modulusBy = useModulusBy(gameCode);
    const resultNumber = useResultNumber(randomNumber, modulusBy);
    const finalResult = useFinalResult(gameCode, resultNumber);

    if (!selectedGame) return null;

    const dropdownItems = games.map(gameData => ({ label: gameData.game_id.toString(), value: gameData.game_id }))

    return (
        <div>
            <div className={cx("game-selection")}>
                <span className={cx("small")}>Battle ID</span>
                <Dropdown
                    items={dropdownItems}
                    selectedValue={gameId}
                    onChange={value => setGameId(Number(value))}
                />
            </div>
            <Detail
                contentClassName={cx("small")}
                className={cx("detail")}
                label={`Next Round Seed (${nextGameId || "-"})`}
                content={nextSeedHash}
                copiable={!!nextSeedHash}
            />
            <Detail
                contentClassName={cx("small")}
                className={cx("detail")}
                label={`Current Round Seed (${gameId || "-"})`}
                content={seedHash}
                copiable={!!seedHash}
            />
            <RandomNumber txIds={txIds} onTxIdChange={onTxIdChange} seedHash={seedHash} />
            <Result modulusBy={modulusBy} result={resultNumber} randomNumber={randomNumber} />
            <Detail
                contentClassName={cx("detail-content")}
                className={cx("detail")}
                label="Final Result"
                content={finalResult}
            />
            <ResultTable game={gameCode} />
        </div>
    );
}

export default CoinBattles;
