import { useQuery } from "@tanstack/react-query";
import BetDetailPresenter from "src/components/stats-table/BetDetailPresenter";
import { axiosInstance } from "src/core/http/axios-instance";
import { CryptoCurrency } from "src/core/currency/currency.model";

interface IBetDetailContainerProps {
    isOpen: boolean,
    onClose: () => void,
    betAmount: number,
    gameCode: string,
    multiplier: string | number,
    asset: CryptoCurrency,
    isWin: boolean,
    playerId: number,
}

export interface IBetDetails {
    area: string;
    balance: number;
    game_id: number;
    game_result: string;
    payout_balance?: number;
    payout_txid?: string;
    status: string;
    txid: string;
    txid_for_url: string;
}

export interface IBetsDetailsResponse {
    timestamp: number;
    data: IBetDetails[]
}

async function getBetDetail(asset: string, gameCode: string, playerId: number) {
    const { data } = await axiosInstance.get<IBetsDetailsResponse>("/v1/bets/details", {
        params: {
            asset,
            game_code: gameCode,
            player_id: playerId,
        },
    });
    return data?.[0];
}

export default function BetDetailContainer({
    isOpen,
    onClose,
    betAmount,
    gameCode,
    multiplier,
    playerId,
    isWin,
    asset,
}: IBetDetailContainerProps) {
    const { data: betDetails, isLoading } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ["betDetails", gameCode],
        queryFn: () => getBetDetail(asset, gameCode, playerId),
        enabled: true,
    });

    if (!betDetails) {
        return null;
    }

    return (
        <BetDetailPresenter
            isOpen={isOpen}
            onClose={onClose}
            betAmount={betAmount}
            gameCode={gameCode}
            multiplier={multiplier}
            isWin={isWin}
            asset={asset}
            betDetails={betDetails}
        />
    );
}


