import { useAsync } from "@ntropy/hooks";
import { load as loadUser } from "src/core/auth/auth.reducer";
import { getWallets } from "src/core/wallet/wallet.reducer";
import { load as loadAffiliate } from "src/core/affiliate/affiliate.reducer";
import useAppStore from "src/useAppStore";
import { useAppDispatch } from "src/redux/reducer";
import { useCallback } from "react";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";

export function useLoadHandcashUserWithAddons() {
    const dispatch = useAppDispatch();

    const [walletProvider] = useWalletProvider();

    const loadHandcashUserWithWalletsAndAffiliate = useCallback(async () => {
        try {
            const userData = await dispatch(loadUser());

            console.log("setUserData", userData);

            useAppStore.getState().setUserData(userData);

            dispatch(getWallets());
            dispatch(loadAffiliate());
        } catch (e) {
            console.error("Error when loading user", e);
            useAppStore.getState().setAssetIsLoading(false);
        }
    }, [dispatch])

    useAsync(async () => {
        if (walletProvider === WalletProvider.HandCash) {
            await loadHandcashUserWithWalletsAndAffiliate();
        }
    }, [walletProvider]);
}