import { useCallback, useEffect, useMemo, useState } from "react";

import { IDropDownValue } from "src/affiliate/components/reusable/drop-down/DropDown";

export default function useDropdown(defaultValue: IDropDownValue, timer = false) {
    const [value, setValue] = useState<IDropDownValue>(() => defaultValue);
    const [trigger, setTrigger] = useState(false);

    const onClickHandler = useCallback(
        (item: IDropDownValue) => {
            setValue(item);
            setTrigger(false);
        },
        [setValue, setTrigger]
    );

    useEffect(() => {
        let timerId;
        if (timer) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            timerId = setTimeout(() => setValue({}), 0);
        }
        return () => clearTimeout(timerId);
    }, [trigger, timer]);

    const dropDownValue = useMemo(() => value, [value, setValue]);
    return {
        onClickHandler,
        value: dropDownValue,
        trigger,
        setTrigger,
        setValue,
    };
}
