const pageDescription = "Currently, We accept Bitcoin SV as the main currency on Peergame.";

export default function Payments() {
    return (
        <>
            <p>
                {pageDescription}
                <br />
                We ensure your deposits and withdrawals are processed securely and almost instant.
            </p>
            <p>
                However, we charge no fees; some transactions may be subject to charges applied by processing 3rd party
                wallets.
            </p>
            <p>
                The User may only use his/her cryptocurrency wallet (confirmed and integrated by Peergame) to deposit
                to/withdraw from Peergame.
            </p>
            <p>
                The minimal amount for a deposit and withdrawal, as well as the number of necessary confirmations to make a
                withdrawal in cryptocurrency, are noted below:
            </p>
            <p>
                Confirmations: 0<br />
                Minimum Deposit Amount: 0.001 BSV
                <br />
                Maximum Withdrawal Amount: No Limit
                <br />
            </p>
            <p>
                The minimum amount of deposit in Bitcoin SV to the User account is to 0.001 BSV. The Company retains the right
                to change this minimum amount at any time should it seems so appropriate.
            </p>
        </>
    );
}
