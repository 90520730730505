import { animated } from "@react-spring/web";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import Clovers from "./assets/clovers.svg?inline";
import Diamonds from "./assets/diamonds.svg?inline";
import Hearts from "./assets/hearts.svg?inline";
import Spades from "./assets/spades.svg?inline";
import CardBack from "./assets/card-back.svg?inline";
import { CSSProperties } from "react";

const cx = classNames.bind(styles);

export const cardSymbols = {
    C: Clovers,
    D: Diamonds,
    H: Hearts,
    S: Spades,
};

interface PlayingCardProps {
    card?: string;
    width: number;
    style?: CSSProperties;
    className?: string;
    [key: string]: any;
}

const PlayingCard = ({
    card,
    width,
    style,
    className,
    ...props
}: PlayingCardProps) => {
    const cardHeight = width / 0.7;
    const borderRadius = cardHeight / 30;
    const cardPadding = cardHeight / 18.75;
    const smallNumberFontSize = cardHeight / 5;
    const bigNumberFontSize = cardHeight / 3.75;
    const symbolDimensions = cardHeight / 10;
    const symbol = card && cardSymbols[card[1]]?.({ style: { height: symbolDimensions, width: symbolDimensions } });
    const cardValue = card?.[0]?.replace("T", "10");

    return (
        <animated.div
            {...props}
            className={cx("playing-card", className)}
            style={{
                ...style,
                width,
                height: cardHeight,
                borderRadius,
            }}
        >
            <div
                className={cx("face", ["H", "D"].includes(card?.[1] || "") ? "red" : "black")}
                style={{ padding: cardPadding, borderRadius }}
            >
                <div className={cx("top")}>
                    <span style={{ fontSize: smallNumberFontSize, lineHeight: `${smallNumberFontSize}px` }}>{cardValue}</span>
                    {symbol}
                </div>
                <span style={{ fontSize: bigNumberFontSize, lineHeight: `${bigNumberFontSize}px` }}>{cardValue}</span>
                <div className={cx("bottom")}>
                    <span style={{ fontSize: smallNumberFontSize, lineHeight: `${smallNumberFontSize}px` }}>{cardValue}</span>
                    {symbol}
                </div>
            </div>
            <CardBack className={cx("back")} style={{ borderRadius, height: cardHeight + 2 }} />
        </animated.div>
    );
}

export default PlayingCard;
