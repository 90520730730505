import classNames from "classnames/bind";
import styles from "../../../static/assets/styles.scss";
import PageContainer from "src/containers/PageContainer/PageContainer";

const pageTitle = "Affiliate Terms & Conditions";

const cx = classNames.bind(styles);

const AffiliateTermsAndConditions = () => (
    <PageContainer pageTitle={pageTitle} contentClass={cx("static-page")} dark>
        <div className={cx("content")}>
            <h3>{pageTitle}</h3>
            <p>
                This document incorporates an agreement (“The Affiliate Agreement”) between you (“you” or “Affiliate”) and
                https://www.peergame.com (“Company”, “us” or “we”). If you register for the Affiliate program, and access our
                marketing tools or redeem any of our rewards or bonuses, you warrant that you have read and comprehended the
                Affiliate Agreement and accept all the terms contained herein. We may alter the terms contained within this
                Agreement at any point. Though we try to notify you regarding such changes, it is advisable to check out this
                page periodically. If you continue to partake in the Affiliate Program, it will be considered that you agree to
                the terms and conditions.
            </p>
            <h5>1. General</h5>
            <p>
                1.1 “Affiliate” refers to you- either an individual or an entity- who applies to partake in the Affiliate
                Program.
            </p>
            <p>
                1.2 The phrase “Affiliate Account” refers to the account that is established for an Affiliate once an Affiliate
                Application is submitted by the Affiliate with the intention by partaking in the Affiliate Program after it is
                approved by Company.
            </p>
            <p>
                1.3 “Affiliate Agreement” entails (i) the different terms and conditions incorporated in this document, (ii) the
                terms and conditions of the Commission Structures that are relevant for different brands as well as products,
                and (iii) other sets of rules or guidelines devised by the Company and/or Websites that are informed to the
                Affiliate if and when they are laid out.
            </p>
            <p>
                1.4 “Affiliate Application” refers to the application created and submitted by the Affiliate in order to partake
                in the Affiliate Program.
            </p>
            <p>
                1.5 “Affiliate Links” is a phrase that denotes internet hyperlinks that the Affiliate link uses from the
                Affiliate Website(s). It could also refer to the Company Websites’ third party website.
            </p>
            <p>
                1.6 “Affiliate Program” refers to the partnership or association between the Company and the Affiliate, wherein
                the Affiliate works to promote the website of the Company through the creation of Affiliate Links from the
                Affiliate Website(s) to Company’s websites. The Affiliate receives a commission for rendering such services,
                albeit the amount depends on the traffic that is garnered on the websites of the Company, and this aspect is
                subject to terms contained within this Affiliate Agreement as well as the Commission Structure of the
                corresponding product.
            </p>
            <p>
                1.7 “Affiliate Wallet” refers to an e-wallet that is established in the Affiliate’s name. The company forwards
                the commission to the Affiliate Wallet along with the other payments that the Affiliate is owed, following
                which, the Affiliate can withdraw the amount while adhering to the Affiliate Agreement;
            </p>
            <p>
                1.8 “Affiliate Website” refers to an online website that the Affiliate maintains, operates or even controls in
                any manner.
            </p>
            <p>
                1.9 In this document, “Commission” is used to refer to either the percentage of the Net Gaming Revenue, or a
                standard amount for a New Customer (CPA structure), as per the guidelines stated in the Commission Structures.
            </p>
            <p>
                1.10 This document defines “Commission Structures” as any particular structures related to rewards, that both
                the Company and the Affiliate agree upon.
            </p>
            <p>
                1.11 “Confidential Information” entails any information, that to the Company, is valuable in economic or
                essentials terms including, but not limited to, financial information, trade secrets, know-how, rates, and
                custom prices, information pertaining to business, products, business strategies, records of old and New
                Customers, technology, information regarding individuals who use Company Websites, marketing strategies, and
                manners of operation.
            </p>
            <p>
                1.12 “Intellectual Property Rights” includes the Company’s copyrights, trademarks, service marks, domain names,
                brands, business names, and registrations of the aforementioned aspects. It also includes any rights that are of
                similar nature.
            </p>
            <p>
                1.13 “Wagered Amount” is the phrase used to refer to the bet placed by the players in terms of money or assets.
            </p>
            <p>
                1.14 This document uses the phrase “New Customer” to refer to a customer who is new to the Company and has
                deposited an amount for the first time, provided the amount is equal to or more than the minimum deposit
                determined by the Company. The amount must be deposited in the player’s account on the Company Websites, in
                compliance with the relevant terms and conditions stated on Company Websites. An Affiliate cannot be a New
                Customer.
            </p>
            <p>
                1.15 “Parties” include the Company and the Affiliate (when referred to singularly, each is called a “Party”).
            </p>
            <p>
                1.16 “Personal Data” includes information pertaining to an individual or an entity, which can be recognized
                through direct or indirect means.
            </p>
            <h5>2. Affiliate Obligations</h5>
            <p>
                2.1 Registering as Affiliate <br />
                In order to partake in our Affiliate Program, it is mandatory to explicitly agree to the terms and conditions.
                This can be done by checking the box prior to the submission of your Affiliate Application. The submitted
                Affiliate Application is a crucial aspect of the overall Affiliate Agreement. The Company reserves the right to
                accept or reject an Affiliate Application and its decision is ultimate, and cannot be contested by appeal. The
                medium of email services will be used to inform applicants regarding the acceptance or rejection of their
                Affiliate Application. The Company can seek any documentation for proof while verifying the submitted Affiliate
                Application. You agree to submit documentation at any point in time, as long as the Affiliate Agreement is
                valid, to facilitate the verification of the Affiliate Account information. The required documentation could
                include bank statements, individual, corporate identity papers, proof of address, and any other documents that
                may be deemed to be relevant. You agree that it is your responsibility to make sure that the information that
                you submit when you register for the Affiliate Program is accurate and it is consistently updated whenever
                necessary.
            </p>
            <p>
                2.2 Affiliate login details <br />
                You agree that it is solely your responsibility to ensure that the information required to log in on your
                Affiliate Account remains confidential and secure. If any unauthorized individual or entity uses your Affiliate
                Account due to your negligence or inability to maintain the confidentiality of your login details, you agree
                that you are solely responsible and liable for the event. If you believe that your Affiliate Account is being
                used unlawfully, you are obligated to inform us promptly.
            </p>
            <p>
                2.3 Affiliate Program participation <br />
                The Affiliate Program that you chose to apply is only for your independent participation and we do not permit
                creating an Affiliate Account for a third party, or even brokering or reassigning your account to another
                individual or entity. If any Affiliate wants to transfer their account to another individual or entity, they are
                required to seek permission. No Affiliate is allowed to create more than one Affiliate Account without our
                written consent. If you accept our terms and conditions, you agree that to the best of your capacity, you will
                actively promote the Company Websites in compliance with the Affiliate Agreement and other subsequent
                instructions forwarded by the Company at any point in time. You agree that the steps you undertake in the
                process and in adherence with the Affiliate Agreement will positively impact the Company and will not
                deteriorate its reputation. In order to perform your marketing activities, you can leverage the Affiliate Links
                to the Company Websites as well as other approved materials we may introduce. No other methods can be undertaken
                for advertising purposes.
            </p>
            <p>
                2.4 Affiliate Website <br />
                The creation, maintenance, and operation of the Affiliate Website are your sole responsibility as well as the
                content and materials that are presented on the Affiliate Website. At all times, you must take the
                responsibility to make sure that your Affiliate Website adheres to the relevant laws such as General Data
                Protection Regulation (GDPR), and that it operates as a professional website. No Affiliate is permitted to
                present its Affiliate Website in a manner that it may be confused with any of the Company Websites, or it may
                create a perception that the Company owns or operates it. You accept that the Affiliate Website will not include
                content that is considered to be defamatory, libelous, bigoted, offensive, or inappropriate in any manner. Such
                content includes, but is not limited to violent, obscene, derogatory or pornographic materials, or illegal
                content in a particular country.
            </p>
            <p>
                2.5 Valid traffic and good faith <br />
                You agree that you will not register a New Customer through direct or indirect means in order to generate
                traffic to Company Websites, for instance by asking family members or friends to register as New Customers. Any
                such activity will be deemed to be fraudulent. You agree to not try to make money from the traffic that is not
                generated in good faith. If you learn or believe that a New Customer who registered based on your referral is
                directly or indirectly linked to bonus abuse, money laundering, fraud, or other abuse of remote gaming websites,
                you are obligated to inform us. You also agree that if a New Customer is identified as a bonus abuser, money
                launderer or fraudster or who is an accomplice in affiliate fraud (regardless of how the information surfaces),
                they would not be considered a valid New Customer under the Affiliate Agreement, and hence the concerned
                Affiliate will not be paid a Commission in relation to such New Customers.
            </p>
            <p>
                2.6 Unsuitable websites <br />
                You agree that you will not use any Affiliate Links or display digital advertisements that feature our
                Intellectual Property on other inappropriate websites (regardless of whether a third party owns them or anyone
                else). Inappropriate websites are websites directed at children, contain unlawful pornography or other content
                containing unlawful sexual acts, glorify or endorse violence, encourage or engage in discrimination based on
                race, sex, religion, nationality, disability, sexual orientation, or age, encourage unlawful activities or in
                breach the intellectual property rights of any third party or of the Company directly or indirectly, or breach
                advertising regulations or codes of practice in any manner and in any region where either the Affiliate Links or
                digital advertisements are displayed.
            </p>
            <p>
                2.7 Affiliate Links <br />
                You agree to display the Affiliate Links either as prominently or more prominently in comparison to any other
                sales links displayed on the Affiliate Website. The Affiliate Links that the Company provides can only be used
                as described within the scope of the Affiliate Program. We prohibit masking the Affiliate Links, such as
                obscuring the source of the traffic directed towards the Company’s Websites.
            </p>
            <p>
                2.8 Use of Company Intellectual Property Rights <br />
                The brand guidelines issued to you must be adhered to while making use of the Company’s Intellectual Property
                Rights, which will always be subject to the permission required in the clause stated. You agree to not register
                domain names, search terms, as well as other identifiers, with the intention of using them in any search engine,
                portal, app store, sponsored advertising service, or other referral services, which are either identical to or
                the same as the trademarks of the Company.
            </p>
            <p>
                2.9 Approved creative <br />
                You are prohibited from making use of advertising layouts, banners, images, or logos, which include our
                Intellectual Property Rights prior to the written approval of the Company if they were created by you. In case
                the advertising layout was provided by the Company or was approved by it, you are not permitted to alter its
                appearance in any manner. Prior to the launch of an advertising campaign or creative, you are obligated to seek
                permission from the Company and make sure that you are provided with written approval, specifically related to
                the advertising. Upon request, you must be able to showcase such approval.
            </p>
            <p>
                2.10 Loyalty Programs <br />
                You are not permitted to offer programs such as cash-back / value-back and others, which are not offered on the
                Company Websites. 2.11 Responsible Gaming The Company is committed to facilitate responsible gaming and prevent
                any type of gambling addiction among users. You agree that you will contribute to the message pertaining to
                responsible gaming that the Company wishes to promote. You agree to not use content that targets people aged
                under 18 (or whatever is the legal age for gambling in the region).
            </p>
            <p>
                2.12 Illegal activity <br />
                You are prohibited from promoting the Company in any territory or jurisdictions where gambling is unlawful. You
                agree to adhere to any applicable laws at all times and refrain from undertaking any actions that are illegal in
                general or in terms of the Affiliate Program.
            </p>
            <p>
                2.13 Data Protection and Cookies <br />
                You are required to adhere to the General Data Protection Regulation (GDPR) as well as other data protection
                acts, regulations, and laws- both existing and future- that are relevant in your region. This clause includes
                any such regulations or laws related to ‘cookies’ and their uses.
            </p>
            <p>
                2.14 Cost and expense <br />
                You take sole responsibility for the risks, costs, as well as expenses borne by you when you are engaged in
                adhering to the obligations detailed in the Affiliate Agreement. 2.15 Company monitoring of Affiliate activity.
                If and when required and asked for, you will instantaneously offer your assistance to the Company and provide us
                with information that is necessary to track your activity under the Affiliate Program.
            </p>
            <p>
                2.16 Commissions paid incorrectly <br />
                When requested by the Company, the Affiliate agrees to return any Commissions that the Company believes were
                made by either breaching the Affiliate Agreement or resorting to fraudulent or falsified transactions.
            </p>
            <h5>3. Affiliate Rights</h5>
            <p>
                3.1 Right to direct New Customers <br />
                During the term of the Affiliate Agreement, the Affiliate is granted the non-exclusive, non- transferable right
                to transfer the New Customers to the Company Websites we have agreed upon, in strict compliance with the terms
                of the Affiliate Agreement. You do not hold the right to claim Commission or other compensation for traffic
                generated by other individuals or entities.
            </p>
            <p>
                3.2 License to use Company Intellectual Property Rights <br />
                During the term of the Affiliate Agreement, the Affiliate is granted the non-exclusive, non- transferable
                license to make use of the Company Intellectual Property Rights, which could be approved by us on a time-to-time
                basis only in relation to the presentation of promotional materials on the Affiliate Website or in other places
                upon approval (written) by Company. Sub- licensing, transfer, or assignment of the license is not allowed.
            </p>
            <p>
                3.3 Players’ Personal Data <br />
                In connection with the services rendered under the terms of this Agreement, the Affiliate is not permitted to
                access any Personal Data of Company’s customers.
            </p>
            <h5>4. Company Obligations</h5>
            <p>
                4.1 We will direct our best efforts to offer you all materials and information that are necessary to implement
                the Affiliate Links.
            </p>
            <p>
                4.2 It is the Company’s discretion to register New Customers directed towards the Company Websites by an
                Affiliate and the Company reserves the right to track their transactions. The Company holds absolute right to
                refuse New Customers (or delete their accounts) if and when required, in order to adhere to the requirements
                that we may develop on a periodic basis.
            </p>
            <p>
                4.3 We shall provide you with monitoring tools that help you in monitoring your Affiliate Account as well as
                your Commission’s level and the corresponding payment.
            </p>
            <p>
                4.4 We reserve the right to use any Affiliate employee’s personal data and process it in this manner: username
                as login details, your email address, name, date of birth, your country and address, telephone number, and
                financial information to ensure optimal security, fulfillment the AML legal requirements and to handle and
                manage our business relationship.
            </p>
            <p>
                4.5 If you strictly adhere to the Affiliate Agreement, we will you the Commission in compliance with Clause 6.
            </p>
            <h5>5. Company Rights and Remedies</h5>
            <p>
                If you breach this Agreement (or there is a suspected breach of the Agreement) or if it is identified that you
                neglected your performance under this Affiliate Program, or if you failed in meeting your obligations outlined
                here, there are several remedies that are available for the Company to turn to a) the Company reserves the right
                to suspend you from partaking in the Affiliate Program during the time period wherein the investigation is
                carried out regarding the breach of the Affiliate Agreement. The Company will also suspend the payment of
                Commissions during such periods; b) the Company holds the right to hold back payments or Commission to an
                Affiliate in relation to specific campaigns, traffic, content, or activity that the Affiliate was involved in if
                any such activities breach the obligations of the Affiliate under the Affiliate Agreement; c) the Company also
                reserves the right to withhold any amount of money from the Commission that is determined to be reasonable to
                cover any indemnity given by the Affiliate hereunder or to cover the Company’s liability that is a result of the
                breach of the Affiliate Agreement by the Affiliate; d) termination of the Affiliate Agreement; e) withholding
                the money contained in the Affiliate Wallet, provided it hasn’t been withdrawn within a period of 3 (three)
                months following the termination of the Affiliate Agreement in accordance with clause 9.1. The rights and
                remedies of the Company, outlined above, are not mutually exclusive.
            </p>
            <h5>6. Fees and Taxes</h5>
            <p>
                6.1 Based on the compliance with the provisions contained within the Affiliate Agreement, an Affiliate earns
                Commission in adherence to the Commission Structure. The Company reserves the right to alter the percentage of
                Commission as well as the method undertaken to calculate Commission in accordance with this clause.
            </p>
            <p>
                6.2 At the end of every wager placed by placers, the Commission is computed, and 48 hours after each wager, the
                payment will be processed. Because of technical problems, the timeline may be extended to some extent, but the
                payments shall be made not later than 7 days in such cases.
            </p>
            <p>
                6.3 Our Affiliate Wallet will be used to make the payment of Commission. In order to comply with the existing
                regulations, there may be a need for Affiliates to verify and submit “know your customer” documentation before
                they are allowed to withdraw.
            </p>
            <p>
                6.4 During the computation of the Commission, if any erroneous results are attained, the Company reserves the
                right to rectify such mistakes at any point either by paying out the underpayment or reclaiming overpayment made
                to the Affiliate.
            </p>
            <p>
                6.5 At the Company’s sole discretion, the Affiliate may be offered an opportunity to restructure the commission
                structure.
            </p>
            <p>
                6.6 Once the Affiliate accepts a Commission payment, it will be considered to be the final settlement of the
                amount that was due for the specific period. If the Affiliate does not agree to the balance dues that are
                reported, they must inform the Company within fourteen (14) calendar days and elaborately explain the reason for
                their disagreement. If the Company is not notified within the stipulated time, it would be considered that the
                balance due for the specific period has been accepted.
            </p>
            <p>
                6.7 The Commission is exclusive of any relevant tax. The Affiliate is solely responsible for the payment of
                applicable taxes, levies, charges, and other amounts that they are required to pay to the tax authority,
                department, or other competent entity, in terms of the Compensation provided under the Affiliate Agreement.
            </p>
            <h5>7. Confidential Information</h5>
            <p>
                In relation to the Affiliate Agreement, the Company may divulge to you confidential information regarding its
                business, operations, or underlying technology and/or the Affiliate Program (such as Commissions you make under
                the Affiliate Program).
            </p>
            <p>
                You agree that you will neither disclose such confidential information nor permit the unauthorized use of such
                confidential information to third parties without prior written permission from the Company. You agree that the
                confidential information will be used by you only under the terms of the Affiliate Agreement. With regard to
                this clause, your obligations will remain even after the termination of this Agreement.
            </p>
            <p>
                Additionally, until you receive the written consent of the Company, you are not permitted to issue any form of
                formal communication to the public regarding your participation in the Affiliate Program. Such content must be
                approved by the Company.
            </p>
            <h5>8. Term and Termination</h5>
            <p>
                8.1 Term <br />
                Once you are approved as an Affiliate, the terms of the Affiliate Agreement will become effective and will
                remain so until one of the Parties states in writing that it wishes to terminate the Agreement. In such a case,
                30 days after the notice, the Agreement will be terminated. Information passed via email, regarding the
                termination of the agreement, will be considered to be a written form of notification. In order to avoid any
                doubts, the Company may choose to terminate the Agreement (which would be effective immediately) if the
                Affiliate does not meet fulfill their responsibilities described in the Agreement or are negligent in any
                manner.
            </p>
            <p>
                8.2 Affiliate actions upon termination <br />
                If terminated, you are required to delete the Company banners and other forms of creatives from the Affiliate
                Website and the Affiliate Links for the Company Websites must also be disabled. The rights as well as licenses
                that have been provided to you under the Affiliate Agreement will also be terminated immediately. Any
                confidential information, documents, and their copies that you possess must be returned to the Company and you
                must suspend the uses of all Company Intellectual Property Rights.
            </p>
            <p>
                8.3 Commission <br />
                Once the Affiliate Agreement is terminated, the Commission pertaining to New Customers who are directed to the
                Company during the term will no longer remain payable.
            </p>
            <h5>9. Use of Player Account</h5>
            <p>
                9.1 Disclaimer <br />
                The Company does not make any express or implied warranties or representations regarding the Affiliate Program,
                Company, or the Commission payment arrangements (such as functionality, merchantability, or non-infringement).
                Additionally, we do not guarantee that our sites will remain functional without any interruptions or errors and
                you agree that the Company cannot be held responsible for the consequences of such interruptions and errors. If
                there are any discrepancies between the reports presented within the Affiliate Account system and the Company
                database, the latter will be considered to be accurate.
            </p>
            <p>
                9.2 Indemnity and Limitation of Liability <br />
                When you become a part of the Affiliate Program, you agree to indemnify and protect the Company, our directors,
                employees, and representatives against liabilities, losses, damages, and costs, which includes legal fees, that
                may have emerged in relation to (a) breach of any provision of the Affiliate Agreement by the Affiliate, (b) you
                performance of both duties and obligations that have been described in the Affiliate Agreement, (c) your
                negligence or (d) any injury that resulted directly or indirectly by the Affiliate’s actions or inactions, or
                the unauthorized use of any of our creatives and links or this Affiliate Program. The affiliate agrees to not
                hold the Company liable for any direct or indirect, special, or consequential damages (or any loss of revenue,
                profits, or data), any loss of goodwill or reputation, that may have been caused in connection with the
                Affiliate Agreement or the Affiliate Program, even in cases wherein we were informed about such potential
                damages.
            </p>
            <p>
                9.3 Non-Waiver <br />
                If we fail in enforcing any provision contained within the Affiliate Agreement, it does not warrant a waiver of
                our right to enforce either that specific provision or other provisions present in the Affiliate Agreement.
            </p>
            <p>
                9.4 Relationship of Parties <br />
                You agree that both the Company and the Affiliate are two independent contractors and the Affiliate Agreement
                does not, in any way, lead to the establishment of a partnership, joint venture, agency, franchise, or
                employment relationship among the parties. You do not hold a right to either make or accept any offer on the
                Company’s behalf. You agree to not issue a statement that contradicts any clauses contained within the Affiliate
                Agreement.
            </p>
            <p>
                9.5 Force Majeure <br />
                No part will be held responsible in cases of any delays or inability to fulfill their obligations presented
                within the Affiliate Agreement, in cases wherein such a scenario is the result of a cause that cannot be
                controlled, including but not limited to, labor disputes, strikes, industrial disturbances, acts of God, acts of
                terrorism, floods, lightning, utility or communications failures, earthquakes or other casualties. In such
                cases, the party that fails to fulfill its obligations is not held liable and is excused from performing such
                obligations which cannot be fulfilled under the given circumstances. However, if the force majeure event
                continues even after thirty (30) calendar days, the Affiliate Agreement can be terminated by either Party by
                sending a written notice.
            </p>
            <p>
                9.6 Assignability <br />
                Without our prior consent, you cannot assign the Affiliate Agreement. 9.7 Severability In case it is determined
                that any provision stated within the Affiliate Agreement cannot be enforceable under law or is invalid in any
                manner, the provision will be rendered ineffective to the extent of its unenforceability. The rest of the
                Affiliate Agreement will not be affected in such a case and shall remain effective.
            </p>
            <p>
                9.7 English language <br />
                It is understood that English was used to draft the primary Affiliate Agreement. In case there are any
                contradictions between the English version and other versions, the former is deemed to be accurate.
            </p>
            <p>
                9.8 Modification of Terms & Conditions <br />
                The company, in its sole discretion, can alter any of the terms and conditions described in the Affiliate
                Agreement or any point, replace it, and a notice or the new agreement is published on our site. The
                modifications can relate to any provision, such as alteration of the scope of available Commissions and
                Affiliate Program rules. In case you do not agree with any modifications, you are required to terminate the
                Affiliate Agreement. If you continue to partake in our Affiliate Program after we notify you about the changes
                through our site, it is warranted as a binding acceptance of the alterations or the new agreement.
            </p>
        </div>
    </PageContainer>
);

export default AffiliateTermsAndConditions;
