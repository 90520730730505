import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";

interface IVerifyChallengePayload {
    walletAddress: string
    challengeSignature: string
}

interface IVerifyChallengeResponse {
    token: string
}

export const useVerifyChallenge = () => {
    const { mutateAsync, isError, isSuccess } = useMutation({
        mutationFn: (payload: IVerifyChallengePayload) => axiosInstance
            .post<IVerifyChallengePayload, IVerifyChallengeResponse>(Endpoint.AuthChallengeVerify, payload),
    })

    return {
        verifyChallenge: mutateAsync,
        isError,
        isSuccess,
    };
}