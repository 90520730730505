import Helmet from "react-helmet";
import styled from "styled-components";
import { Caption, StyledTitle } from "src/affiliate/styled/typography.styled";
import CommissionRateCard from "src/affiliate/components/commission-plans/CommissionRateCard";
import CommissionTable from "src/affiliate/components/commission-plans/CommissionTable";
import useFetch from "src/helpers/useFetch";
import alertIcon from "../../../affiliate/components/reusable/assets/Alert-white.svg";
import Layout from "src/affiliate/components/Layout";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import Loader from "src/components/loader/Loader";

const StyledCaption = styled(Caption)`
    @media only screen and (max-width: 1157px) {
        white-space: unset;
    }
`;

interface ICommissionPlanData {
    pr_condition_list: any[];
    require_referrals: number;
    require_wagered: number;
    commission_rate: number;
    tier_name: string;
    referrals: number;
    wagered: number;
}

const defaultDataValues: ICommissionPlanData = {
    pr_condition_list: [],
    require_referrals: 0,
    require_wagered: 0,
    commission_rate: 0,
    tier_name: "",
    referrals: 0,
    wagered: 0,
}

const CommissionPlans = () => {
    const { data, loaded } = useFetch<ICommissionPlanData>("/api/v1/affiliate/commission/info")
    const {
        pr_condition_list: prConditionList,
        require_referrals: requiredReferrals,
        require_wagered: requiredWagered,
        commission_rate: commissionRate,
        tier_name: tierName,
        referrals,
        wagered,
    } = loaded && data ? data : defaultDataValues;

    return (
        <>
            <Helmet>
                <title>Commission-plans</title>
                <meta property="og:title" content="Peergame commission-plans" />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
                />
            </Helmet>
            <Layout transparent sideNav>
                {loaded ? (
                    <FlexContainer
                        flexDirection="column"
                        padding="42px 0 178px 38px"
                        tabletPadding="58px 24px 333px 24px"
                        mobilePadding="22px 16px 112px 16px"
                        width="800px"
                        mobileWidth="100%"
                        tabletWidth="100%"
                    >
                        <FlexContainer
                            alignItems="center"
                            tabletAlignItems="flex-end"
                            justifyContent="space-between"
                            mobileFlexDirection="column"
                        >
                            <FlexContainer flexDirection="column" width="52%" tabletWidth="44%" mobileWidth="100%">
                                <FlexContainer
                                    mobileWidth="100%"
                                    tabletMargin="0 0 67px 0"
                                    mobileJustifyContent="center"
                                    mobileMargin="0 0 28px 0"
                                >
                                    <StyledTitle
                                        tabletMargin="0 0 0 40px"
                                        mobileMargin="0"
                                        mobileFontSize="20px"
                                    >
                                        Commission Plans
                                    </StyledTitle>
                                </FlexContainer>
                                <FlexContainer flexDirection="column" width="100%">
                                    <StyledCaption>
                                        You will forever receive commission from every user you refer. Every bet they make you will receive
                                        a percentage.
                                    </StyledCaption>
                                    <StyledCaption margin="16px 0 8px 0">
                                        You can derive your commission using the following formula:
                                    </StyledCaption>
                                    <StyledCaption whiteSpace="nowrap" color="#ff2976">
                                        Wagered amount * 1% * Commission rate
                                    </StyledCaption>
                                    <FlexContainer alignItems="start" margin="16px 0 0 0">
                                        <img src={alertIcon} alt="Alert icon" />
                                        <StyledCaption margin="0 0 0 8px">
                                            Your commission level may change in the last 30 days of transactions. Wagered is reflected into
                                            level and commission rate once every 24 hours.
                                        </StyledCaption>
                                    </FlexContainer>
                                </FlexContainer>
                            </FlexContainer>
                            <FlexItem width="42%" tabletWidth="47%" mobileWidth="100%" mobileMargin="53px 0 0 0">
                                <CommissionRateCard
                                    wagered={wagered}
                                    referrals={referrals}
                                    commissionRate={commissionRate}
                                    requiredReferrals={requiredReferrals}
                                    requiredWagered={requiredWagered}
                                    tierName={tierName}
                                />
                            </FlexItem>
                        </FlexContainer>
                        <FlexItem margin="77px 0 0 0" mobileMargin="77px 0 0 0">
                            <CommissionTable data={prConditionList} tierName={tierName} />
                        </FlexItem>
                    </FlexContainer>
                ) : (
                    <Loader />
                )}
            </Layout>
        </>
    );
};
export default CommissionPlans;
