import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import Detail from "src/components/detail/Detail";

const cx = classNames.bind(styles);

interface ResultProps {
    randomNumber?: string;
    result?: string;
    modulusBy: number;
}

const Result = ({ randomNumber, result, modulusBy }: ResultProps) => {
    function getContent() {
        return (
            <div className={cx("hidden")}>
                <span className={cx("small")}>{randomNumber}</span>
                {result && (
                    <div className={cx("mt-8", "flex-center")}>
                        <span className={cx("mt-8", "small")}>%</span>
                        <span className={cx("modulus-by", "small")}>{modulusBy}</span>
                        <span className="small">=</span>
                        <h5 className={cx("result")}>{result}</h5>
                    </div>
                )}
            </div>
        );
    }

    return (
        <Detail
            className={cx("result")}
            label="Result"
            content={getContent()}
            copyText={randomNumber}
            copiable={!!randomNumber}
        />
    );
};

export default Result;
