import { useState } from "react";
import classNames from "classnames/bind";
import styles from "../assets/styles.scss";
import Detail from "src/components/detail/Detail";
import PlayingCard from "src/games/components/PlayingCard/PlayingCard";
import { getCardsScores } from "src/games/Blackjack/utils";
import RandomNumber from "src/containers/FairnessCalculation/RandomNumber/RandomNumber";

interface IBlackjackProps {
    data: {
        user_seed: string;
        daily_seed: string;
        card_order: string;
        scenario: {
            all_card: string[];
            dealer: string[];
            player: string[][];
        };
        game_result: {
            first_hand: string;
            second_hand?: string;
        };
        area: string;
    };
}

const cx = classNames.bind(styles);

export default function Blackjack({ data }: IBlackjackProps) {
    const {
        user_seed: clientSeed,
        daily_seed: dailySeed,
        card_order: cardOrder,
        scenario,
        game_result: gameResult,
        area,
    } = data;

    const [txIds, setTxIds] = useState([clientSeed]);

    return (
        <div>
            <Detail
                contentClassName={cx("small")}
                className={cx("detail")}
                label="Client Seed"
                content={clientSeed}
                copiable={!!clientSeed}
            />
            <Detail
                contentClassName={cx("small")}
                className={cx("detail")}
                label="Daily Server Seed"
                content={dailySeed || "Daily Seed hasn’t revealed yet"}
                copiable={!!dailySeed}
            />
            <RandomNumber
                seedHash={dailySeed || "Daily Seed hasn’t revealed yet"}
                txIds={txIds}
                onTxIdChange={(index, newValue) => setTxIds([newValue.toString()])}
            />
            <RandomNumber seedHash={cardOrder || "Daily seed hasn’t revealed yet"} isCardOrder />
            <div className={cx("bj-scenario")}>
                <h6>Generated Card Order</h6>
                <div className={cx("cards")}>
                    {scenario.all_card.map(card => (
                        <PlayingCard key={card} card={card} width={32} />
                    ))}
                </div>
                <div className={cx("title")}>
                    <span className={cx("small")}>Dealer</span>
                    <span
                        className={cx(
                            "score",
                            "black",
                            gameResult?.first_hand !== "win" && gameResult?.second_hand !== "win" ? "win" : "lose",
                        )}
                    >
            {getCardsScores(scenario.dealer).join("/")}
          </span>
                </div>
                <div className={cx("cards")}>
                    {scenario.dealer.map(card => (
                        <PlayingCard key={card} card={card} width={42} />
                    ))}
                </div>
                <div className={cx("player-hands")}>
                    <div className={cx({ dim: area !== "first_hand" })}>
                        <div className={cx("title")}>
                            <span className={cx("small")}>Player {scenario.player[1] && "- Main Hand"}</span>
                            <span className={cx("score", "black", gameResult.first_hand)}>
                {getCardsScores(scenario.player[0]).join("/")}
              </span>
                        </div>
                        <div className={cx("cards")}>
                            {scenario.player[0].map(card => (
                                <PlayingCard key={card} card={card} width={42} />
                            ))}
                        </div>
                    </div>
                    {scenario.player[1] && (
                        <div className={cx({ dim: area !== "second_hand" })}>
                            <div className={cx("title")}>
                                <span className={cx("small")}>Player - Split Hand</span>
                                <span className={cx("score", "black", gameResult.second_hand)}>
                  {getCardsScores(scenario.player[1]).join("/")}
                </span>
                            </div>
                            <div className={cx("cards")}>
                                {scenario.player[1].map(card => (
                                    <PlayingCard key={card} card={card} width={42} />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
