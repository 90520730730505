import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames/bind";
import Modal from "src/components/modal/Modal";
import styles from "./assets/styles.scss";

import IconCheckmark from "./assets/checkmark-icon.svg?inline";
import IconCamera from "./assets/camera.svg?inline";
import Button from "src/components/button/Button";
import { axiosInstance } from "src/core/http/axios-instance";
import { successToast } from "src/helpers/toast";
import { setAvatar } from "src/core/auth/auth.reducer";
import { useAppDispatch } from "src/redux/reducer";

const cx = classNames.bind(styles);

interface IProfileImagesResponse {
    timestamp: number;
    data: {
        id: number;
        image_url: string;
    }[]
}

async function getAvatars() {
    const { data } = await axiosInstance.get<IProfileImagesResponse>("/v1/common/profileimages");

    return data || []
}

async function _updateAvatar(avatarId: number) {
    await axiosInstance.post<{ image_id: number }>("/v1/mypage/modify/profileimage", { image_id: avatarId });
}

interface IAvatarProps {
    src?: string;
}

const Avatar = ({ src }: IAvatarProps) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [newAvatar, setNewAvatar] = useState(src);
    const { data: avatars } = useQuery({
        queryKey: ["avatars"],
        queryFn: getAvatars,
    });
    const { mutate: updateAvatar, isPending } = useMutation({
        mutationFn: ({ id }: { id: number, image_url?: string }) => _updateAvatar(id),
        onSuccess: (_, { image_url: imageUrl }) => {
            successToast("Avatar updated successfully");
            dispatch(setAvatar(imageUrl || ""));
            setIsOpen(false);
        },
    });

    // reset on modal close
    useEffect(() => {
        if (!isOpen) {
            setNewAvatar(src);
        }
    }, [isOpen, src]);

    return (
        <>
            <div className={cx("avatar-container")}>
                <img src={src} alt="avatar" />
                <button className={cx("hover-overlay")} type="button" onClick={() => setIsOpen(true)}>
                    <IconCamera />
                </button>
            </div>
            <Modal
                header="Change Avatar"
                isOpen={isOpen}
                onClose={() => !isPending && setIsOpen(false)}
                dark
                backgroundColor="#1F0D53"
                className={cx("change-avatar-modal")}
                footerContent={(
                    <Button
                        className={cx("save-button")}
                        onClick={() => {
                            const selectedAvatar = avatars?.find(({ image_url: imageUrl }) => imageUrl === newAvatar);
                            if (selectedAvatar) {
                                updateAvatar({ id: selectedAvatar.id });
                            }
                        }}
                        isDisabled={src === newAvatar}
                        isLoading={isPending}
                    >
                        Save
                    </Button>
                )}
            >
                <div className={cx("avatar-list")}>
                    {avatars?.map(({ id, image_url: imageUrl }) => (
                        <button key={id} type="button" onClick={() => setNewAvatar(imageUrl)}>
                            <img src={imageUrl} alt={`avatar-${id}`} />
                            {newAvatar === imageUrl && <IconCheckmark />}
                        </button>
                    ))}
                </div>
            </Modal>
        </>
    );
};

export default Avatar;
