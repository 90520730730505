import { matchRoutes, RouteConfig } from "react-router-config";

interface IRouteComponent {
    preload?: () => Promise<any>;
}

interface IRouteMatch {
    route: {
        component: IRouteComponent;
    };
    match?: {
        params?: Record<string, string>;
    };
}

interface IAsyncMatchResult {
    components: Array<IRouteComponent>;
    match: Array<IRouteMatch>;
    params: Record<string, string>;
}

function getComponents(match: IRouteMatch[]): Promise<Array<IRouteComponent>> {
    return match.reduce(async (result, component) => {
        if (component.route.component.preload) {
            const res = await component.route.component.preload();
            const ret = [...(await result), component.route.component, ...(Array.isArray(res) ? res : [res])];
            return ret;
        }
        return [...(await result), component.route.component];
    }, Promise.resolve([]));
}

function getParams(match: IRouteMatch[]): Record<string, string> {
    return match.reduce((result, component) => {
        if (component.match && component.match.params) {
            return { ...result, ...component.match.params };
        }
        return result;
    }, {});
}

const asyncMatchRoutes = async (routes: RouteConfig[], pathname: string): Promise<IAsyncMatchResult> => {
    const match = matchRoutes(routes, pathname);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const params = getParams(match);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const components = await getComponents(match);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return { components, match, params };
};

export default asyncMatchRoutes;
