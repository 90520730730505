import React from "react";
import loadable from "@loadable/component";
import Landing from "src/containers/Landing/Landing";
import Games from "src/containers/Games/Games";
import Loader from "src/components/loader/Loader";
import App from "src/containers/App/App";
import staticPages from "src/static/staticPages";
import Account from "src/containers/Account/Account";
import Affiliate from "src/containers/Affiliate/Affiliate";
import Races from "src/containers/Races/Races";

const StaticPage = loadable(() => import("src/static/StaticPage"), { fallback: <Loader /> });

interface RouteConfig {
    path: string;
    exact?: boolean;
    component: React.ComponentType<any>;
}

export interface IRouteWithComponent { component: React.ComponentType<any>, routes: RouteConfig[] }

const routes: IRouteWithComponent[] = [
    {
        component: App,
        routes: [
            {
                path: "/",
                exact: true,
                component: Landing,
            },
            ...staticPages.map(({ path }) => ({
                path,
                exact: true,
                component: StaticPage,
            })),
            {
                path: "/account",
                component: Account,
            },
            { path: "/partners", component: Affiliate },
            {
                path: "/games/:gameUrl?",
                component: Games,
            },
            {
                path: "/races",
                exact: true,
                component: Races,
            },
            {
                path: "*",
                exact: true,
                component: Landing,
            },
        ],
    },
];

export default routes;
