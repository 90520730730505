import { Suspense, useEffect, useRef } from "react";
import { Route, Switch, useParams, Redirect } from "react-router";
import classNames from "classnames/bind";
import { Howler } from "howler";
import PageContainer from "src/containers/PageContainer/PageContainer";
import GamesList from "src/components/games-list/GamesList";
import styles from "./styles.scss";
import StatsTable from "src/components/stats-table/StatsTable";
import useAppStore from "src/useAppStore";
import { games } from "src/games/config";
import { errorToast } from "src/helpers/toast";
import Restriction from "src/static/Restriction";
import Maintenance from "src/static/Maintenance";
import AreYouAdult from "src/components/Popup/AreYouAdult";
import GameCodeContext from "src/containers/Games/GameCodeContext";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/redux/reducer";
import { useMaintenance } from "src/core/maintenance/hooks/useMaintenance";
import { CryptoCurrency } from "src/core/currency/currency.model";

const cx = classNames.bind(styles);

interface RootState {
    info: {
        accessAvailable: boolean;
        maintenance: {
            code: string;
            game: { name: string; is_maintenance: string }[];
        };
    };
}

interface IGameParams {
    gameUrl: string;
}

const getMuted = () => {
    return "_muted" in Howler ? Howler._muted as boolean : false;
}
const Games = () => {
    const { gameUrl } = useParams<IGameParams>();
    const prevSoundState = useRef(getMuted());
    const accessAvailable = useAppSelector((state: RootState) => state.info?.accessAvailable);
    const selectedAsset = useAppStore(state => state.asset.selected);
    const { pathname } = useLocation();

    const gameCode = games[gameUrl]
        ? gameUrl
        : Object.entries(games).find(([, { urlName }]) => urlName && urlName === gameUrl)?.[0];

    const isMaintenance = useMaintenance({ forGame: gameCode });

    useEffect(() => {
        function visibilityListener() {
            const { hidden } = document;
            if (!hidden) {
                Howler.mute(prevSoundState.current);
            } else {
                prevSoundState.current = getMuted();
                Howler.mute(true);
            }
        }

        document.addEventListener("visibilitychange", visibilityListener);
        return () => document.removeEventListener("visibilitychange", visibilityListener);
    }, []);

    useEffect(() => {
        if (games[gameCode]?.pgpDisabled) {
            if (selectedAsset === "PGP") {
                useAppStore.getState().setSelectedAsset(CryptoCurrency.BSV);
            }
            const unsubscribe = useAppStore.subscribe(
                selected => {
                    if (games[gameCode].pgpDisabled && selected === "PGP") {
                        useAppStore.getState().setSelectedAsset(CryptoCurrency.BSV);
                        errorToast(`${games[gameCode].label} does not support PGP payments`);
                    }
                },
                state => state.asset.selected,
            );
            return () => {
                unsubscribe();
                if (!games[gameCode].pgpDisabled) {
                    useAppStore.getState().setSelectedAsset(selectedAsset);
                }
            };
        }
    }, [gameCode, selectedAsset]);

    if (!accessAvailable) {
        return <Restriction />;
    }

    if (isMaintenance) {
        return <Maintenance />;
    }

    const getShouldShowStatsTable = (gameCode: string) => !["bitto", "cfBattle", "slots"].includes(gameCode)

    return (
        <>
            <AreYouAdult />
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                {Object.entries(games).map(([gameCode, { urlName, label, component: Component }]) => (
                    <Route key={gameCode} path={`/games/${urlName || gameCode}`}>
                        <GameCodeContext.Provider value={gameCode}>
                            <PageContainer showFooter showHeader dark pageTitle={label} contentClass={cx("game-page")}>
                                <Suspense
                                    fallback={
                                        <div className={cx("game-loader")}>
                                            <span className={cx("bold", "large")}>Loading game...</span>
                                        </div>
                                    }
                                >
                                    <Component />
                                </Suspense>
                                {getShouldShowStatsTable(gameCode) ? <StatsTable /> : null}
                            </PageContainer>
                        </GameCodeContext.Provider>
                    </Route>
                ))}
                <Route exact path="/games">
                    <PageContainer showFooter showHeader dark pageTitle="Games" contentClass={cx("games-page")}>
                        <GamesList />
                    </PageContainer>
                </Route>
                <Route path="/games/:category">
                    <PageContainer showFooter showHeader dark pageTitle="Games" contentClass={cx("games-page")}>
                        <GamesList />
                    </PageContainer>
                </Route>
            </Switch>
        </>
    );
};

export default Games;