import seenIcon from "./assets/Seen.svg";
import alertIcon from "./assets/Alert.svg";
import { StyledMessageContainer } from "src/affiliate/components/reusable/assets/styles/notify.styled";

interface INotifyProps {
    error?: boolean;
    message: string;
}

const Notify = ({ error = false, message }: INotifyProps) => (
    <StyledMessageContainer error={error}>
        {error ? <img src={alertIcon} alt="icon" /> : <img src={seenIcon} alt="icon" />}
        <p className="small">{message}</p>
    </StyledMessageContainer>
);

export default Notify;
