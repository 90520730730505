import bsvIcon from "./assets/bsv-icon.svg";
import { StyledFlexContainer, StyledP, StyledH3Wrapper } from "src/affiliate/components/reusable/assets/styles/data-card.styled";
import BigNumber from "bignumber.js";

interface IDataCardProps {
    width?: string;
    padding?: string;
    gradientDirection?: boolean;
    name: string;
    value: string | number;
    bsIcon?: boolean;
    campaignBlogCard?: boolean;
}

const DataCard = ({
    width = "",
    padding = "",
    gradientDirection = false,
    bsIcon = false,
    campaignBlogCard = false,
    name,
    value,
}: IDataCardProps) => {
    const splittedValue = new BigNumber(value)
        .toFixed()
        .toString()
        .split(".");

    return (
        <StyledFlexContainer width={width} padding={padding} gradientDirection={gradientDirection}>
            <StyledP campaignBlogCard={campaignBlogCard}>{name}</StyledP>
            <StyledH3Wrapper campaignBlogCard={campaignBlogCard}>
                {splittedValue[0]}
                {splittedValue.length === 1 ? "" : "."}
                <span className="xlarge">{splittedValue[1]}</span>
                {bsIcon ? <img src={bsvIcon} alt="icon" /> : null}
            </StyledH3Wrapper>
        </StyledFlexContainer>
    );
};

export default DataCard;
