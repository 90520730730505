import styled, { css } from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { Caption } from "src/affiliate/styled/typography.styled";

export const StyledFlexContainer = styled(FlexContainer)`
    width: 100%;
    border-radius: 4px;
    background-color: #f8f5ff;
    height: 100%;
`;

export const StyledTitle = styled.p`
    width: 73%;
    color: #12033d;
`;

export const StyledCaption = styled(Caption)<{ collapsed: boolean }>`
    color: #6e658a;
    opacity: ${({ collapsed }) => (collapsed ? "1" : "0")};
    transition: opacity 0.25s ease;
`;

export const StyledCircle = styled(FlexContainer)<{ trigger: boolean }>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: solid 1px #c7baf1;
    background-color: #f8f5ff;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    > img {
        ${({ trigger }) => trigger
    ? css`
                transform: rotateZ(-180deg);
            `
    : css`
                transform: rotateZ(-360deg);
            `};
        transition: all .25s ease;
    }
;
}
`;
export const StyledCaptionContainer = styled(FlexContainer)<{ collapsed: boolean }>`
    display: block;
    overflow-y: hidden;
    max-height: ${({ collapsed }) => (collapsed ? "500px" : "0")};
    padding: ${({ collapsed }) => (collapsed ? "12px 24px 24px 24px" : "0")};
    transition: all 0.25s ease;
`;