import styled, { css } from "styled-components";
import { H1 } from "src/affiliate/styled/typography.styled";
import { IBaseModalProps } from "src/components/base-modal/BaseModal";

interface ICommonStyledComponentsProps extends Pick<IBaseModalProps, "open"> {
}

export const StyledModal = styled.div<ICommonStyledComponentsProps>`
    background: #1f0d53;
    box-shadow: 0 20px 40px rgba(18, 3, 61, 0.1);
    border-radius: 4px;
    position: fixed;
    width: 100%;
    max-width: 500px;
    overflow: auto;
    margin: 0 auto;
    top: 219px;
    left: calc(50% - 250px);
    max-height: calc(100% - 160px);
    padding: 32px;
    @media only screen and (max-width: 568px) {
        left: 0;
        top: 120px;
        max-width: unset;
        max-height: 100%;
        padding: 32px;
        border-radius: 0;
    }

    ${({ open }) => open
    ? css`
            opacity: 1;
            z-index: 10001;
            transform: scale(1);
        `
    : css`
            opacity: 0;
            transform: scale(0);
        `};
    transition: opacity 0.3s ease 0.2s, transform 0.5s;
`;

export const StyledOverlay = styled.div<ICommonStyledComponentsProps>`
    background-color: #04000f;
    cursor: default;
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    ${({ open }) => open
    ? css`
            opacity: 0.8;
        `
    : css`
            opacity: 0;
            z-index: -1000;
        `};
    transition: opacity 0.1s ease 0.1s, transform 0.2s;
`;

export const StyledSvgContainer = styled.div`
    cursor: pointer;
    position: absolute;
    right: 17px;
    top: 17px;
    height: 14px;
    width: 14px;
`;

export const StyledModalTitle = styled(H1)`
    font-size: 28px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    margin: 0;
`;