import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";

const cx = classNames.bind(styles);

const pageDescription = "Peergame is a technology company that reinvents gaming. Since the original Bitcoin was "
    + "reborn in the form of BSV, we finally were able to start building the vision that was set forth many years ago: "
    + "the frictionless commercial internet.";

export default function AboutUs() {
    return (
        <>
            <p>{pageDescription}</p>
            <p>
                We believe that Bitcoin technology will be the backbone of the new internet, and the gaming industry is about to
                be disrupted.
            </p>
            <p>
                Our mission, we call it Casino 2.0, is to provide the entirely new gaming experience that is frictionless, fast,
                and easy. Try our games and join our journey!
            </p>
            <Link className={cx("button")} to="/games">
                Browse Games
            </Link>
        </>
    );
}
