import Helmet from "react-helmet";
import SectionOneMain from "src/affiliate/components/affiliate-landing/section-one/SectionOneMain";
import SectionTwoMain from "src/affiliate/components/affiliate-landing/section-two/SectionTwoMain";
import CommissionPlans from "src/affiliate/components/affiliate-landing/section-three/commission-plans/CommissionPlans";
import Payments from "src/affiliate/components/affiliate-landing/section-three/payments/Payments";
import FrequentlyAq from "src/affiliate/components/affiliate-landing/section-three/frequently-aq/FrequentlyAq";
import Layout from "src/affiliate/components/Layout";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

const AffiliateLanding = () => (
    <>
        <Helmet>
            <title>Peergame Partners - Partner up with the highest-paid commission casino affiliate program.</title>
            <meta
                property="og:title"
                content="Peergame Partners - Partner up with the highest-paid commission casino affiliate program."
            />
            <meta
                name="description"
                content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
            />
        </Helmet>
        <Layout allowedRoutes backGround="linear-gradient(to top, #efedff, #ffeef3, #f6f7ff, #e4e3ff)">
            <FlexContainer flexDirection="column">
                <SectionOneMain />
                <SectionTwoMain />
                <CommissionPlans />
                <Payments />
                <FrequentlyAq />
            </FlexContainer>
        </Layout>
    </>
);

export default AffiliateLanding;
