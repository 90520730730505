import React, { Fragment, useState, useEffect } from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import styles from "./assets/styles.scss";
import Modal from "src/components/modal/Modal";

import ImgWager from "./assets/wager.png";
import ImgRaces from "./assets/races.png";
import ImgPartners from "./assets/partners.png";
import ImgPGP from "./assets/pgp.png";
import IconInfo from "../../Races/assets/images/info.svg?inline";
import { axiosInstance } from "src/core/http/axios-instance";
import useAppStore from "src/useAppStore";
import GridCard from "src/containers/Account/MyAccount/GridCard";
import Avatar from "src/containers/Account/MyAccount/Avatar";
import ChangeUsername from "src/containers/Account/MyAccount/ChangeUsername";
import { useAppSelector } from "src/redux/reducer";
import { useMyPoints } from "src/components/header/hooks/useMyPoints";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { formatCrypto } from "src/core/currency/currency.function";

const cx = classNames.bind(styles);

interface IAuthState {
    loaded: boolean;
    user: {
        nickname: string;
        profile_image: string;
    };
}

interface IWalletState {
    currentWallet: {
        paymail: string;
    };
}

interface IAppState {
    auth: IAuthState;
    wallets: IWalletState;
    info: {
        settings: {
            pgp: {
                distributeByWagerRate: string;
            };
        };
    };
}

export default function MyAccount() {
    const authLoaded = useAppSelector(state => state.auth.loaded);
    const { nickname, profile_image: profilePic } = useAppSelector(state => state.auth.user) || {};
    const { paymail } = useAppSelector(state => state.wallets.currentWallet) || {};

    const { myPoints, isLoading: isLoadingPoints } = useMyPoints();
    const { balance, isLoading: isLoadingBalance } = useAppStore(state => state.asset);
    const [isPgpOpen, setIsPgpOpen] = useState(false);
    const pgpRate = useAppSelector(state => state.info.settings?.pgp.distributeByWagerRate);

    return (
        <Fragment>
            <h3 className={cx("heading")}>My Account</h3>
            <div className={cx("my-account")}>
                <GridCard className={cx("profile")} isLoading={!authLoaded}>
                    <Avatar src={profilePic} />
                    <div className={cx("username")}>
                        <span>{nickname}</span>
                        {nickname &&
                            <ChangeUsername username={nickname} />
                        }
                    </div>
                    <div className={cx("paymail")}>
                        <span className={cx("small")}>Paymail Address</span>
                        <span className={cx("xlarge", "bold")}>{paymail}</span>
                    </div>
                </GridCard>
                <div className={cx("wager-pgp-container")}>
                    <GridCard className={cx("wager")} isLoading={isLoadingPoints}>
                        <img src={ImgWager} alt="wager" />
                        <div className={cx("amount")}>
                            <span className={cx("small")}>Total Wager</span>
                            <div className={cx("text-icon")}>
                                <span>{myPoints?.total_wager?.toFixed(4) || "0.0000"}</span>
                                <CurrencyIcon currency={CryptoCurrency.BSV} />
                            </div>
                        </div>
                    </GridCard>
                    <GridCard className={cx("peergame-points")} isLoading={isLoadingBalance}>
                        <div>
                            <img src={ImgPGP} alt="peergame points" />
                            <div className={cx("amount")}>
                                <span className={cx("small")}>Total PGP</span>
                                <div className={cx("text-icon")}>
                                    <span>{formatCrypto(balance.PGP) || formatCrypto(0)}</span>
                                    <CurrencyIcon currency={CryptoCurrency.PGP} />
                                </div>
                            </div>
                        </div>
                        <button className={cx("small", "pgp-details")} type="button" onClick={() => setIsPgpOpen(true)}>
                            <IconInfo />
                            What is PGP?
                        </button>
                        <Modal
                            header="Peergame Points"
                            isOpen={isPgpOpen}
                            onClose={() => setIsPgpOpen(false)}
                            dark
                            backgroundColor="#1F0D53"
                            className={cx("pgp-modal")}
                        >
                            <p className={cx("bold")}>1 PGP = 1 BSV</p>
                            <p>
                                You can use PGP to bet on any games and you will receive winning payouts in BSV. To earn PGP, simply
                                make a bet. Even if you bet with PGP, you will earn PGP! Every time you bet you get{" "}
                                {new BigNumber(pgpRate).multipliedBy(100).toFixed()}% of your bet as PGP.
                            </p>
                            <p>
                                You can use PGPs same like BSV, if you bet with PGP and win, you will get your rewards as BSV directly
                                to your wallet.
                            </p>
                        </Modal>
                    </GridCard>
                </div>
                <GridCard className={cx("races")} isLoading={isLoadingPoints}>
                    <img src={ImgRaces} alt="races" />
                    <div className={cx("description")}>
                        <span>Peergame Races</span>
                        <span>Get your rewards with Daily and Weekly races.</span>
                    </div>
                    <div className={cx("my-stats")}>
                        <div>
                            <span className={cx("small")}>Your Rank</span>
                            <span className={cx("small")}>{myPoints?.daily_rank?.ranking || "-"}</span>
                        </div>
                        <div>
                            <span className={cx("small")}>Your Points</span>
                            <span className={cx("small")}>{myPoints?.daily_rank ? myPoints?.daily_rank.point : "-"}</span>
                        </div>
                    </div>
                </GridCard>
                <GridCard className={cx("partners")}>
                    <img src={ImgPartners} alt="partners" />
                    <div className={cx("description")}>
                        <span>Peergame Partners</span>
                        <span>Invite your friends and start winning together.</span>
                    </div>
                    <div className={cx("button-container")}>
                        <Link to="/partners">Invite Your Friends</Link>
                    </div>
                </GridCard>
            </div>
        </Fragment>
    );
}
