import {games} from "../../../games/config";
import {wheelSections} from "../../../games/Wheel/config";

export default function useFinalResult(gameCode, resultNumber) {
    switch (gameCode) {
        case "roulette":
            return resultNumber;
        case "dice":
            return resultNumber;
        case "coinflip":
            return resultNumber === "0" ? "Heads" : "Tails";
        case "cfBattle":
            return resultNumber === "0" ? "Heads" : "Tails";
        case "wheel":
            if (resultNumber) return games.wheel.formatResult(wheelSections[resultNumber]);
            break;
        case "turtle":
            if (resultNumber === "0") {
                return "Yellow, Red, Blue";
            }
            if (resultNumber === "1") {
                return "Yellow, Blue, Red";
            }
            if (resultNumber === "2") {
                return "Red, Yellow, Blue";
            }
            if (resultNumber === "3") {
                return "Red, Blue, Yellow";
            }
            if (resultNumber === "4") {
                return "Blue, Yellow, Red";
            }
            if (resultNumber === "5") {
                return "Blue, Red, Yellow";
            }
            break;
        case "ladder":
            if (resultNumber === "0" || resultNumber === "3") {
                return "Left";
            }
            if (resultNumber === "1" || resultNumber === "2") {
                return "Right";
            }
            break;
        default:
            return "";
    }
}
