import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { ReactNode } from "react";
import { StyledTitle } from "src/affiliate/styled/typography.styled";

interface IDashBoardLayoutProps {
    title: string;
    component?: ReactNode;
    children: ReactNode;
    mobilePadding?: string;
}

const DashBoardLayout = ({
    title,
    children,
    component,
    mobilePadding = "",
}: IDashBoardLayoutProps) => (
    <FlexContainer
        flexDirection="column"
        padding="42px 0 178px 38px"
        tabletPadding="58px 24px 333px 24px"
        mobilePadding={mobilePadding || "22px 16px 112px 16px"}
        width="800px"
        mobileWidth="100%"
        tabletWidth="100%"
    >
        {component ? (
            <FlexContainer alignItems="center" justifyContent="space-between" mobileFlexDirection="column">
                <FlexContainer mobileWidth="100%" mobileJustifyContent="center" mobileMargin="0 0 28px 0">
                    <StyledTitle tabletMargin="0 0 0 40px" mobileFontSize="20px">
                        {title}
                    </StyledTitle>
                </FlexContainer>
                {component}
            </FlexContainer>
        ) : (
            <FlexContainer mobileWidth="100%" mobileJustifyContent="center" mobileMargin="0 0 28px 0">
                <StyledTitle
                    tabletMargin="0 0 0 40px"
                    mobileMargin="0"
                    mobileFontSize="20px"
                >
                    {title}
                </StyledTitle>
            </FlexContainer>
        )}
        {children}
    </FlexContainer>
);

export default DashBoardLayout;
