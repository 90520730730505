import styled from "styled-components";
import { H3 } from "src/affiliate/styled/typography.styled";

export const StyledFrom = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 480px;
    @media only screen and (max-width: 568px) {
        width: 100%;
        padding: 0 24px;
    }
`;

export const StyledH3 = styled(H3)`
    color: ${({ theme }) => theme.color.white};
    padding-bottom: 40px;
    @media only screen and (max-width: 568px) {
        width: 312px;
        font-size: 20px;
        padding-bottom: 32px;
    }
`;

export const StyledAnchor = styled.a`
    color: #ff2976;
`;