import {useMediaQuery} from "react-responsive";

export const smallMinWidth = 480;
export const tabletMinWidth = 768;
export const desktopMinWidth = 992;
export const desktopLargeMinWidth = 1200;
export const desktopXLargeMinWidth = 1680;

export const useXSmallOnly = () => useMediaQuery({maxWidth: smallMinWidth - 1});
export const useMobileOnly = () => useMediaQuery({maxWidth: tabletMinWidth - 1});
export const useMobileUp = () => useMediaQuery({minWidth: smallMinWidth});
export const useDesktopXLargeOnly = () => useMediaQuery({minWidth: desktopXLargeMinWidth});
export const useDesktopLargeOnly = () => useMediaQuery({minWidth: desktopLargeMinWidth});
export const useDesktopOnly = () => useMediaQuery({minWidth: desktopMinWidth});
export const useTabletOnly = () => useMediaQuery({minWidth: tabletMinWidth, maxWidth: desktopMinWidth - 1});
export const useTabletUp = () => useMediaQuery({minWidth: tabletMinWidth});
