import { axiosInstance } from "src/core/http/axios-instance";

const GET_ACCESS_AVAILABLE = "game/info/GET_ACCESS_AVAILABLE";
const GET_ACCESS_AVAILABLE_SUCCESS = "game/info/GET_ACCESS_AVAILABLE_SUCCESS";
const GET_ACCESS_AVAILABLE_FAIL = "game/info/GET_ACCESS_AVAILABLE_FAIL";
const I_AM_ADULT = "game/info/I_AM_ADULT";
const MAINTENANCE = "game/info/MAINTENANCE";

const defaultMaintenance: Maintenance = {
    code: "0",
    site: [
        {
            name: "all",
            is_maintenance: "N",
        },
    ],
    wallet: [
        {
            name: "moneybutton",
            is_maintenance: "N",
        },
        {
            name: "handcash",
            is_maintenance: "N",
        },
    ],
    game: [
        {
            name: "bitto",
            is_maintenance: "N",
        },
        {
            name: "ladder",
            is_maintenance: "N",
        },
        {
            name: "turtle",
            is_maintenance: "N",
        },
        {
            name: "baccarat",
            is_maintenance: "N",
        },
        {
            name: "dice",
            is_maintenance: "N",
        },
        {
            name: "coinflip",
            is_maintenance: "N",
        },
        {
            name: "wheel",
            is_maintenance: "N",
        },
        {
            name: "roulette",
            is_maintenance: "N",
        },
    ],
};

interface MaintenanceItem {
    name: string;
    is_maintenance: string;
}

interface Maintenance {
    code: string;
    site: MaintenanceItem[];
    wallet: MaintenanceItem[];
    game: MaintenanceItem[];
}

export interface IInfoState {
    iAmAdult: boolean;
    maintenance: Maintenance;
    accessAvailable: boolean;
    settings: any;
}

interface IAmAdultAction {
    type: typeof I_AM_ADULT;
}

interface SetMaintenanceAction {
    type: typeof MAINTENANCE;
    maintenance: string;
}

interface GetAccessAvailableSuccessAction {
    type: typeof GET_ACCESS_AVAILABLE_SUCCESS;
    result: {
        code: string;
        data: {
            maintenance: Maintenance;
            setting: any; // Adjust the type as necessary
        };
    };
}

interface GetAccessAvailableFailAction {
    type: typeof GET_ACCESS_AVAILABLE_FAIL;
    error?: {
        status: number;
    };
}

type InfoActionTypes =
    | IAmAdultAction
    | SetMaintenanceAction
    | GetAccessAvailableSuccessAction
    | GetAccessAvailableFailAction;

const initialState: IInfoState = {
    iAmAdult: false,
    maintenance: defaultMaintenance,
    accessAvailable: true,
    settings: null,
};

export default function info(state = initialState, action: InfoActionTypes): IInfoState {
    switch (action.type) {
        case I_AM_ADULT:
            return {
                ...state,
                iAmAdult: true,
            };
        case MAINTENANCE: {
            const maintenance = defaultMaintenance;
            action.maintenance.split("|").forEach(service => {
                maintenance[service] = true;
            });
            const res = JSON.parse(action.maintenance);
            return {
                ...state,
                maintenance: {
                    code: state.maintenance.code,
                    site: res?.site,
                    wallet: res?.wallet,
                    game: res?.game,
                },
            };
        }
        case GET_ACCESS_AVAILABLE_SUCCESS:
            return {
                ...state,
                maintenance: {
                    code: action.result.code,
                    site: action.result.data.maintenance.site,
                    wallet: action.result.data.maintenance.wallet,
                    game: action.result.data.maintenance.game,
                },
                settings: action.result.data.setting,
            };
        case GET_ACCESS_AVAILABLE_FAIL:
            return {
                ...state,
                ...(action?.error?.status === 403 && { accessAvailable: false }),
            };

        default:
            return state;
    }
}

export function iAmAdult(): IAmAdultAction {
    return {
        type: I_AM_ADULT,
    };
}

export function setMaintenance(maintenance: string): SetMaintenanceAction {
    return {
        type: MAINTENANCE,
        maintenance,
    };
}

export function getAccessAvailable() {
    return {
        types: [GET_ACCESS_AVAILABLE, GET_ACCESS_AVAILABLE_SUCCESS, GET_ACCESS_AVAILABLE_FAIL],
        promise: () => axiosInstance.get("/v1/ping"),
    };
}
