import { StyledIconContainer, StyledP } from "src/affiliate/components/login/assets/styles/card.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

interface ICardProps {
    image: string
    title: string
    description: string
    alt: string
}

const Card = ({
    image, title, description, alt,
}: ICardProps) => (
    <FlexContainer alignItems="center" padding="0 0 24px 0">
        <StyledIconContainer>
            <img src={image} alt={alt} />
        </StyledIconContainer>
        <FlexContainer margin="0 0 0 16px" flexDirection="column">
            <StyledP className="bold">{title}</StyledP>
            <StyledP>{description}</StyledP>
        </FlexContainer>
    </FlexContainer>
);

export default Card;
