import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

const StyledMessage = styled.p`
    color: #f0ecff;
`;

const EmptyState = () => (
    <FlexContainer justifyContent="center" alignItems="center" width="100%" margin="60px 0 0 0">
        <StyledMessage className="small">You don’t have any history</StyledMessage>
    </FlexContainer>
);

export default EmptyState;
