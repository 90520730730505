import styled from "styled-components";

interface ISimpleCommonProps {
    margin?: string;
}

interface IResponsiveCommonProps extends ISimpleCommonProps {
    tabletMargin?: string;
    mobileMargin?: string;
}

interface IParagraphProps {
    width?: string;
    padding?: string;
    margin?: string;
    whiteSpace?: string;
    mobileWidth?: string;
    mobileMargin?: string;
}

export const H1 = styled.h1<ISimpleCommonProps>`
    margin: ${({ margin }) => margin};
    color: ${({ theme }) => theme.color.white};
`;

export const H2 = styled.h2<ISimpleCommonProps>`
    color: ${({ theme, color }) => color || theme.color.purple};
    margin: ${({ margin }) => margin};
`;
export const H3 = styled.h3<IResponsiveCommonProps>`
    color: ${({ theme }) => theme.color.white};
    margin: ${({ margin }) => margin};
    @media only screen and (max-width: 1157px) {
        margin: ${({ tabletMargin }) => tabletMargin};
    }
    @media only screen and (max-width: 568px) {
        margin: ${({ mobileMargin }) => mobileMargin};
    }
`;
export const H4 = styled.h4<IResponsiveCommonProps>`
    color: ${({ theme }) => theme.color.white};
    margin: ${({ margin }) => margin};
    @media only screen and (max-width: 1157px) {
        margin: ${({ tabletMargin }) => tabletMargin};
    }
    @media only screen and (max-width: 568px) {
        margin: ${({ mobileMargin }) => mobileMargin};
    }
`;
export const H6 = styled.h6<IResponsiveCommonProps>`
    color: ${({ theme }) => theme.color.white};
    margin: ${({ margin }) => margin};
    @media only screen and (max-width: 1157px) {
        margin: ${({ tabletMargin }) => tabletMargin};
    }
    @media only screen and (max-width: 568px) {
        margin: ${({ mobileMargin }) => mobileMargin};
    }
`;
export const Caption = styled.p<IParagraphProps>`
    width: ${({ width }) => width};
    padding: ${({ padding }) => padding};
    color: ${({ theme, color }) => color || theme.color.white};
    margin: ${({ margin }) => margin};
    white-space: ${({ whiteSpace }) => whiteSpace};
    @media only screen and (max-width: 568px) {
        width: ${({ mobileWidth }) => mobileWidth};
        margin: ${({ mobileMargin }) => mobileMargin};
    }
`;

export const StyledTitle = styled(H3)<{ mobileFontSize?: string }>`
    color: ${({ theme }) => theme.color.white};
    white-space: nowrap;
    @media only screen and (max-width: 568px) {
        font-size: ${({ mobileFontSize }) => mobileFontSize};
    }
`;
