import { useState, useRef } from "react";
import classNames from "classnames/bind";
import { Link, useLocation } from "react-router-dom";
import styles from "./assets/styles.scss";
import IconReferrals from "./assets/images/referrals.svg?inline";
import IconLogout from "./assets/images/logout.svg?inline";
import ImgTrophy from "./assets/images/races-trophy.png";
import { basePath, routes } from "src/containers/Account/accountRoutes";
import useClickOutside from "src/helpers/useClickOutside";
import usePageScroll from "src/helpers/usePageScroll";
import useLocationChange from "src/helpers/useLocationChange";
import { useAppSelector } from "src/redux/reducer";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { walletProviders } from "src/core/wallet/wallet.const";
import { useLogout } from "src/core/auth/hooks/useLogout";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { useMyPoints } from "src/components/header/hooks/useMyPoints";


interface IMyAccountProps {
    reverse?: boolean;
}

const cx = classNames.bind(styles);

export default function MyAccountMenu({ reverse = false }: IMyAccountProps) {
    const { pathname } = useLocation();
    const popupRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const walletName = useAppSelector(state => state.wallets.currentWallet?.name);

    const { nickname, profile_image: profilePic } = useAppSelector(state => state.auth.user) || {};
    const [isOpen, setIsOpen] = useState(false);

    const [walletProvider] = useWalletProvider();
    const { myPoints } = useMyPoints({ enabled: walletProvider === WalletProvider.HandCash });

    useClickOutside(() => {
        setIsOpen(false);
    }, [popupRef, buttonRef]);
    usePageScroll(() => setIsOpen(false));
    useLocationChange(() => setIsOpen(false));

    const logout = useLogout();

    return (
        <>
            <button
                type="button"
                ref={buttonRef}
                className={cx("account-button", { active: isOpen })}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{nickname ?? "ABC_random"}</span>
                <div className={cx("avatar-container")}>
                    <img
                        className={cx("avatar")}
                        src={walletProvider === WalletProvider.WalletConnect ?
                            walletProviders[WalletProvider.WalletConnect].iconRound
                            :
                            profilePic
                        }
                        alt="avatar"
                    />
                    <div className={cx("wallet-icon")}>
                        <img src={walletProviders?.[walletProvider ?? walletName ?? WalletProvider.HandCash]?.iconRound} alt="" />
                    </div>
                </div>
            </button>
            <div className={cx("account-popup", { open: isOpen, reverse })} ref={popupRef}>
                <div className={cx("ranking", { reverse })}>
                    <span className={cx("heading", "bold", "small")}>
                        <img src={ImgTrophy} alt="trophy" />
                        My Daily Race
                    </span>
                    <div>
                        <div>
                            <span className={cx("small")}>Rank</span>
                            <span className={cx("small")}>{myPoints?.daily_rank?.ranking || "-"}</span>
                        </div>
                        <div>
                            <span className={cx("small")}>Points</span>
                            <span className={cx("small")}>{myPoints?.daily_rank ? myPoints?.daily_rank.point : "-"}</span>
                        </div>
                    </div>
                </div>
                <div className={cx("navigation")}>
                    {routes.map(({ path, label, icon }) => (
                        <Link
                            key={label}
                            className={cx({ active: pathname === `${basePath}${path}`, reverse })}
                            to={`${basePath}${path}`}
                        >
                            {icon}
                            {label}
                        </Link>
                    ))}
                    <Link to="/partners" className={cx({ reverse })}>
                        <IconReferrals />
                        Referrals
                    </Link>
                    <button type="button" className={cx({ reverse })} onClick={logout}>
                        <IconLogout />
                        Sign out
                    </button>
                </div>
            </div>
        </>
    );
}