import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon } from "src/affiliate/components/reusable/assets/styles/check-box.styled";
import { ReactNode } from "react";

interface ICheckBoxProps {
    checked: boolean;
    onChange: () => void;
    name: string;
    children: ReactNode;
    padding?: string;
}

const CheckBox = ({
    checked,
    onChange,
    padding = "",
    name,
    children,
}: ICheckBoxProps) => (
    <>
        <FlexContainer cursor="pointer" onClick={() => onChange()}>
            <CheckboxContainer padding={padding}>
                <HiddenCheckbox name={name} defaultChecked={checked} />
                <StyledCheckbox checked={checked}>
                    <Icon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </Icon>
                </StyledCheckbox>
            </CheckboxContainer>
            {children}
        </FlexContainer>
    </>
);

export default CheckBox;
