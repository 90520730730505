import { StyledFlexContainer } from "src/affiliate/components/login/assets/styles/login-mobile-list.styled";
import Card from "src/affiliate/components/login/Card";
import { MOBILE_LIST } from "src/affiliate/components/login/assets/constants/login-mobile-list";

const LoginMobileList = () => (
    <StyledFlexContainer>
        {MOBILE_LIST.map(({
            id, image, title, description, alt,
        }) => (
            <Card key={id} image={image} title={title} description={description} alt={alt} />
        ))}
    </StyledFlexContainer>
);

export default LoginMobileList;
