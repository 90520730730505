import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import { ReactNode } from "react";

const cx = classNames.bind(styles);

interface GridCardProps {
    children: ReactNode;
    className: string;
    isLoading?: boolean;
}

const GridCard = ({ children, className, isLoading }: GridCardProps) => {
    return (
        <div className={`${cx("grid-card", { skeleton: isLoading })} ${className}`}>
            {isLoading ? null : children}
        </div>
    );
};

export default GridCard;
