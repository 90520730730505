import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledFlexContainer = styled(FlexContainer)`
    width: 100%;
    border-radius: 4px;
    background-color: #1a0949;
`;

export const StyledTitle = styled.h5`
    text-align: unset;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.color.white};
`;

export const StyledToggleLabel = styled.label`
    color: #f0ecff;
    margin: 0 8px 0 0;
`;