import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";

interface IUseMyPointsInput {
    enabled?: boolean
}

interface IRank {
    ranking: number;
    point: number
}

interface IPointsResponse {
    timestamp: number;
    data: {
        daily_rank: IRank | null;
        total_wager: number;
        weekly_rank: IRank | null;
    }
}

async function getMyPoints() {
    const { data } = await axiosInstance.get<IPointsResponse>("v1/user/points");

    return data
}

export function useMyPoints({ enabled = true }: IUseMyPointsInput = {}) {
    const { data: myPoints, isLoading } = useQuery({ queryKey: ["mypoints"], queryFn: getMyPoints, enabled });

    return { myPoints, isLoading };
}