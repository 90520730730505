export const COOKIE = {
    _ARE_YOU_ADULT: "are-you-adult",
    _LAST_LOCATION: "last-location",
    _MONEY_BUTTON_USER_ID_HASH: "money-button-user-id-hash",
};

export const GAME = {
    _BITTO: "bitto",
    _TURTLE_RACE: "turtle",
    _LADDER_GAME: "ladder",
    _BACCARAT: "baccarat",
    _ROULETTE: "roulette",
    _DICE: "dice",
    _COIN_FLIP: "coinflip",
    _WHEEL: "wheel",
    _COINFLIP_DUEL: "cfBattle",
};

export const URL = {
    [GAME._BITTO]: "/games/bitto/",
    [GAME._TURTLE_RACE]: "/games/turtlerace/",
    [GAME._LADDER_GAME]: "/games/laddergame/",
    [GAME._BACCARAT]: "/games/baccarat/",
    [GAME._DICE]: "/games/dice/",
    [GAME._COIN_FLIP]: "/games/coinflip/",
    [GAME._WHEEL]: "/games/wheel/",
    [GAME._ROULETTE]: "/games/roulette/",
    [GAME._COINFLIP_DUEL]: "/games/coinbattles/",
};
export const ALL_URL = Object.values(URL);

export const ALL = "all";

export const TURTLE = {
    _RED: "R",
    _YELLOW: "Y",
    _BLUE: "B",
};

export const LADDER = {
    _LEFT: "L",
    _RIGHT: "R",
};

export const DICE = {
    _MIN_NUMBER: 1,
    _MAX_NUMBER: 10000,
    _DEFAULT_NUMBER: 5049,
    _DEFAULT_SIDE: "over",
};

export const BACCARAT_BETTING_AREA = {
    _BANKER: "B",
    _TIE: "T",
    _PLAYER: "P",
    _BANKER_PAIR: "BP",
    _PLAYER_PAIR: "PP",
};

