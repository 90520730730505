import React from "react";
import classNames from "classnames/bind";
import _isArray from "lodash/isArray";
import PropTypes from "prop-types";
import styles from "./assets/styles.scss";
import ResultTableImage from "./assets/result-table.png";
import ladder0Image from "./assets/ladder-0.png";
import ladder1Image from "./assets/ladder-1.png";
import ladder2Image from "./assets/ladder-2.png";
import ladder3Image from "./assets/ladder-3.png";
import { wheelSections } from "../../../games/Wheel/config";
import PlayingCard from "games/components/PlayingCard/PlayingCard";

const cx = classNames.bind(styles);

/* eslint no-case-declarations: 0 */

const ResultTable = ({ game, result }) => {
    switch (game) {
        case "coinflip":
            return (
                <div className="text-left">
                    <h6 className={cx("result-table")}>Result Table</h6>
                    <div>
                        <span className="small">Heads = 0</span>
                    </div>
                    <div>
                        <span className="small">Tails = 1</span>
                    </div>
                </div>
            );
        case "cfBattle":
            return (
                <div className="text-left">
                    <h6 className={cx("result-table")}>Result Table</h6>
                    <div>
                        <span className="small">Heads = 0</span>
                    </div>
                    <div>
                        <span className="small">Tails = 1</span>
                    </div>
                </div>
            );
        case "wheel":
            return (
                <div className="text-left">
                    <h6 className={cx("result-table")}>Result Table</h6>
                    <div>
                        <span className="small">Yellow = 0</span>
                    </div>
                    <div>
            <span className="small">
              Green = {wheelSections.map((section, index) => (section === "G" ? `${index}, ` : null))}
            </span>
                    </div>
                    <div>
            <span className="small">
              Red = {wheelSections.map((section, index) => (section === "R" ? `${index}, ` : null))}
            </span>
                    </div>
                    <div>
            <span className="small">
              Purple = {wheelSections.map((section, index) => (section === "P" ? `${index}, ` : null))}
            </span>
                    </div>
                    <div>
            <span className="small">
              Bonus = {wheelSections.map((section, index) => (section === "B" ? `${index}, ` : null))}
            </span>
                    </div>
                </div>
            );
        case "turtle":
            return (
                <div className="text-left">
                    <h6 className={cx("result-table")}>Result Table</h6>
                    <img src={ResultTableImage} alt="" width="220" />
                </div>
            );
        case "ladder":
            return (
                <div className="text-left">
                    <h6 className={cx("result-table")}>Result Table</h6>
                    <div className={cx("ladder-images")}>
                        <img src={ladder0Image} alt="" />
                        <img src={ladder1Image} alt="" />
                        <img src={ladder2Image} alt="" />
                        <img src={ladder3Image} alt="" />
                    </div>
                </div>
            );
        case "baccarat":
            if (!_isArray(result) || !result[0]) {
                break;
            }
            const resCards = JSON.parse(result[0]);
            const generatedCards = [];

            let index = 0;
            while (generatedCards.length < (resCards.player.length + resCards.banker.length) / 2) {
                // push player card
                const playerCard = resCards.player.substring(index, index + 2);
                if (playerCard) {
                    generatedCards.push({ card: playerCard, type: "Player" });
                }
                // push banker card
                const bankerCard = resCards.banker.substring(index, index + 2);
                if (bankerCard) {
                    generatedCards.push({ card: bankerCard, type: "Banker" });
                }

                index += 2;
            }

            let hrStyles = null;
            let text = "";
            if (result[3][0] === "P") {
                const filteredCards = generatedCards.filter(({ type }) => type === "Player");
                hrStyles = {
                    width: `${filteredCards.length * 47}px`,
                    marginLeft: "initial",
                };
                text = "Player Wins";
            } else if (result[3][0] === "B") {
                const filteredCards = generatedCards.filter(({ type }) => type === "Banker");
                hrStyles = { width: `${filteredCards.length * 47}px` };
                text = "Banker Wins";
            } else if (result[3][0] === "T") {
                hrStyles = { width: "100%" };
                text = "Tie Wins";
            }

            return (
                <div className="text-left">
                    <div>
                        <div className={cx("generated-cards-wrapper")}>
                            <h6 className={cx("result-table")}>Generated Card Order</h6>
                            <div className={cx("cards")}>
                                {generatedCards.map(({ card, type }, i) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div key={i} className={cx("card-wrapper")}>
                                        <PlayingCard card={card} width={46} />
                                        <span className={cx("xsmall")}>{type}</span>
                                    </div>
                                ))}
                            </div>
                            <div className={cx("hr-text", { noDisplay: generatedCards.length < 5 })}>
                                <hr />
                                <span className="xsmall">or</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx("mt-16")}>
                        <div className={cx("generated-cards-wrapper")}>
                            <h6 className={cx("result-table")}>Game Result</h6>
                            <div className={cx("cards")}>
                                {["Player", "Banker"].map(item => generatedCards
                                    .filter(({ type }) => type === item)
                                    .map(({ card, type }, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={i} className={cx("card-wrapper")}>
                                            <PlayingCard card={card} width={46} />
                                            <span className={cx("xsmall")}>{type}</span>
                                        </div>
                                    )))}
                            </div>
                            <div style={hrStyles} className={cx("hr-text")}>
                                <hr />
                                <span className="small">{text}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

ResultTable.propTypes = {
    game: PropTypes.string.isRequired,
    result: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

ResultTable.defaultProps = {
    result: "",
};

export default ResultTable;
