import { useRef } from "react";
import { useAuthModalState } from "src/feature/auth/dialogs/AuthModal";
import IconWallet from "src/feature/auth/assets/images/wallet-with-plus.svg?inline";
import cn from "classnames";

export default function AuthButton({ className, onClick }: { onClick?(): void, className?: string }) {
    const [_, setModalOpen] = useAuthModalState()
    const connectWalletRef = useRef<HTMLButtonElement | null>(null);

    return (
        <button
            className={cn(
                "flex items-center text-brand-primary bg-brand-primary-100 px-2 py-1 rounded-[6px] transition-colors hover:bg-brand-primary-200 md:pr-4 md:pl-3 md:min-h-10 md:rounded-[10px]",
                className,
            )}
            type="button"
            onClick={() => {
                setModalOpen(true);
                onClick?.();
            }}
            ref={connectWalletRef}
        >
            <IconWallet className="w-8 md:mr-1" />
            <span className="hide-mobile small font-bold whitespace-nowrap">Play Now</span>
        </button>
    );
}

