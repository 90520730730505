import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import ImgPromotions from "./assets/promotons-banner-image.png";

interface IPromotionsBannerProps {
    isMobileOnly: boolean;
}

const cx = classNames.bind(styles);

function getFairnessCalculatorTempContent(isMobileOnly: boolean) {
    if (isMobileOnly) {
        return (
            <>
                <div className={cx("text-section")}>
                    <span className={cx("xlarge")}>Verify Your Spins</span>
                </div>
                <a
                    className={cx("bold", "small")}
                    href="https://peergame.com/fairness-calculation"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Try the Calculator
                </a>
                <img src={ImgPromotions} alt="Promotions" />
            </>
        );
    }
    return (
        <>
            <span className={cx("xlarge")}>Verify Your Spins</span>
            <h3>Use Provably Fair</h3>
            <a
                className={cx("bold")}
                href="https://peergame.com/fairness-calculation"
                target="_blank"
                rel="noreferrer noopener"
            >
                Try the Calculator
            </a>
        </>
    );
}

// eslint-disable-next-line no-unused-vars
function getPromotionsBannerContent(isMobileOnly) {
    if (isMobileOnly) {
        return (
            <>
                <div className={cx("text-section")}>
                    <span className={cx("xlarge")}>Check out</span>
                    <span className={cx("xlarge", "bold")}>Promotions</span>
                </div>
                <a className={cx("bold")} href="https://promotions.peergame.com/" target="_blank" rel="noreferrer noopener">
                    See More
                </a>
                <img src={ImgPromotions} alt="Promotions" />
            </>
        );
    }
    return (
        <>
            <span className={cx("xlarge")}>Check out</span>
            <h3>Promotions</h3>
            <a className={cx("bold")} href="https://promotions.peergame.com/" target="_blank" rel="noreferrer noopener">
                See more
            </a>
        </>
    );
}

export default function PromotionsBanner({ isMobileOnly }: IPromotionsBannerProps) {
    return <div className={cx("promotions-banner")}>{getFairnessCalculatorTempContent(isMobileOnly)}</div>;
}
