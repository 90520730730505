import { useWeb3Modal } from "@web3modal/wagmi/react"

export const useHandleWalletConnectModal = (onModalClose?: () => void) => {
    const { open, close } = useWeb3Modal()

    const openWalletConnectModal = async () => {
        await open({ view: "Connect" })
        onModalClose?.()
    }

    const closeWalletConnectModal = async () => {
        await close()
    }

    return {
        openWalletConnectModal,
        closeWalletConnectModal,
    }
}