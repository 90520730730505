import {
    StyledH3,
    StyledP,
    StyledSpan,
    StyledSource,
} from "src/affiliate/components/email-verification/assets/styles/email-verification-and-expiration.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { axiosInstance } from "src/core/http/axios-instance";
import toastNotify from "src/affiliate/utils/toastNotify";
import { useAppSelector } from "src/redux/reducer";

interface IEmailVerificationAndExpirationProps {
    title: string;
    span?: string;
    description: string;
}

const DELAY_IN_MS = 2000

const EmailVerificationAndExpiration = ({ title, span = "", description }: IEmailVerificationAndExpirationProps) => {
    const email = useAppSelector(state => state.affiliate.email);

    const handleOnClickResendEmail = () => {
        axiosInstance
            .post("/affiliate/auth/resend", { referrer_email: email })
            .then(() => toastNotify("Email sent successfully", DELAY_IN_MS))
            .catch(e => console.log(e));
    };
    return (
        <>
            <FlexContainer padding="70px 0 366px 0" justifyContent="center" mobilePadding="40px 0 539px 0">
                <FlexContainer flexDirection="column" alignItems="center">
                    <StyledH3>{title}</StyledH3>
                    <StyledP>
                        {span && <StyledSpan>{span}</StyledSpan>}
                        {description}
                    </StyledP>
                    <StyledSource className="small" onClick={() => handleOnClickResendEmail()}>
                        Resend Email
                    </StyledSource>
                </FlexContainer>
            </FlexContainer>
        </>
    );
};
export default EmailVerificationAndExpiration;
