import { useQuery } from "react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { games } from "../config";

async function getSoftswissGames(category, isRecommended) {
    const params = { category };
    if (isRecommended) {
        params.situation = "recommend";
    }
    return (await axiosInstance.get("/v1/softswiss/gamelist", { params })).data.map(game => ({
        gameId: game.game_id,
        identifier: game.identifier.toLowerCase(),
        title: game.title,
        thumbnailSrc: `https://cdn.softswiss.net/i/s3/${game.provider}/${game.identifier}.png`,
        url: `/games/${game.category}/${game.identifier.toLowerCase()}`,
        provider: "BGaming",
        category: game.category,
    }));
}

const ensureThumbnailSrcAsString = thumbnail => {
    if (!!thumbnail && typeof thumbnail === "object") {
        return thumbnail.src ?? thumbnail;
    }

    return thumbnail;
};

export default function useGamesList(category, isRecommended) {
    const { data: softswissGames = [], isLoading } = useQuery(
        [category, "gamelist", isRecommended],
        () => getSoftswissGames(category, isRecommended),
        {
            enabled: ["slots", "table", "casual"].includes(category) || !category,
        },
    );

    let gamesList = [];

    if (!category || category === "inhouse") {
        const inhouseGames = Object.entries(games)
            .filter(([, { thumbnail }]) => !!thumbnail)
            .map(([gameId, { thumbnail, label, urlName }]) => ({
                title: label,
                thumbnailSrc: ensureThumbnailSrcAsString(thumbnail),
                url: `/games/${urlName || gameId}`,
                provider: "Peergame",
                category: "inhouse",
            }));
        gamesList = gamesList.concat(inhouseGames);
    }
    if (!category || ["slots", "table", "casual"].includes(category)) {
        gamesList = gamesList.concat(softswissGames);
    }
    return { games: gamesList, isLoading };
}
