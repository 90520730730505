import { useState } from "react";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import { games } from "src/games/config";
import BetDetails from "src/containers/Account/History/BetDetails";
import { CryptoCurrency } from "src/core/currency/currency.model";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";

const cx = classNames.bind(styles);

const thirdPartyGames = ["slots", "table", "casual"];

interface IHistoryTableRowProps {
    gameCode: string;
    gameId: number;
    payoutRate?: number | null;
    area: string;
    result: string;
    txId: string;
    betAmount: number;
    isWin?: string | null;
    status: string;
    payout?: number | null;
    payoutTxId?: string | null;
    asset: CryptoCurrency;
    txIdForUrl: string;
    gameName?: string | null;
}

const HistoryTableRow = ({
    gameCode,
    gameId,
    payoutRate,
    area,
    result,
    txId,
    betAmount,
    isWin,
    status,
    payout,
    payoutTxId,
    asset,
    txIdForUrl,
    gameName,
}: IHistoryTableRowProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const formattedPayoutRate = `${payoutRate?.toFixed(2) || "0.00"}x`;
    const formattedPick = games[gameCode].formatPick?.(area);
    const formattedResult = result && games[gameCode].formatResult(result);

    return (
        <tr className={cx("small")} onClick={() => setIsOpen(!isOpen)}>
            <td className={cx({ "hide-mobile": thirdPartyGames.includes(gameCode) })}>{gameId || "-"}</td>
            {gameName && <td>{gameName}</td>}
            <td className={cx("bet-amount")}>
                <div className="flex items-center justify-center">
                    <span className={cx("small")}>{betAmount}</span>
                    <CurrencyIcon currency={asset} />
                </div>
            </td>
            {gameCode !== "bitto" && (
                <td className={cx({ "hide-mobile": thirdPartyGames.includes(gameCode) })}>{formattedPayoutRate}</td>
            )}
            {!thirdPartyGames.includes(gameCode) && <td className={cx("hide-mobile")}>{formattedPick}</td>}
            {!thirdPartyGames.includes(gameCode) && <td className={cx("hide-mobile")}>{formattedResult || "-"}</td>}
            <td>
                <BetDetails
                    isOpen={thirdPartyGames.includes(gameCode) ? false : isOpen}
                    onClose={() => setIsOpen(false)}
                    gameCode={gameCode}
                    isWin={isWin === "Y"}
                    status={status}
                    betAmount={betAmount}
                    payout={payout}
                    payoutRate={formattedPayoutRate}
                    txId={txId}
                    playerPick={formattedPick}
                    result={formattedResult}
                    payoutTxId={payoutTxId}
                    asset={asset}
                    txIdForUrl={txIdForUrl}
                />
            </td>
        </tr>
    );
};

export default HistoryTableRow;
