import { StyledLabel, StyledSpan } from "src/affiliate/components/reusable/assets/styles/toggle.styled";

interface IToggleProps {
    toggle: boolean
    onChange: (toggle: boolean) => void;
}

const Toggle = ({ toggle, onChange }: IToggleProps) => (
    <>
        <StyledLabel>
            <input type="checkbox" onChange={() => onChange(toggle)} />
            <StyledSpan backGroundColor={toggle} transform={toggle} />
        </StyledLabel>
    </>
);

export default Toggle;
