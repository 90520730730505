import { useEffect, useRef } from "react";

type Callback = () => void;

export default function useDebouncedCallback(
    callback: Callback,
    value: string | number,
    debounceDuration: number
): void {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback();
        }, debounceDuration);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [value, debounceDuration, callback]);
}
