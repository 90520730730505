export default function KycPolicy() {
    return (
        <>
            <h5>Objective and Scope of the Policy</h5>
            <p>
                Peergame has responsibilities to ensure that Anti Money Laundering legislation is complied with.
                The Managing Director of the company and all employees of Peergame have individual obligations
                in respect of anti-money laundering, counter-terrorist financing, and in general
                for preventing the misuse of the company's services for illegal activity of any kind.
                These are taken seriously at all levels and robust measures are taken to ensure that any efforts
                to launder the proceeds of criminal activity through company operations are prevented.
            </p>

            <p>
                Where any suspicion of money laundering or terrorist financing arises, the company will ensure that it is
                dealt with appropriately, having regard to individual circumstances and the requirements of the associated
                legislation.
            </p>

            <p>
                In order to ensure that the company and its employees are not in breach of Anti Money Laundering legislation,
                the company will:
            </p>

            <p>
                1. Maintain the role of an appointed independent Compliance Officer or Compliance Function that acts as an
                Anti Money Laundering Officer(s) and support team.
            </p>

            <p>
                2. Deliver staff training at all levels that include identification of suspicious transactions, activity or
                incidents, and the correct reporting process.
            </p>

            <p>
                3. Include in staff training the fact that a customer should not in any way be informed that they are either
                suspected or the subject of a Suspicious Activity Report.
            </p>

            <p>
                4. Operate an Audit process that measures compliance with Anti-Money Laundering procedures and identifies
                points of difficulty or uncertainty.
            </p>

            <p>
                5. Risk assesses the business and ensures that an appropriate level of examination is provided to higher risk
                businesses.
            </p>

            <p>6. Maintain reports, records of anti-money laundering activities for inspection by the regulators.</p>

            <p>
                7. Provide a source of communication for all inquiries or questions regarding this Policy and a reporting
                process that directs information to the Compliance Officer.
            </p>

            <p>
                8. Make Suspicious Activity Reports in accordance with National Ordinance on the Reporting of Unusual
                Transaction (NORUT).
            </p>

            <p>
                9. Consider any action by an employee that is in breach of company instruction or policy in relation to Anti
                Money Laundering as an action, which may lead to disciplinary action and summary dismissal.
            </p>

            <p>10. Undertake appropriate measures to ensure that Know Your Customer Procedure applies to all customers.</p>

            <p>
                11. Undertake activities to keep records for providing financial services at least five (5) years, and where
                applicable at least ten (10) years.
            </p>

            <h5>Peergame Guarantee</h5>
            <p>
                All Users of peergame.com website ensure by accepting this AML/KYC Policy and Terms & Conditions at least the
                following:
            </p>

            <p>
                a. The User is not an individual under 18 years old or other legal age of majority in User's jurisdiction.
            </p>

            <p>b. The User is neither under legal supervision nor restricted in his business activities.</p>

            <p>c. The User has registered personally and not on the behalf of someone else.</p>

            <p>d. The User uses the account for personal use and has no commercial intentions.</p>

            <p>e. The User has no knowledge about any bet result of the underlying bet before placing it.</p>

            <p>f. The User does not have multiple accounts on peergame.com website.</p>

            <p>g. The User has read and accepted this Policy.</p>

            <p>
                h. The User cannot sell, transfer, assign, and/or acquire by any other means the accounts to/from other Users.
            </p>

            <p>i. The User assures that he/she won’t use his/her account to transfer funds amongst his/her accounts</p>

            <h5>Know your customer (KYC) Procedure</h5>
            <p>
                Know Your Customer Procedure (KYC) represents the process of customers' identification and verification of its
                identity, identification of the beneficial owner/ beneficiary, and confirmation of his/ her identity using
                reliable, independent source documents, data, or information. It also includes providing data and information
                on the business relationship's purpose and continuously monitoring the business relationship with the customer
                to identify its risk profile.
            </p>

            <h5>Customer Due Diligence (CDD)</h5>
            <p>
                The Company adopts a risk-based approach for customer due diligence to understand that not all customers
                represent the same level of risk. The Company performs customer due diligence in the following circumstances:
            </p>

            <p>
                {" "}
                - before the business relationship is established;
                <br />
                - before carrying out a transaction that is equal to or above NAF (ANG) 5000 or 25 Bitcoin SV (BSV) for a
                customer with whom a business relationship is not established, whether the transaction is carried out in a
                single operation or in several functions which appear to be linked;
                <br />
                - when there is a suspicion of money laundering or terrorist financing, regardless of the amounts involved or;
                <br />- when there are doubts about the veracity or adequacy of previously obtained customer identification
                data.
            </p>

            <p>
                {" "}
                We conduct CDD and ongoing monitoring by scrutinizing transactions and identifying the funds' source to ensure
                that they are consistent with the licensed activity taking place.
            </p>

            <p>
                {" "}
                We apply these measures on a risk-sensitive basis depending on the type of customer, business levels,
                deposits, withdrawals, nature of the funds, and their source. Certain situations present a greater risk of
                money laundering or terrorist financing. Higher risk customers (for example, PEPs) will be subject to a higher
                degree of due diligence (EDD).
            </p>

            <p>
                {" "}
                Customer identification is by way of the electronic application form or by submission of the necessary
                documentation, completed by the customer – natural person, providing personal information including full legal
                name, residential or national address or place of business, location, and date of birth and nationality, type
                and number of identification document with photo included.
            </p>

            <p>
                {" "}
                The Company accepts customers that are in the age applicable for playing games of chance/ gambling in their
                jurisdiction and, in any case, over 18 years old.
            </p>

            <p>
                {" "}
                The essential documents that are acceptable for customer identification for a natural person and that are
                needed for performing CDD (i.e., customers file) are as follows:
            </p>

            <p>
                {" "}
                - Personal identification document issued by an official governmental body (Passport, ID Card or Driving
                License);
                <br />- Certified Extract from the Civil Registry of the customer's residence, Utility Bill or Bank Statement,
                not older than three (3) months.
            </p>

            <p>
                {" "}
                In the verification stage, an electronic check of the customer's information, using data from multiple sources
                and across time, is made. In certain risk-based situations, it may be necessary to consider and assess the
                customer's source of funds and legitimate income level, e.g., their occupation. In these situations, Bank
                Statement/ Proof from exchanges is accepted. This will help establish if it is in line with their known
                profile or if there is a reason for suspicion.
            </p>

            <p> Additional Risk-based Information:</p>

            <p>
                {" "}
                - Source of funds
                <br />
                - SSN/Tax ID
                <br />- Employment Agreement
            </p>

            <p>
                {" "}
                From time to time, in accordance with the applicable regulations and regarding the risk assessment for
                verification and validation of the submitted application form, an additional personal identification document
                issued by an official governmental body can be required. Also, providing information regarding the customers'
                financial status and source of funds Employment Agreement is accepted. Other documents and information from
                reliable and independent sources can also be used from case to case. In certain situations, if there is doubt
                about the provided documents' veracity, notarization shall be demanded.
            </p>

            <p>
                {" "}
                The documents obtained from the customers are updated regularly, at least two years. The Company can rely on a
                third party to perform the KYC measures on behalf of the Company. If this happens, the Company is obliged to
                ensure that the third party can immediately obtain all necessary information prescribed by the applicable
                legislation, including but not limited to copies of identification data and other relevant documentation
                relating to the due diligence measures without delay.
                <br />
            </p>

            <h5>Anti-money Laundering Policy</h5>
            <p>
                Peergame requires a strict anti-money laundering policy with zero tolerance for money laundering activities.
                We define money laundering as any activity carried out to misrepresent the source of funds acquired through
                illegal processes as funds received through lawful bases/activities.
            </p>

            <p>Peergame assures complete compliance with laws of anti-money laundering through its related policy.</p>

            <p>
                If there is suspicion of money laundering or terrorist financing - transaction for which there is reason to
                believe that it may be related to money laundering or terrorist financing, including but not limited to:
            </p>

            <p>
                - Funds derived from illegal activity or are intended to hide or disguise funds or assets derived from
                criminal activity
                <br />
                - Designed to evade the requirements of the applicable legislation, whether through the structuring of other
                means
                <br />
                - Serve no business or apparent lawful purpose, and no reasonable explanation for the transaction is available
                after examining all available facts
                <br />- Or any other case where the company knows, suspects, or has reasonable grounds to suspect that funds,
                regardless of the amount involved, are the proceeds of criminal activity or are related to terrorist
                financing.
            </p>

            <p>List of red flags that are related to customers:</p>

            <p>
                1. The customer derives from a country that does not implement standards for preventing money laundering and
                terrorist financing. It is known as a drug-related country or country categorized as a tax haven.
                <br />
                2. Depositing funds and withdrawing it with minimal playing.
                <br />
                3. The customer uses fake identification documents or refuses to identify.
                <br />
                4. The authenticity of the customer's ID cannot be confirmed quickly.
                <br />
                5. The customer cancels the account when there is a need for identification.
                <br />
                6. Customers' behavior is changed drastically changed and becomes unusual. (i.e., at start playing on minimal
                amounts and suddenly starts playing on significant amounts).
                <br />
                7. The customer insists on confirming the winnings or intends to establish a more considerable amount of the
                winnings.
                <br />
                8. The customer is interested in internal control measures and internal regulations regarding money laundering
                or terrorist financing.
                <br />
                9. The customer is interested in information regarding the amount for reporting of transactions.
                <br />
                10. The customer is undertaking activities to avoid the obligation for reporting by withdrawing amounts just
                below the reporting threshold.
                <br />
                11. There is information that the customer has been involved in crimes or crime-related news (adverse media).
            </p>

            <p>
                Peergame monitors its client and account activity and takes appropriate measures to prevent money laundering.
            </p>

            <h5>Compliance Officer</h5>
            <p>
                The company acknowledges the risk of being used in the process of money laundering and terrorist financing.
                Therefore, the Managing Director appoints Compliance Officer, an Anti-Money Laundering Officer who undertakes
                activities and is responsible for identifying, assessing, and mitigating the risk of money laundering and
                terrorist financing.
            </p>

            <p>
                The Compliance Officer is responsible for ensuring that this Policy and Procedures are implemented. All
                matters involved with money laundering will be referred to the Compliance Officer for consideration and
                investigation, and this also includes such issues as consent to provide services and procedures for dealing
                with customers who may fall into the category of politically exposed person (PEP) or subject to sanctions or
                with whom a business relationship will make the risk of misusing the company for money laundering and
                terrorist financing and may expose the company to the risk of regulatory fines.
            </p>

            <p>
                The company has a laid down structure for reporting any suspicions regarding AML/CTF. It is the Compliance
                Officer's responsibility to consider, investigate, and report such matters in a timely and prescribed method
                by legislation.
            </p>

            <p>
                The Compliance Officer also produces a monthly and annual report, together with reports commissioned by the
                Managing Director, to ensure they are informed of matters, issues, and risks suspected or identified
                concerning money laundering.
            </p>

            <p>
                The company can rely on a third party or intermediary, a company in compliance with Article 2c from the
                National Ordinance on Identification when Rendering Services.
            </p>

            <p>The roles and responsibilities of the Compliance Officer are as follows:</p>

            <p>
                - Identifies and monitors alerts generated on customers' transactions, both from the software and business
                lines.
                <br />
                - Reporting of suspicious activity to the Financial Intelligence Unit.
                <br />
                - Provides information upon request to the Supervisory Authority, including the Financial Intelligence Unit.
                <br />
                - Identifying trends, system weaknesses, and staff training awareness may expose the company or its operators
                to criminals' risk of abuse.
                <br />
                - Manage, monitor, and audit the effectiveness of all Anti-Money Laundering policies and procedures.
                <br />
                - Be aware of all current and relevant AML/CTF legislation, processes, and guidance.
                <br />
                - Ensure that all advice and information from the Supervisory Authorities are received, understood, and
                implemented within its procedures.
                <br />
                - Manages sanctions screening software or processes.
                <br />
                - Prepare and present periodic reports, which from time to time, show changes to the AML/CTF process of law,
                the numbers and typologies of cases considered, and reported by staff.
                <br />
                - Manages and coordinates regulatory examinations.
                <br />
                - To identify the Managing Director, any risks that may be identified.
                <br />- Act as a liaison with 3rd party Suppliers.
            </p>

            <p>
                Peergame uses outside services to carry out a KYC check and comply with such procedures' legislative rules and
                standards. For such purposes, there's a written agreement of Peergame with PASSBASE INC to carry out the
                clients' KYC check on its part as a processor of its Users' data.
            </p>

            <p>
                PASSBASE INC and Peergame guarantee that all clients' data received during the KYC check of such clients are
                kept safely and securely. They can be processed only in compliance with the GDPR and Privacy Policy of
                Peergame.
            </p>

            <h5>Training</h5>
            <p>
                The company provides training to all its employees, including Senior management. The training is tailor-made
                and is used to increase the knowledge and raise awareness to all the employees of the company for the
                provisions of the anti-money laundering and terrorist financing regulations. Training is performed at least on
                an annual basis and is conducted by Compliance Officer or third parties with relevant knowledge of the subject
                of prevention of money laundering and terrorist financing.
            </p>

            <p>
                The training can be conducted in-class or online. Moreover, online newsletters can be provided for the
                employees on a regular basis.
            </p>

            <p>The topics that should be presented for all employees on training are as follows:</p>

            <p>
                - Introduction for the term of money laundering and terrorist financing;
                <br />
                - Legislation, guidelines and international standards used for prevention of money laundering and terrorist
                financing’;
                <br />
                - Stages of money laundering;
                <br />
                - Money laundering schemes;
                <br />
                - How to prevent from using the company for money laundering and terrorist financing;
                <br />
                - Providing information for the purpose of establishing a business relationship with the company (including
                KYC measures);
                <br />
                - Risk types of customers, countries, products;
                <br />
                - Activities related to money laundering and terrorist financing;
                <br />- Example of cases for money laundering and/ or terrorist financing.
            </p>

            <p>
                The list is non-exhaustive and can be changed from time to time as needed. The topics for the training are
                subject to change in the manner of improving them with other useful information and new typologies for
                misusing the company for money laundering and terrorist financing. Also, since the purpose of the training is
                to raise awareness for the employees to implement the regulations in their daily work proficiently, the
                training is changed upon changes in the relevant regulations.
            </p>

            <p>The training for the senior management includes, but not limits to:</p>

            <p>
                - Introduction for the term of money laundering and terrorist financing;
                <br />
                - Legislation, guidelines, and international standards used for the prevention of money laundering and
                terrorist financing;
                <br />
                - How to prevent from using the company for money laundering and terrorist financing;
                <br />
                - Risk types of customers, countries, products;
                <br />
                - Activities related to money laundering and terrorist financing;
                <br />- Example of cases for money laundering and/ or terrorist financing.
            </p>

            <p>
                The list is non-exhaustive and can be changed from time to time as needed. The training topics are subject to
                change in improving them with other useful information and new typologies for misusing the company for money
                laundering and terrorist financing.
            </p>

            <p>Records are kept regarding the training in accordance with the Record Keeping Procedure.</p>

            <h5>Internal Audit</h5>
            <p>
                The company performs an audit on the AML system, including processes, procedures, policies, and overall
                activities undertaken to implement the legislation governing the prevention of money laundering and terrorist
                financing. Accordingly, the AML audit is performed on a yearly basis.
            </p>

            <p>
                The company elects an independent audit function to test the effectiveness and adequacy of the measures
                undertaken to comply with the relevant legislation. The audit function reports directly to the Managing
                Director.
            </p>
        </>
    );
}
