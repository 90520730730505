import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledFlexContainer = styled(FlexContainer)`
    background-color: ${({ theme }) => theme.color.white};
`;

export const StyledTitle = styled.h2`
    color: #22096c;
    @media only screen and (max-width: 568px) {
        font-size: 28px;
        line-height: 1.29;
    }
`;