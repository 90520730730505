import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledFlexContainer = styled(FlexContainer)`
    width: 100%;
    border-radius: 4px;
    background-color: #22096c;
`;

export const StyledH5 = styled.h5`
    color: #ffffff;
    margin: 0 0 8px 0;
`;