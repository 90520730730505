import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import styles from "./assets/styles.scss";

import HashImg from "./assets/hash-img.png";
import SecretKeyImg from "./assets/secret-key-img.png";

const cx = classNames.bind(styles);

export default function ProvablyFair() {
    return (
        <div className={cx("provably-fair")}>
            <p>
                “Provably fair” is one of those terms that gets a lot of attention in these days. Betting activity is believed
                to be provably fair when there are adequate means in place that enables both the player and operator (dealer) to
                validate the fairness of a gaming process.
            </p>
            <p>
                The concept of Provably fair is uniquely tied to the emergence of crypto casinos that use cryptocurrencies like
                Bitcoin SV running on a cryptographic algorithm. Thereby, with the use of hash functions, external interference
                became almost impossible.
            </p>
            <p>
                Peergame implemented the provably fair algorithm to provide fully transparent and verifiable game results. On
                Peergame, you have total control over your betting experience, and 100% fairness of the games always guaranteed
                with the unique casino experience.
            </p>
            <h4>Implementation</h4>
            <h5>HashChain</h5>
            <p>Peergame uses a secret key with SHA-256 to generate a chain of 1,000,000 hashes.</p>
            <img src={HashImg} alt="" />
            <p>
                Each round seed’s hash equals the previous round seed.
                <br />
                For example, 1,000,000th seed is the first round of the game.
            </p>
            <img src={SecretKeyImg} alt="" />
            <h5>Randomness</h5>
            <p>
                First, hash the combination of the current round seed and all participated transaction IDs in the previous round
                to create a random number.
            </p>
            <div className={cx("block")}>Random number = hash (current round’s seed + previous round’s TxIDs)</div>
            <h5>Shuffling Cards</h5>
            <p>
                The random number goes through the Fisher-Yates Shuffle algorithm to shuffle and randomly generate card orders
                also pick with equal probability.
            </p>
            <div className={cx("block")}>
                Card Order = hash (current round’s seed + previous round’s TxIDs) + shuffle algorithm
            </div>
            <p>
                Each game may have different fairness process depends on the game results.
                <br />
                You can also check our Fairness pages for the games.
            </p>
            <Link to="/games/baccarat/fairness">Baccarat</Link>
            <br />
            <Link to="/games/laddergame/fairness">Ladder Game</Link>
            <br />
            <Link to="/games/turtlerace/fairness">Turtle Race</Link>
        </div>
    );
}
