import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import download from "downloadjs";
import usePagination from "src/affiliate/hooks/use-pagination";
import useDropdown from "src/affiliate/hooks/use-dropdown";
import useFetch from "src/helpers/useFetch";
import { axiosInstance } from "src/core/http/axios-instance";
import Layout from "src/affiliate/components/Layout";
import DashBoardLayout from "src/affiliate/components/reusable/DashBoardLayout";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import DataCard from "src/affiliate/components/reusable/DataCard";
import Tabs from "src/affiliate/components/Transactions/Tabs";
import Payouts from "src/affiliate/components/Transactions/Payouts";
import Bets from "src/affiliate/components/Transactions/Bets";

interface ITransactionInfo {
    available_payouts: number;
    lifetime_payouts: number;
};

interface ITransactionPayout {
    payout_list: any[];
    total_pages: number;
};

interface ITransactionBet {
    bet_list: any[];
    total_pages: number;
};

const Transactions = () => {
    const [selectedTab, setSelectedTab] = useState<string>("Payout");
    const [page, setPage] = useState<{ betsTotalPages: number; payoutTotalPages: number }>({
        betsTotalPages: 0,
        payoutTotalPages: 0,
    });

    const {
        pagination,
        prevPage,
        nextPage,
        changePage,
        currentPage,
    } = usePagination(0, page.payoutTotalPages);

    const {
        pagination: betsPagination,
        prevPage: betsPrevPage,
        nextPage: betsNextPage,
        changePage: betsChangePage,
        currentPage: betsCurrentPage,
    } = usePagination(0, page.betsTotalPages);

    const {
        onClickHandler,
        value,
        trigger,
        setTrigger,
    } = useDropdown({ name: "", value: "" }, true);

    const {
        onClickHandler: betsOnCLickHandler,
        value: betsValue,
        trigger: betsTrigger,
        setTrigger: betsSetTrigger,
    } = useDropdown({ name: "", value: "" }, true);

    const { data: transactionInfo, loaded: loadedTransactionInfo } = useFetch<ITransactionInfo>("/api/v1/affiliate/transaction/info");

    const { available_payouts: availablePayouts, lifetime_payouts: lifetimePayouts } = loadedTransactionInfo && transactionInfo
        ? transactionInfo
        : {
            available_payouts: 0,
            lifetime_payouts: 0,
        };

    const { data: transactionPayout, loaded: loadedTransactionPayout } = useFetch<ITransactionPayout>(
        `/api/v1/affiliate/transaction/payout?size=10&page=${currentPage}&sort=createdAt,DESC`
    );

    const { payout_list: payoutList, total_pages: payoutTotalPages } = loadedTransactionPayout && transactionPayout
        ? transactionPayout :
        {
            payout_list: [],
            total_pages: 0,
        };

    const { data: transactionBet, loaded: loadedTransactionBet } = useFetch<ITransactionBet>(
        `/api/v1/affiliate/transaction/bet?size=10&page=${betsCurrentPage}&sort=createdAt,DESC`
    );

    useEffect(() => {
        if (betsValue.value) {
            axiosInstance
                .get(`/api/v1/affiliate/transaction/bet/excel?period=${betsValue.value}`)
                .then(res => download(res, `bets${Date.now()}.csv`, "text/csv"))
                .catch(e => console.log(e));
        }
        if (value.value) {
            axiosInstance
                .get(`/api/v1/affiliate/transaction/payout/excel?period=${value.value}`)
                .then(res => download(res, `payouts_${Date.now()}.csv`, "text/csv"))
                .catch(e => console.log(e));
        }
    }, [betsValue.value, value.value]);

    const { bet_list: betList, total_pages: betsTotalPages } = loadedTransactionBet && transactionBet ?
        transactionBet : {
            bet_list: [],
            total_pages: 0,
        };

    useEffect(() => {
        setPage(prev => ({ ...prev, betsTotalPages, payoutTotalPages }));
    }, [betsTotalPages, payoutTotalPages]);

    return (
        <>
            <Helmet>
                <title>Transactions</title>
                <meta
                    property="og:title"
                    content="Peergame Partners - Partner up with the highest-paid commission casino affiliate program."
                />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
                />
            </Helmet>
            <Layout sideNav transparent>
                <DashBoardLayout title="Transactions">
                    <FlexContainer
                        margin="24px 0 24px 0"
                        width="53%"
                        tabletWidth="65%"
                        mobileWidth="100%"
                        justifyContent="space-between"
                        mobileFlexWrap
                    >
                        <FlexItem width="49%" mobileWidth="100%" mobileMargin="0 0 6px 0">
                            <DataCard
                                name="Available Payouts"
                                value={loadedTransactionInfo ? availablePayouts : "-"}
                                gradientDirection
                                bsIcon
                            />
                        </FlexItem>
                        <FlexItem width="49%" mobileWidth="100%">
                            <DataCard
                                name="Lifetime Payouts"
                                value={loadedTransactionInfo ? lifetimePayouts : "-"}
                                gradientDirection
                                bsIcon
                            />
                        </FlexItem>
                    </FlexContainer>
                    <Tabs tabTitles={["Payout", "Bets"]} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                    {selectedTab === "Payout" ? (
                        <Payouts
                            pagination={pagination}
                            prevPage={prevPage}
                            setTrigger={setTrigger}
                            trigger={trigger}
                            value={value}
                            onClickHandler={onClickHandler}
                            changePage={changePage}
                            nextPage={nextPage}
                            payout={payoutList}
                            loaded={loadedTransactionPayout}
                            payoutTotalPages={page.payoutTotalPages}
                        />
                    ) : (
                        <Bets
                            pagination={betsPagination}
                            prevPage={betsPrevPage}
                            setTrigger={betsSetTrigger}
                            trigger={betsTrigger}
                            value={betsValue}
                            onClickHandler={betsOnCLickHandler}
                            changePage={betsChangePage}
                            nextPage={betsNextPage}
                            bets={betList}
                            loaded={loadedTransactionBet}
                            BetsTotalPages={page.betsTotalPages}
                        />
                    )}
                </DashBoardLayout>
            </Layout>
        </>
    );
};

export default Transactions;