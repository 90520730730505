import { CryptoCurrency } from "src/core/currency/currency.model";

export type WalletAssetBalance = { amount: number, asset: CryptoCurrency };

export interface IWalletBalanceResponse {
    timestamp: number;
    data: WalletAssetBalance[]
}


export interface IWalletResponse {
    default: boolean
    name: CvApiWalletProvider
    sendable: boolean
    paymail?: string
}

export interface IWalletTopupResponse {
    defaultWallet: CvApiWalletProvider
    uri: string
}

export const DeprecatedMoneyButtonProvider = "moneybutton" as const;
export type DeprecatedMoneyButtonProvider = typeof DeprecatedMoneyButtonProvider;

export type CvApiWalletProvider = WalletProvider.HandCash | DeprecatedMoneyButtonProvider;

export enum WalletProvider {
    WalletConnect = "walletconnect",
    HandCash = "handcash"
}