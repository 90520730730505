import { ReactElement } from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import MyAccountMenu from "src/components/header/MyAccountMenu";
import SideNav from "src/components/header/SideNav";
import WalletMenu from "src/components/header/WalletMenu/WalletMenu";
import PeergameLogoLight from "./assets/images/peergame-logo-light.svg?inline";
import PeergameLogoDark from "./assets/images/peergame-logo-dark.svg?inline";
import IconRaces from "./assets/images/races.svg?inline";
import IconSlots from "../../assets/img/slots.svg?inline";
import IconTableGames from "../../assets/img/table-games.svg?inline";
import IconGames from "../../assets/img/games.svg?inline";
import styles from "./assets/styles.scss";
import GamesHeader from "src/components/GamesHeader/GamesHeader";
import AuthButton from "src/feature/auth/AuthButton";
import { useMobileOnly, useTabletUp, useMobileUp } from "src/helpers/responsive";
import useLocationChange from "src/helpers/useLocationChange";
import { WalletDialog } from "src/feature/wallet/dialogs/WalletDialog";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";

interface IHeaderProps {
    game?: string | null;
    displaySubheader?: boolean;
    classes?: string[];
}

interface INavItem {
    route: string;
    icon: ReactElement;
    text: string;
    isVisible: boolean
}

const cx = classNames.bind(styles);

export default function Header({ game = null, displaySubheader = true, classes = [] }: IHeaderProps) {
    const isMobileOnly = useMobileOnly();
    const isTabletUp = useTabletUp();
    const isMobileUp = useMobileUp();
    const {isAuthorized, walletProvider} = useAuthorizedState();

    useLocationChange(() => {
        document.body.scrollTo({ top: 0, behavior: "smooth" });
    });

    function gamesHeader() {
        return displaySubheader && game ? <GamesHeader /> : null;
    }

    const navItems: INavItem[] = [
        {
            route: "/games",
            icon: <IconGames />,
            text: !isMobileOnly ? "Games" : "",
            isVisible: isMobileUp,
        },
        {
            route: "/games/slots",
            icon: <IconSlots />,
            text: "Slots",
            isVisible: isTabletUp,
        },
        {
            route: "/games/table",
            icon: <IconTableGames />,
            text: "Table Games",
            isVisible: isTabletUp,
        },
        {
            route: "/races",
            icon: <IconRaces />,
            text: "Races",
            isVisible: isTabletUp,
        },
    ];

    return (
        <>
            <header className={cx(classes)}>
                <div className={cx("left")}>
                    <SideNav reverse={!classes.includes("transparent")} />
                    <Link to="/" className={cx("logo")}>
                        {!classes.includes("transparent") && <PeergameLogoLight />}
                        {classes.includes("transparent") && <PeergameLogoDark />}
                    </Link>
                </div>

                <div className={cx("center")}>
                    {navItems.map(({ icon, text, route, isVisible }) => isVisible && (
                        <Link key={text} to={route} className={cx("header-button", { reverse: !classes.includes("transparent") })}>
                            {icon}
                            {text}
                        </Link>
                    ))}

                </div>
                <div className={cx("right")}>
                    {isAuthorized &&
                        <WalletMenu reverse={!classes.includes("transparent")} forWalletConnect={walletProvider === WalletProvider.WalletConnect} />
                    }
                    {isAuthorized && walletProvider === WalletProvider.WalletConnect && <WalletDialog />}
                    {isAuthorized ? <MyAccountMenu reverse={!classes.includes("transparent")} /> : <AuthButton />}
                </div>
            </header>
            {gamesHeader()}
        </>
    );
}