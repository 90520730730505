import { useRef, useState } from "react";
import { withRouter } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SideNavMenuItem from "src/affiliate/components/side-nav/SideNavMenuItem";
import dashboardIcon from "./assets/Dashboard.svg";
import campaignsIcon from "./assets/Campaigns.svg";
import commissionPlansIcon from "./assets/Pie Chart.svg";
import referredUsersIcon from "./assets/Star Badge.svg";
import transactionsIcon from "./assets/Invoice.svg";
import resourcesIcon from "./assets/Images.svg";
import accountSettingsIcon from "./assets/Settings.svg";
import dashboardMenuIcon from "./assets/Dashboard Menu Icon.svg";
import useOnClickOutside from "src/affiliate/hooks/use-on-click-outside";
import useScrollingElement from "src/affiliate/hooks/use-scrolling-element";
import { campaignSchema } from "src/affiliate/components/campaigns/config";
import useModal from "src/affiliate/hooks/use-modal";
import Button from "src/affiliate/components/reusable/Button";
import CreateCampaignModal from "src/affiliate/components/reusable/CreateCampaignModal";
import { StyledMenuTrigger, StyledFlexContainer, StyledLine } from "src/affiliate/components/reusable/assets/styles/side-nav.styled";

interface ISideNavProps {
    match: { path: string };
}

const routes = [
    {
        id: 0,
        name: "Dashboard",
        location: "dashboard",
        image: dashboardIcon,
    },
    {
        id: 1,
        name: "Campaigns",
        location: "campaigns",
        image: campaignsIcon,
    },
    {
        id: 2,
        name: "Commission Plans",
        location: "commission-plans",
        image: commissionPlansIcon,
    },
    {
        id: 3,
        name: "Referred Users",
        location: "referred-users",
        image: referredUsersIcon,
    },
    {
        id: 4,
        name: "Transactions",
        location: "transactions",
        image: transactionsIcon,
    },
    {
        id: 5,
        name: "Resources",
        location: "resources",
        image: resourcesIcon,
    },
    {
        id: 6,
        name: "Account Settings",
        location: "account-settings",
        image: accountSettingsIcon,
    },
];

const SideNav = ({ match: { path = "" } }: ISideNavProps) => {
    const [toggler, setToggler] = useState(false);
    const [open, onOpen, onClose] = useModal();
    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        reset,
    } = useForm({
        resolver: yupResolver(campaignSchema),
        mode: "all",
    });
    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => setToggler(false));

    useScrollingElement(window.innerWidth <= 568 ? toggler : false);

    const onClickHandler = () => {
        setToggler(prev => !prev);
    };
    return (
        <>
            <StyledMenuTrigger onClick={() => onClickHandler()} zIndex={toggler}>
                {toggler ? (
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="18" height="2" rx="1" fill="#22096C" />
                        <rect y="12" width="22" height="2" rx="1" fill="#22096C" />
                        <rect y="6" width="12" height="2" rx="1" fill="#22096C" />
                    </svg>
                ) : (
                    <img src={dashboardMenuIcon} alt="menu-icon" />
                )}
            </StyledMenuTrigger>
            <StyledFlexContainer ref={ref} openCloseMenu={toggler}>
                {routes.map(({
                    id, name, location, image,
                }) => (
                    <SideNavMenuItem key={id} name={name} location={location} path={path} image={image} />
                ))}
                <StyledLine />
                <Button width="210px" buttonShape="bordered" onClick={onOpen} hoverTextColor="#f0ecff">
                    Create New Campaign
                </Button>
            </StyledFlexContainer>
            <CreateCampaignModal
                open={open}
                onClose={onClose}
                register={register}
                handleSubmit={handleSubmit}
                reset={reset}
                errors={errors}
                name={{ first: "name", second: "code" }}
                label={{ first: "Campaign Name", second: "Campaign Code" }}
                isValid={isValid}
            />
        </>
    );
};

export default withRouter(SideNav);
