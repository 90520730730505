import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Redirect } from "react-router";
import Verification from "src/affiliate/components/email-verification/Verification";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import EmailVerificationAndExpiration from "src/affiliate/components/email-verification-and-expiration/EmailVerificationAndExpiration";
import Loader from "src/components/loader/Loader";
import { verifyEmail } from "src/core/affiliate/affiliate.reducer";
import Layout from "src/affiliate/components/Layout";
import { useAppDispatch, useAppSelector } from "src/redux/reducer";

const EmailVerification = ({ location }) => {
    const dispatch = useAppDispatch();
    const affiliateStatus = useAppSelector(({ affiliate }) => affiliate.status);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const authToken = new URLSearchParams(location?.search).get("auth_token");
        setIsLoading(true);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        dispatch(verifyEmail(authToken)).finally(() => {
            setIsLoading(false);
        });
    }, []);

    let mainComponent;
    switch (affiliateStatus) {
        case "ACTIVE":
            mainComponent = <Redirect to="dashboard" />;
            break;
        case "REVIEWING":
            mainComponent = <Verification />;
            break;
        case "PENDING":
            mainComponent = <Redirect to="login" />;
            break;
        default:
            mainComponent = (
                <EmailVerificationAndExpiration
                    span="Your verification link has expired. "
                    description="Please press resend email button, check your email and click the link to activate your partner
          account"
                    title="Link Expired"
                />
            );
    }
    if (isLoading) {
        mainComponent = <Loader />;
    }
    return (
        <>
            <Helmet>
                <title>Email-verification</title>
                <meta property="og:title" content="Peergame beta-verification" />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and
          enhanced privacy"
                />
            </Helmet>
            <Layout transparent allowedRoutes>
                <FlexContainer padding="60px 0 302px 0" justifyContent="center">
                    {mainComponent}
                </FlexContainer>
            </Layout>
        </>
    );
};

export default EmailVerification;
