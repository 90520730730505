import classNames from "classnames/bind";
import { useSpring, animated } from "@react-spring/web";
import styles from "./assets/styles.scss";
import IconArrowDown from "src/components/game/assets/images/arrow-down.svg?inline";
import { languages } from "src/components/header/constants/languages-list";

interface ILangSwitcherProps {
    isOpen: boolean;
    toggleOpen: () => void;
    onOpened: () => void;
    reverse?: boolean;
}

const OPTION_HEIGHT = 46;

const cx = classNames.bind(styles);

function getLangCode() {
    if (window.bablic) {
        return window.bablic.getLocale();
    }
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("locale") || "en";
}

const dropdownHeight = OPTION_HEIGHT * (Object.entries(languages).length - 1);

export default function LangSwitcher({
    isOpen, toggleOpen, reverse = false, onOpened,
}: ILangSwitcherProps) {
    const [dropdownStyle] = useSpring(
        {
            to: { height: isOpen ? dropdownHeight : 0 },
            onRest: () => isOpen && onOpened(),
        },
        [isOpen],
    );

    function switchLanguage(newLanguage: string) {
        window.bablic.redirectTo(newLanguage, { forceRedirect: true });
        toggleOpen();
    }

    const selectedLang = languages[getLangCode()];
    return (
        <div className={cx("lang-switcher", { reverse })}>
            <button
                className={cx("dropdown-trigger")}
                type="button"
                onClick={() => toggleOpen()}
                style={{ height: OPTION_HEIGHT }}
            >
                <img src={selectedLang.icon} alt={selectedLang.name} />
                <span className={cx("small")}>{selectedLang.name}</span>
                <IconArrowDown className={cx({ rotate: isOpen })} />
            </button>
            <animated.div style={dropdownStyle} className={cx("dropdown")}>
                {Object.entries(languages)
                    .filter(([key]) => key !== getLangCode())
                    .map(([key, { icon, name }]) => (
                        <button key={key} type="button" onClick={() => switchLanguage(key)} style={{ height: OPTION_HEIGHT }}>
                            <img src={icon} alt={name} />
                            <span className={cx("small")}>{name}</span>
                        </button>
                    ))}
            </animated.div>
        </div>
    );
}