import { renderRoutes } from "react-router-config";
import { useRouteMatch } from "react-router-dom";
import Registration from "src/containers/Affiliate/registration/registration";
import AffiliateLanding from "src/containers/Affiliate/affiliate-landing/AffiliateLanding";
import Dashboard from "src/containers/Affiliate/dashboard/Dashboard";
import CommissionPlans from "src/containers/Affiliate/commission-plans/CommissionPlans";
import ReferredUsers from "src/containers/Affiliate/referred-users/ReferredUsers";
import Transactions from "src/containers/Affiliate/transactions/Transactions";
import Resources from "src/containers/Affiliate/resources/Resources";
import Campaigns from "src/containers/Affiliate/campaigns/campaigns";
import AccountSettings from "src/containers/Affiliate/account-settings/AccountSettings";
import EmailVerification from "src/containers/Affiliate/email-verification/EmailVerification";
import AffiliateTermsAndConditions from "src/containers/Affiliate/affiliate-terms-and-conditions/AffiliateTermsAndConditions";
import Login from "src/containers/Affiliate/login/Login";

const affiliateRoutes = [
    {
        path: "",
        component: AffiliateLanding,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/terms-and-conditions",
        component: AffiliateTermsAndConditions,
    },
    {
        path: "/registration",
        component: Registration,
    },
    {
        path: "/verify-email",
        component: EmailVerification,
    },
    {
        path: "/dashboard",
        component: Dashboard,
    },
    {
        path: "/campaigns",
        component: Campaigns,
    },
    {
        path: "/commission-plans",
        component: CommissionPlans,
    },
    {
        path: "/referred-users",
        component: ReferredUsers,
    },
    {
        path: "/transactions",
        component: Transactions,
    },
    {
        path: "/resources",
        component: Resources,
    },
    {
        path: "/account-settings",
        component: AccountSettings,
    },
];

export default function Affiliate() {
    const match = useRouteMatch();

    return renderRoutes(affiliateRoutes.map(route => ({ ...route, exact: true, path: `${match.path}${route.path}` })));
}
