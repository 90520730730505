import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";

export const useGetChallenge = (walletAddress: string | undefined, {
    enabled = true, onGetChallengeStart,
}: { enabled?: boolean, onGetChallengeStart(): void }) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ["challenge", walletAddress],
        queryFn: async () => {
            onGetChallengeStart();
            return await axiosInstance.post<{ walletAddress: string }, string>(Endpoint.AuthChallenge, { walletAddress: walletAddress! })
        },
        enabled: !!walletAddress && enabled,
    })

    return { challenge: data || null, isLoading, isError };
}