import realTimeIcon from "src/affiliate/components/affiliate-landing/section-two/assets/Real-Time-Payouts.svg";
import highCommissionIcon from "src/affiliate/components/affiliate-landing/section-two/assets/High-Commissions.svg";
import noNegativeIcon from "src/affiliate/components/affiliate-landing/section-two/assets/No-Negative.svg";
import marketLeaderIcon from "src/affiliate/components/affiliate-landing/section-two/assets/Market-Leader.svg";
import dataTransparencyIcon from "src/affiliate/components/affiliate-landing/section-two/assets/Data-Transparency.svg";
import newExperienceIcon from "src/affiliate/components/affiliate-landing/section-two/assets/New-Experience.svg";

export const WHY_PARTNER_DATA = [
    {
        id: 0,
        title: "Real Time Payouts",
        img: realTimeIcon,
        description:
            "The one and only partnership where you can receive instant payouts in real-time as your referred audience play on Peergame.",
    },
    {
        id: 1,
        title: "High Commissions",
        img: highCommissionIcon,
        description:
            "The commission is based on the wager, not revenue. Even if your referees beat the house, you still get your share. ",
    },
    {
        id: 2,
        title: "No Negative Carryover",
        img: noNegativeIcon,
        description: "Again, commission is based on wager, not revenue. No negative carryover whatsoever. ",
    },
    {
        id: 3,
        title: "Market Leader",
        img: marketLeaderIcon,
        description: "Peergame is the first, and most trusted online casino built on Bitcoin SV. ",
    },
    {
        id: 4,
        title: "Data Transparency",
        img: dataTransparencyIcon,
        description:
            "All our partners can track their own progress through our detailed, immutable, real-time data provided through their own accounts. ",
    },
    {
        id: 5,
        title: "New Experience",
        img: newExperienceIcon,
        description:
            "We are the first in the world to provide a real-time wager share to partners. We are very confident that every partner will love it. ",
    },
];

export const COMMISSION_PLAN_DATA = [
    {
        id: 0,
        bsv: "0",
        percentage: "10%",
        height: "60px",
    },
    {
        id: 1,
        bsv: "10",
        percentage: "15%",
        height: "92px",
    },
    {
        id: 2,
        bsv: "100",
        percentage: "20%",
        height: "124px",
    },
    {
        id: 3,
        bsv: "500",
        percentage: "25%",
        height: "156px",
    },
    {
        id: 4,
        bsv: "1000",
        percentage: "30%",
        height: "188px",
    },
    {
        id: 5,
        bsv: "5000",
        percentage: "40%",
        height: "220px",
    },
];
