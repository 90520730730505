import { StyledTabHeader, StyledTabTitle } from "src/affiliate/components/Transactions/assets/tabs.styled";

interface ITabsProps {
    tabTitles: string[];
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
}

const Tabs = ({ tabTitles, selectedTab, setSelectedTab }: ITabsProps) => (
    <>
        <StyledTabHeader>
            {tabTitles.map(title => (
                <StyledTabTitle
                    visited={selectedTab === title}
                    key={title}
                    onClick={() => setSelectedTab(title)}
                    className="xlarge"
                >
                    {title}
                </StyledTabTitle>
            ))}
        </StyledTabHeader>
    </>
);

export default Tabs;
