const SET_TRACK = "game/audio/SET_TRACK";
const SET_MUTE = "game/audio/SET_MUTE";

export const TRACK = {
    _WAIT: "bgm-1.mp3",
    _MOVE: "racing-1.mp3",
};

export interface IAudioState {
    track: string | null;
    mute: {
        [key: string]: boolean;
    };
}

const initialState: IAudioState = {
    track: null,
    mute: {},
};

Object.values(TRACK).forEach(track => {
    initialState.mute[track] = false;
});

interface ISetTrackAction {
    type: typeof SET_TRACK;
    track: string;
}

interface ISetMuteAction {
    type: typeof SET_MUTE;
    mute: {
        [key: string]: boolean;
    };
}

type TAudioActionTypes = ISetTrackAction | ISetMuteAction;

export default function audio(state = initialState, action: TAudioActionTypes): IAudioState {
    switch (action.type) {
        case SET_TRACK:
            return {
                ...state,
                track: action.track,
            };

        case SET_MUTE:
            return {
                ...state,
                mute: {
                    ...state.mute,
                    ...action.mute,
                },
            };

        default:
            return state;
    }
}

// Define action creators
export function setTrack(track: string): ISetTrackAction {
    return {
        type: SET_TRACK,
        track,
    };
}

export function setMute(mute: { [key: string]: boolean }): ISetMuteAction {
    return {
        type: SET_MUTE,
        mute,
    };
}
