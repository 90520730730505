function protectedMail(email: string) {
    const splittedEmail = email.split("@");
    let char = "";
    const avg = splittedEmail[0].length / 2;
    const leftPart = splittedEmail[0].split("");

    for (let i = 0; i < leftPart.length; i += 1) {
        if (i >= Math.floor(avg)) {
            char += "*";
        } else {
            char += leftPart[i];
        }
    }

    return `${char}@${splittedEmail[1]}`;
}

export default protectedMail;
