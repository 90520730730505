import styled from "styled-components";
import { H3 } from "src/affiliate/styled/typography.styled";

export const StyledH3 = styled(H3)`
    margin: 40px 0 16px 0;

    @media only screen and (max-width: 568px) {
        margin: 24px 0 16px 0;
        font-size: 20px;
    }
`;

export const StyledP = styled.p`
    width: 382px;
    text-align: center;
    margin: 0;
    color: ${({ theme }) => theme.color.white};
    @media only screen and (max-width: 568px) {
        width: 312px;
    }
`;