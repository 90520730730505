import Button from "src/affiliate/components/reusable/Button";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { StyledH3, StyledP } from "src/affiliate/components/account-suspended/suspended.styled";

const Suspended = () => (
    <>
        <FlexContainer padding="60px 0 365px 0" justifyContent="center" mobilePadding="40px 0 429px 0">
            <FlexContainer flexDirection="column" alignItems="center">
                <StyledH3>Account Suspended</StyledH3>
                <StyledP>
                    Your partner account has been suspended due to suspicious activities. Please contact us to learn more.
                </StyledP>
                <FlexContainer
                    width="536px"
                    justifyContent="center"
                    mobileFlexDirection="column"
                    mobileHeight="116px"
                    mobileJustifyContent="center"
                    mobileAlignItems="center"
                >
                    <a href="mailto:contact@peergame.com" target="_blank" rel="noreferrer noopener">
                        <Button width="260px" buttonShape="bordered">
                            Email
                        </Button>
                    </a>
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    </>
);

export default Suspended;
