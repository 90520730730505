import { FC } from "react";
import { Button } from "src/components/shadcn-button/Button";
import { useAuthModalState } from "src/feature/auth/dialogs/AuthModal";

interface IErrorButtonsProps {
    onClose(): void
}

const ErrorButtons: FC<IErrorButtonsProps> = ({
    onClose,
}) => {
    const [_, setModalOpen] = useAuthModalState();

    const handleTryAgain = () => {
        onClose();
        setModalOpen(true);
    }


    return (
        <div className="flex flex-col gap-1.5 mt-auto -mb-2">
            <Button className="w-full text-md font-bold" onClick={handleTryAgain}>Try again</Button>
            <a href="mailto:contact@peergame.com" target="_blank" rel="noreferrer noopener">
                <Button className="w-full text-xs font-bold" variant="ghost">Contact support</Button>
            </a>
        </div>
    );
};

export default ErrorButtons;