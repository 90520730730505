import { BigNumber } from "bignumber.js";

// Points associated with card numbers
const points: { [key: string]: number } = {
    A: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    T: 0,
    J: 0,
    Q: 0,
    K: 0,
};

// Card patterns and numbers
const patterns = ["C", "D", "H", "S"];
const numbers = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K"];

class Card {
    pattern: string;
    number: string;

    constructor(pattern: string, number: string) {
        this.pattern = pattern;
        this.number = number;
    }

    toString(): string {
        return `${this.number}${this.pattern}`;
    }

    get point(): number {
        return points[this.number];
    }

    static get points() {
        return points;
    }

    static get patterns() {
        return patterns;
    }

    static get numbers() {
        return numbers;
    }
}

// Constants
export const deckCount = 6;

// Fisher-Yates shuffle algorithm
const fisherYates = (seed: BigNumber, initialDeck: Card[]): Card[] => {
    const shuffledDeck = [...initialDeck];

    for (let i = initialDeck.length - 1; i > 0; i -= 1) {
        const index = seed.mod(i + 1).toNumber();

        // Swap
        const tmp = shuffledDeck[index];
        shuffledDeck[index] = shuffledDeck[i];
        shuffledDeck[i] = tmp;
    }

    return shuffledDeck;
};

// Create initial deck
const getInitialDeck = (count: number): Card[] => {
    const deck: Card[] = [];
    for (let i = 0; i < count; i += 1) {
        for (const pattern of Card.patterns) {
            for (const number of Card.numbers) {
                deck.push(new Card(pattern, number));
            }
        }
    }
    return deck;
};

// Shuffle the deck
export const shuffle = (seed: string, count: number): Card[] => {
    BigNumber.config({ RANGE: 80 });
    const seedInteger = new BigNumber(seed, 16);
    return fisherYates(seedInteger, getInitialDeck(count));
};

// Baccarat Result Types
const BaccaratResult = Object.freeze({
    Player: "P",
    Banker: "B",
    Tie: "T",
    PlayerPair: "PP",
    BankerPair: "BP",
});

// Calculate points of the cards
const getPoint = (cards: Card[]): number => cards.reduce((accu, card) => accu + card.point, 0) % 10;

// Check if cards form a pair
const isPair = (areaCards: Card[]): boolean => areaCards[0].number === areaCards[1].number;

// Get the result of the game
const getResult = (players: Card[], bankers: Card[]): string[] => {
    const result: string[] = [];
    const playerPoints = getPoint(players);
    const bankerPoints = getPoint(bankers);

    if (playerPoints === bankerPoints) result.push(BaccaratResult.Tie);
    else if (playerPoints > bankerPoints) result.push(BaccaratResult.Player);
    else result.push(BaccaratResult.Banker);

    if (isPair(bankers)) result.push(BaccaratResult.BankerPair);
    if (isPair(players)) result.push(BaccaratResult.PlayerPair);

    return result;
};

// Check if either player or banker has a natural hand
const isNatural = (players: Card[], bankers: Card[]): boolean => getPoint(players) >= 8 || getPoint(bankers) >= 8;

// Check if player should draw more cards
const isPlayerMoreCard = (cards: Card[]): boolean => getPoint(cards) < 6;

// Check if banker should draw more cards
const isBankerMoreCard = (players: Card[], bankers: Card[]): boolean => {
    if (players.length === 2) return isPlayerMoreCard(bankers);
    if (getPoint(bankers) <= 2) return true;
    if (getPoint(bankers) === 3) return !["8"].includes(players[2].number);
    if (getPoint(bankers) === 4) return ["2", "3", "4", "5", "6", "7"].includes(players[2].number);
    if (getPoint(bankers) === 5) return ["4", "5", "6", "7"].includes(players[2].number);
    if (getPoint(bankers) === 6) return ["6", "7"].includes(players[2].number);
    return false;
};

// Get the game scenario
const getScenario = (players: Card[], bankers: Card[]): string => JSON.stringify({
    player: players.map(player => player.toString()).join(""),
    banker: bankers.map(banker => banker.toString()).join(""),
});

// Get the return of the game
const getReturn = (players: Card[], bankers: Card[]): [string, number, number, string[]] => [
    getScenario(players, bankers),
    getPoint(players),
    getPoint(bankers),
    getResult(players, bankers),
];

// Get the next game result
export const getNextResult = (cardsDecks: Card[]): [string, number, number, string[]] => {
    const players: Card[] = [];
    const bankers: Card[] = [];

    players.push(cardsDecks.shift()!);
    bankers.push(cardsDecks.shift()!);
    players.push(cardsDecks.shift()!);
    bankers.push(cardsDecks.shift()!);

    // Natural processing
    if (isNatural(players, bankers)) return getReturn(players, bankers);

    // Player additional card
    if (isPlayerMoreCard(players)) players.push(cardsDecks.shift()!);

    // Banker additional card
    if (isBankerMoreCard(players, bankers)) bankers.push(cardsDecks.shift()!);

    // Calculate result
    return getReturn(players, bankers);
};
