import classNames from "classnames/bind";
import InfiniteScroll from "react-infinite-scroller";
import styles from "./styles.scss";
import Loader from "src/components/loader3/Loader";
import { ReactNode, ComponentPropsWithoutRef } from "react";

export interface ITableColumn {
    label: string
    hideMobile?: boolean
    hide?: boolean
    className?: string
}

interface ITableWrapperProps {
    children: ReactNode;
    columns: ITableColumn[];
    className?: string;
    isLoading: boolean;
    infiniteScrollProps?: ComponentPropsWithoutRef<typeof InfiniteScroll>
    emptyPlaceholder?: string;
}

const cx = classNames.bind(styles);
export default function TableWrapper({
    className = "",
    columns,
    isLoading = false,
    children,
    infiniteScrollProps,
    emptyPlaceholder = "",
}: ITableWrapperProps) {
    function getBodyContent() {
        if (isLoading) {
            return (
                <tr className={cx("message")}>
                    <td colSpan={12}>
                        <Loader />
                    </td>
                </tr>
            );
        }
        if (emptyPlaceholder) {
            return (
                <tr className={cx("message")}>
                    <td colSpan={12}>{emptyPlaceholder}</td>
                </tr>
            );
        }

        return children;
    }

    return (
        <div className={cx("table-wrapper", className)}>
            {/* @ts-expect-error it never had this prop*/}
            <InfiniteScroll {...infiniteScrollProps} initialLoad={false} useWindow={false} element="table" threshold={400}>
                <thead>
                <tr className={cx("xsmall")}>
                    {columns.map(
                        ({
                            label,
                            hideMobile,
                            hide,
                            className,
                        }) => !hide && (
                            <th key={label.toLowerCase()} className={cx({ "hide-mobile": hideMobile }, className)}>
                                {label}
                            </th>
                        ),
                    )}
                </tr>
                </thead>
                <tbody>{getBodyContent()}</tbody>
            </InfiniteScroll>
        </div>
    );
}