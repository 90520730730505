/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import "core-js/stable";
import "regenerator-runtime/runtime";
import "lazysizes";

import "src/core/analytics/google-tag-manager";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { getStoredState } from "redux-persist";
import localForage from "localforage";
import routes, { IRouteWithComponent } from "src/routes";
import isOnline from "src/utils/isOnline";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./registerServiceWorker";
import Root from "src/containers/root/Root";
import { RootState } from "src/redux/reducer";

const persistConfig = {
    key: "hello",
    storage: localForage,
    stateReconciler(inboundState, originalState) {
        // Ignore state from cookies, only use preloadedState from window object
        return originalState;
    },
    whitelist: [],
};

Sentry.init({ dsn: process.env.SENTRY_DSN, environment: process.env.NODE_ENV });

const dest = document.getElementById("react-root")!;


const initializeApp = async () => {
    const preloadedState = (await getStoredState(persistConfig)) as Partial<RootState>;
    const online = window.__data ? true : await isOnline();

    const hydrate = (routes: IRouteWithComponent[]) => {
        const element = (
            <Root preloadedState={preloadedState} online={online} routes={routes} />
        );

        if (window.__PRERENDER_INJECTED && !window.__PRERENDER_INJECTED.injected) {
            hydrateRoot(dest, element);
        } else {
            const root = createRoot(dest);
            root.render(element);
        }
    }

    hydrate(routes);

    // Hot reload
    if ((module as any).hot) {
        (module as any).hot.accept("./routes", () => {
            const nextRoutes = require("src/routes");
            hydrate(nextRoutes.__esModule ? nextRoutes.default : nextRoutes);
        });
    }

    // Server-side rendering check
    if (process.env.NODE_ENV !== "production") {
        window.React = React; // enable debugger
    }
}

initializeApp();

// Service worker
serviceWorker.register({
    scope: "/",
});