import { Fragment, useState, memo, useEffect } from "react";
import classNames from "classnames/bind";
import ImgReload from "./reload.svg";
import styles from "./styles.scss";
import Button from "src/components/button/Button";
import { useGameStore } from "src/components/game/hooks/useGameStore";
import useSettings from "src/components/game/hooks/useSettings";
import useBet, { ISendDepositOrBetItem } from "src/games/hooks/use-bet";
import { IBetData } from "src/components/game/types/bet";

const cx = classNames.bind(styles);

interface IBetButtonProps {
    className: string;
    buttonText: string;
    onBetSuccess: (transactionData?: ISendDepositOrBetItem) => void;
    disabled?: boolean;
    hidden?: boolean;
    depositData?: IBetData;
}

const BetButton = ({
    className,
    buttonText,
    onBetSuccess,
    disabled = false,
    hidden = false,
    depositData,
}: IBetButtonProps) => {
    const isDeposit = !!depositData;
    const storeData = useGameStore(state => state.bettingData)
    const bettingData = depositData || storeData;
    const { isLoading: settingsLoading } = useSettings({ enabled: !isDeposit });
    const [moneyButtonLoaded, setMoneyButtonLoaded] = useState(false);
    const isRealMode = useGameStore(state => state.isRealMode);
    const bettingDisabled = useGameStore(state => state.bettingDisabled);
    const {
        placeBet,
        isLoading,
        error,
        errorModals,
    } = useBet({ bettingData, onBetSuccess, isDeposit });

    useEffect(() => {
        if (!settingsLoading && moneyButtonLoaded) {
            useGameStore.getState().setBettingDisabled(false);
        }
    }, [settingsLoading, moneyButtonLoaded]);

    function onBet() {
        useGameStore.getState().setBettingDisabled(true);
        if (isRealMode || isDeposit) {
            placeBet();
        } else {
            onBetSuccess();
        }
    }

    // don't render anything if hidden is true
    if (hidden) {
        return null;
    }

    return (
        <Fragment>
            <div className={cx("button-container")}>
                {error ? (
                    <span className={cx("bold", "error-placeholder")}>
                        {error.code === "__too_long_mempool_chain__" ? "Speed Limit" : "Error"}
                    </span>
                ) : (
                    <Button
                        className={cx("bold", "bet-button", className)}
                        onClick={onBet}
                        isDisabled={disabled || isLoading || (!isDeposit && bettingDisabled)}
                        isLoading={isLoading}
                    >
                        {buttonText}
                    </Button>
                )}
                {error && (
                    <button className={cx("retry-button")} type="button" disabled={isLoading} onClick={() => placeBet()}>
                        <img src={ImgReload} alt="" />
                    </button>
                )}
            </div>
            {errorModals}
        </Fragment>
    );
}

export default memo(BetButton);
