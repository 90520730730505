import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import { useMobileOnly } from "src/helpers/responsive";
import RacesBanner from "src/containers/Landing/BannerGrid/RacesBanner";
import PartnersBanner from "src/containers/Landing/BannerGrid/PartnersBanner";
import PromotionsBanner from "src/containers/Landing/BannerGrid/PromotionsBanner";

const cx = classNames.bind(styles);

export default function BannerGrid() {
    const isMobileOnly = useMobileOnly();

    return (
        <div className={cx("banner-grid")}>
            <RacesBanner />
            <PartnersBanner isMobileOnly={isMobileOnly} />
            <PromotionsBanner isMobileOnly={isMobileOnly} />
        </div>
    );
}
