import { Link } from "react-router-dom";
import CheckBox from "src/affiliate/components/reusable/CheckBox";
import Button from "src/affiliate/components/reusable/Button";
import { Caption } from "src/affiliate/styled/typography.styled";
import { StyledFrom, StyledH3, StyledAnchor } from "src/affiliate/components/registration/assets/styles/registration-form.styled";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import Input from "src/affiliate/components/reusable/Input";
import { IRegistrationFormValues } from "src/containers/Affiliate/registration/types";
import { UseFormHandleSubmit, UseFormRegister, GlobalError } from "react-hook-form";

interface IRegistrationFormProps {
    register: UseFormRegister<IRegistrationFormValues>;
    errors: Record<string, GlobalError>;
    handleSubmit: UseFormHandleSubmit<IRegistrationFormValues, undefined>;
    onSubmit: (registrationData: IRegistrationFormValues) => void;
    handleOnChange: () => void;
    checked: boolean;
    isValid: boolean;
    serverError: string;
}

const RegistrationForm = ({
    register,
    handleSubmit,
    errors,
    isValid,
    onSubmit,
    checked,
    handleOnChange,
    serverError,
}: IRegistrationFormProps) => (
    <FlexContainer flexDirection="column" alignItems="center">
        <StyledH3>Peergame Partners Registration</StyledH3>
        <StyledFrom onSubmit={handleSubmit(onSubmit)}>
            <FlexItem margin="0 0 26px 0" width="100%" mobileMargin="0 0 26px 0">
                <Input {...register("name")} inputType="text" label="Name" error={errors} modalInputActive />
            </FlexItem>
            <FlexItem margin="0 0 26px 0" width="100%" mobileMargin="0 0 26px 0">
                <Input
                    {...register("email")}
                    inputType="email"
                    label="Email"
                    error={errors}
                    modalInputActive
                    serverError={serverError}
                />
            </FlexItem>
            <FlexItem margin="0 0 26px 0" width="100%" mobileMargin="0 0 26px 0">
                <Input
                    {...register("sot")}
                    label="Source of Traffic ( Website, Blog, Social Media )"
                    error={errors}
                    inputType="text"
                    modalInputActive
                />
            </FlexItem>
            <FlexContainer width="100%" padding="0 0 33px 0">
                <CheckBox name="terms" checked={checked} onChange={handleOnChange}>
                    <Caption padding="0 0 0 8px" margin="0">
                        I have read and agree with{" "}
                        <StyledAnchor as={Link} to="terms-and-conditions" className="bold">
                            terms and conditions
                        </StyledAnchor>{" "}
                        &{" "}
                        <StyledAnchor as={Link} to="/privacy-policy" className="bold">
                            privacy policy
                        </StyledAnchor>
                    </Caption>
                </CheckBox>
            </FlexContainer>
            <Button type="submit" disabled={!isValid || !checked || !!serverError} className="bold">
                JOIN NOW
            </Button>
        </StyledFrom>
    </FlexContainer>
);

export default RegistrationForm;
