import React, { memo } from "react";
import { ToastContainer } from "react-toastify";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import IconClose from "src/components/modal/assets/close.svg?inline";

interface IToastProps {
    closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const AUTO_CLOSE_TIME_IN_MS = 5000;

const cx = classNames.bind(styles);

function CloseButton({ closeToast }: IToastProps) {
    return (
        <button type="button" onClick={closeToast}>
            <IconClose />
        </button>
    );
}

const Toast = memo(() => {
    return (
        <ToastContainer
            hideProgressBar
            className={cx("toast-container")}
            position="top-right"
            autoClose={AUTO_CLOSE_TIME_IN_MS}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            closeButton={({ closeToast }) => <CloseButton closeToast={closeToast} />}
        />
    );
});

Toast.displayName = "Toast";

export default Toast;