import React, { useMemo } from "react";
import classNames from "classnames/bind";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.scss";

interface IInputProps {
    value: string;
    label?: string;
    error?: string;
    success?: string;
    placeholder?: string;
    onChange: (value: string | number) => void;
    className?: string;
}

const cx = classNames.bind(styles);

export default function Input({
    value = "",
    label = "",
    error = "",
    success = "",
    className = "",
    placeholder = "",
    onChange,
}: IInputProps) {
    const inputId = useMemo(() => uuidv4(), []);

    return (
        <div className={`${cx("container")} ${className}`}>
            {label && (
                <label htmlFor={inputId} className={cx("label", "small")}>
                    {label}
                </label>
            )}
            <input
                id={inputId}
                value={value}
                placeholder={placeholder}
                className={cx("input", { error, success })}
                onChange={e => onChange(e.target.value)}
            />
            <span className={cx({ error, success }, "xsmall")}>{error || success}&nbsp;</span>
        </div>
    );
}