import { useState } from "react";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import Modal from "src/components/modal/Modal";

import IconInfo from "./assets/images/info.svg?inline";

const cx = classNames.bind(styles);

export default function RaceInfo() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button type="button" className={cx("race-info")} onClick={() => setIsOpen(true)}>
                <IconInfo />
                Race Info
            </button>
            <Modal isOpen={isOpen} header="Peergame Races" onClose={() => setIsOpen(false)}>
                <div className={cx("modal-body")}>
                    <p className={cx("small")}>
                        Simply play in any of the games involved to join the race. The top 10 players with the highest points will
                        win prizes every day.
                    </p>
                    <h3>PG Points</h3>
                    <p className={cx("small")}>Points are based on two components:</p>
                    <ol className={cx("small")}>
                        <li>Your overall profit</li>
                        <li>
                            Sum of winning bet's (&#123;multipliers - 1&#125; x &#123;wagered amount:max 0.01&#125; x &#123;100&#125;)
                        </li>
                    </ol>
                    <p className={cx("small")}>
                        Example: You made 3 bets, all with 1 BSV and 2x multiplier on Dice. You won twice and lost once. Points =
                        (1) x [&#123;(2-1) x (0.01) x (100)&#125; + &#123;(2-1) x (0.01) x (100)&#125;] x 100 = 200
                    </p>

                    <h3>Games</h3>
                    <div className={cx("game-badges")}>
                        <span>Dice</span>
                        <span>Coin Flip</span>
                        <span>Wheel</span>
                        <span>Roulette</span>
                        <span>Blackjack</span>
                    </div>

                    <h3>Duration</h3>
                    <p>Peergame Daily Race resets every day at 9:00am (GMT)</p>
                    <p>Peergame Weekly Race resets every week on Tuesday at 9:00am (GMT)</p>
                </div>
            </Modal>
        </>
    );
}
