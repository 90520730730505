import { StyledTitle, StyledCaption } from "src/affiliate/components/affiliate-landing/section-two/assets/styles/section-two-card.styled";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";

interface ISectionTwoCardProps {
    title: string;
    description: string;
    img: string;
}

const SectionTwoCard = ({ title, description, img }: ISectionTwoCardProps) => (
    <>
        <FlexContainer flexDirection="column">
            <FlexContainer alignItems="center">
                <FlexItem margin="0 16px 0 0" mobileMargin="0 16px 0 0">
                    <img src={img} alt="section_image" />
                </FlexItem>
                <StyledTitle>{title}</StyledTitle>
            </FlexContainer>
            <StyledCaption>{description}</StyledCaption>
        </FlexContainer>
    </>
);

export default SectionTwoCard;
