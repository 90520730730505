import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledFlexContainer = styled(FlexContainer)`
    width: 100%;
    position: relative;
    border-radius: 5px 5px 0 0;
    background-image: linear-gradient(to top, #1a0949, #22096c);
`;

export const StyledSpan = styled.span`
    color: ${({ theme }) => theme.color.white};
`;

export const StyledProgressBarContainer = styled.div`
    width: 100%;
    height: 16px;
    position: relative;
    margin: 8px 0 0 0;
    border-radius: 10px;
    box-shadow: inset 0 2px 9px 0 rgba(0, 0, 0, 0.7);
    background-color: #22096c;
`;
export const StyledProgressLoader = styled.div<{ loaderWidth: number; }>`
    width: ${({ loaderWidth }) => loaderWidth}%;
    height: 16px;
    position: absolute;
    top: 0;
    border-radius: 10px;
    background-color: #ff2976;
`;

export const StyledImageContainer = styled.div<{
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    width?: string;
}>`
    position: absolute;
    top: ${({ top }) => top};
    right: ${({ right }) => right};
    bottom: ${({ bottom }) => bottom};
    left: ${({ left }) => left};

    img {
        width: ${({ width }) => width};
    }
`;