import { FocusEvent, ChangeEvent, KeyboardEvent, ReactNode, forwardRef } from "react";
import { InputProps, Input } from "src/components/shadcn-input/Input";
import { useStateRef, usePrevious, usePassedRef } from "@ntropy/hooks";
import { isNumeric, asNumberOrNull } from "@ntropy/utils/src/math-utils";
import { isSet } from "@ntropy/utils/src/type-utils";
import { Button } from "src/components/shadcn-button/Button";
import cn from "classnames";
import { ChevronDown, ChevronUp } from "lucide-react"
import BigNumber from "bignumber.js";
import { CRYPTO_DECIMAL } from "src/core/currency/currency.const";

interface INumericInputProps extends Omit<InputProps, "value" | "defaultValue" | "onChange" | "pattern" | "type" | "max" | "min" | "step"> {
    value?: number | null
    defaultValue?: number | null
    onChange?(value: number | null): void
    withMaxButton?: boolean
    min?: number
    max?: number
    /** Use to handle value rounding, but also `step` – if not provided separately
     * @default CRYPTO_DECIMAL
     * */
    decimal?: number
    step?: number
    suffix?: ReactNode
}

const stringifyValue = (value: number | null | undefined) => isSet(value) ? new BigNumber(value).toFixed() : "";
const getStepFromDecimal = (decimal: number | null | undefined) => {
    if (!isSet(decimal)) return null;

    return 1 / Math.pow(10, decimal);
}

const NumericInput = forwardRef<HTMLInputElement, INumericInputProps>(({
    value,
    defaultValue,
    onChange,
    onBlur,
    withMaxButton,
    max,
    decimal,
    step = getStepFromDecimal(decimal) ?? 1,
    onKeyDown,
    suffix,
    ...props
}, ref) => {
    const inputRef = usePassedRef(ref);
    const [valueString, setValueString, valueStringRef] = useStateRef(stringifyValue(value));

    const prevValue = usePrevious(value);

    if (prevValue !== value && stringifyValue(value) !== valueStringRef.current) {
        setValueString(stringifyValue(value))
    }

    const handleChange = (value: string) => {
        let valueString = value;

        if (valueString.includes("")) {
            valueString = valueString.replaceAll(" ", "");
        }

        if (valueString !== valueStringRef.current) {
            setValueString(valueString);
        }

        if (valueString.trim() === "") {
            onChange?.(null)
        } else if (isNumeric(valueString)) {
            onChange?.(asNumberOrNull(valueString))
        }
    }

    const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
        const valueString = event.target.value;

        handleChange(valueString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        onBlur?.(event);

        if (!onChange || asNumberOrNull(valueString) === value) {
            return;
        }

        if (valueString.startsWith(".") && isNumeric(`0${valueString}`)) {
            onChange?.(asNumberOrNull(`0${valueString}`));
            return;
        }

        onChange?.(isNumeric(value) ? asNumberOrNull(value) : null);
    }

    const usingMaxButton = withMaxButton && isSet(max) && max !== Infinity;

    const handleMaxClick = () => {
        if (!usingMaxButton) {
            return;
        }

        handleChange(stringifyValue(max));
    }

    const handleStep = (direction: 1 | -1) => {
        let newValue = asNumberOrNull(valueString) ?? 0;
        newValue += direction * step;

        newValue = Number(newValue.toFixed(decimal ?? CRYPTO_DECIMAL));

        console.log("handleStep", { valueString, step, decimal, newValue })
        handleChange(stringifyValue(newValue));
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        onKeyDown?.(event);

        switch (event.key) {
            case "ArrowUp":
                handleStep(+1);
                return;
            case "ArrowDown":
                handleStep(-1);
                return;
        }
    }

    return (
        <div className="relative flex">
            <Input
                ref={inputRef}
                value={valueString}
                onChange={handleChangeEvent}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                type="tel"
                pattern="-?[0-9]*([.,][0-9]{1,8})?"
                {...props}
            />
            <div
                className={cn("absolute grid grid-rows-2 grid-cols-2 h-full place-items-center py-1", {
                    "right-0": !usingMaxButton,
                    "right-[56px]": usingMaxButton,
                })}
            >
                {!!suffix && <div className="row-span-2 min-w-8 px-1 mr-0.5">{suffix}</div>}
                <ChevronUp className="cursor-pointer px-2 py-1 w-8 opacity-50 hover:opacity-100 transition-opacity" onClick={() => handleStep(+1)} />
                <ChevronDown className="cursor-pointer px-2 py-1 w-8 opacity-50 hover:opacity-100" onClick={() => handleStep(-1)} />
            </div>
            {usingMaxButton &&
                <Button type="button" variant="brand-300" onClick={handleMaxClick} className="absolute right-0 rounded-l-none text-xs font-[700] select-none">
                    Max
                </Button>
            }
        </div>
    );
});

NumericInput.displayName = "NumericInput";

export default NumericInput;