import classNames from "classnames/bind";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import styles from "./assets/styles.scss";
import Dropdown from "src/components/dropdown/Dropdown";
import HistoryTable from "src/containers/Account/History/HistoryTable";
import { games } from "src/games/config";

const cx = classNames.bind(styles);

const betStatusOptions = [
    { label: "All Transactions", value: "" },
    { label: "Confirmed Bets", value: "completed" },
    { label: "Pending Bets", value: "pending" },
    { label: "Failed Bets", value: "fail" },
];

const gameOptions = Object.entries(games).map(([gameCode, { label }]) => ({ label, value: gameCode }));

const History = () => {
    const [betStatus, setBetStatus] = useQueryParam("betStatus", withDefault(StringParam, betStatusOptions[0].value));
    const [gameCode, setGameCode] = useQueryParam("gameCode", withDefault(StringParam, "dice"));

    return (
        <>
            <div className={cx("header")}>
                <h3>Game History</h3>
                <div className={cx("filters")}>
                    <Dropdown
                        items={betStatusOptions}
                        selectedValue={betStatus}
                        onChange={(newValue: string) => setBetStatus(newValue)}
                    />
                    <Dropdown
                        items={gameOptions}
                        selectedValue={gameCode}
                        onChange={(newValue: string) => setGameCode(newValue)}
                    />
                </div>
            </div>
            <HistoryTable gameCode={gameCode} betStatus={betStatus} />
        </>
    );
};

export default History;
