import { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import Form from "src/affiliate/components/account-settings/Form";
import { Caption } from "src/affiliate/styled/typography.styled";
import DropDown from "src/affiliate/components/reusable/drop-down/DropDown";
import useDropdown from "src/affiliate/hooks/use-dropdown";
import useFetch from "src/helpers/useFetch";
import { updateSettings } from "src/core/affiliate/affiliate.reducer";
import Layout from "src/affiliate/components/Layout";
import DashBoardLayout from "src/affiliate/components/reusable/DashBoardLayout";
import { StyledFlexContainer, StyledToggleLabel, StyledTitle } from "src/containers/Affiliate/account-settings/assets/styles/account-settings.styled";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import Toggle from "src/affiliate/components/reusable/Toggle";
import { useAppSelector, useAppDispatch } from "src/redux/reducer";
import Loader from "src/components/loader/Loader";
import BigNumber from "bignumber.js";
import { formatCrypto } from "src/core/currency/currency.function";

interface IPayoutLimitItem {
    name: string;
    value: string;
}

const AccountSettings = () => {
    const {
        value,
        trigger,
        setTrigger,
        setValue,
    } = useDropdown({ name: "", value: "" });
    const [dropDownList, setDropDownList] = useState<IPayoutLimitItem[]>([]);
    const [reportValue, setReportValue] = useState<boolean>(false);
    const {
        name,
        email,
        sot,
        commission_payout_address: commissionPayoutAddress,
        report_enable: reportEnable,
        payout_limit: payoutLimit,
        loading,
    } = useAppSelector(state => state.affiliate);
    const dispatch = useAppDispatch();
    const { data: payoutLimitList, loaded: payoutLimitListLoaded } = useFetch(
        "/api/v1/affiliate/account/payoutlimit/option",
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { payout_limit: payoutLimitDropDownList }: { payout_limit: string[] } = payoutLimitListLoaded ? payoutLimitList : [];

    useEffect(() => {
        setReportValue(() => reportEnable === "Y");
        setValue(prev => ({ ...prev, name: `${(payoutLimit || 0)?.toFixed(6)} BSV`, value: payoutLimit }));
        setDropDownList(() => payoutLimitDropDownList?.map(item => ({ name: `${formatCrypto(new BigNumber(item))} BSV`, value: item })));
    }, [loading, payoutLimit, payoutLimitListLoaded]);

    const listItems = useMemo(() => {
        let list: IPayoutLimitItem[] = [];
        if (dropDownList) {
            dropDownList.forEach(item => {
                list = [...list, { name: item.name, value: item.value }];
            });
        }

        return list;
    }, [dropDownList]);

    const onChange = (toggle: boolean) => {
        setReportValue(prev => !prev);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        dispatch(updateSettings({ report_enable: !toggle ? "Y" : "N" }));
    };

    const onClickDropDownHandler = (item: IPayoutLimitItem) => {
        setValue({ name: "", value: 1 });
        setTrigger(false);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        dispatch(updateSettings({ payout_limit: item.value }));
    };

    return (
        <>
            <Helmet>
                <title>Account settings</title>
                <meta
                    property="og:title"
                    content="Peergame Partners - Partner up with the highest-paid commission casino affiliate program."
                />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
                />
            </Helmet>
            <Layout sideNav transparent>
                {loading || !payoutLimitListLoaded ? (
                    <Loader />
                ) : (
                    <DashBoardLayout title="Account Settings" mobilePadding="22px 0 450px 0">
                        <StyledFlexContainer flexDirection="column" margin="24px 0 8px 0" padding="32px" mobilePadding="16px">
                            <Form name={name} email={email || ""} sot={sot} commissionPayoutAddress={commissionPayoutAddress} />
                            <FlexContainer alignItems="center">
                                <StyledToggleLabel className="xsmall">Weekly Report Emails</StyledToggleLabel>
                                <Toggle toggle={reportValue} onChange={onChange} />
                            </FlexContainer>
                        </StyledFlexContainer>

                        <StyledFlexContainer flexDirection="column" padding="32px">
                            <StyledTitle>Payout Limit</StyledTitle>
                            <Caption>
                                You can select when you want to get paid your commissions automatically. We recommend 0.01000 BSV to
                                prevent dust transactions
                            </Caption>
                            <FlexItem margin="16px 0 0 0" mobileMargin="24px 0 0 0" width="220px">
                                <DropDown
                                    listItems={listItems}
                                    value={value}
                                    onClickHandler={onClickDropDownHandler}
                                    trigger={trigger}
                                    setTrigger={setTrigger}
                                />
                            </FlexItem>
                        </StyledFlexContainer>
                    </DashBoardLayout>
                )}
            </Layout>
        </>
    );
};

export default AccountSettings;
