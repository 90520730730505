import { memo, useRef } from "react";
import copyIcon from "./assets/Copy.svg";
import toastNotify from "src/affiliate/utils/toastNotify";
import { StyledContainer, StyledInput, StyledButton } from "src/affiliate/components/reusable/copy-clip/assets/styles/copy-clip.styled";

interface ICopyClipProps {
    link: string;
}

const CopyClip = ({ link }: ICopyClipProps) => {
    const input = useRef<HTMLInputElement | null>(null);
    const onClickHandler = () => {
        input?.current?.select();
        document.execCommand("copy");
        toastNotify("Copied to Clipboard", 2000);
    };

    return (
        <StyledContainer>
            <StyledInput className="small" value={link} readOnly ref={input} />
            <StyledButton onClick={onClickHandler}>
                <img src={copyIcon} alt="Copy" />
            </StyledButton>
        </StyledContainer>
    );
};

export default memo(CopyClip);
