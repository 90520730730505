import classNames from "classnames/bind";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./assets/styles.scss";

import "swiper/swiper.scss";

import IconTestimonials from "./assets/images/testimonials-icon.svg?inline";
import IconQuote from "./assets/images/quote-icon.svg?inline";
import { useDesktopLargeOnly } from "src/helpers/responsive";
import { testimonials } from "src/containers/Landing/constants/testimonials";

const cx = classNames.bind(styles);

export default function Testimonials() {
    const isDesktopLargeOnly = useDesktopLargeOnly();

    return (
        <>
            <div className={cx("icon-heading")}>
                <IconTestimonials />
                <h3>TESTIMONIALS</h3>
            </div>
            <Swiper
                className={cx("testimonials")}
                spaceBetween={16}
                slidesPerView="auto"
                simulateTouch
                allowTouchMove={!isDesktopLargeOnly}
            >
                {testimonials.map(({
                    title, text, authorName, authorSource,
                }) => (
                    <SwiperSlide key={title.toLowerCase()} className={cx("card")}>
                        <IconQuote />
                        <h5>{title}</h5>
                        <p className={cx("small")}>{text}</p>
                        <span className={cx("small", "bold")}>{authorName}</span>
                        <br />
                        <span className={cx("small")}>{authorSource}</span>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}
