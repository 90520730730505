import styled from "styled-components";

export const StyledContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const StyledInput = styled.input`
    width: 100%;
    height: 38px;
    border-radius: 4px;
    border: solid 1px rgba(199, 186, 241, 0.2);
    background-color: rgba(34, 9, 108, 0.2);
    color: #c7baf1;
    padding: 5px 0 5px 10px;

    &:focus {
        outline: none;
    }
`;

export const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    border-radius: 4px;
    background-color: #08a57f;
    padding: 7px 0 7px 0;
    cursor: copy;
    position: absolute;
    right: 4px;
    top: 4px;

    img {
        width: 15px;
    }
`;