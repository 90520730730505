import { WHY_PARTNER_DATA } from "src/affiliate/components/affiliate-landing/constant/constant";
import { StyledTitle, StyledFlexContainer } from "src/affiliate/components/affiliate-landing/section-two/assets/styles/section-two-main.styled";
import SectionTwoCard from "src/affiliate/components/affiliate-landing/section-two/SectionTwoCard";
import { FlexItem, FlexContainer } from "src/affiliate/styled/layout.styled";

const SectionTwoMain = () => (
    <StyledFlexContainer
        flexDirection="column"
        padding="120px 0"
        tabletPadding="60px 60px"
        mobilePadding="60px 24px"
        alignItems="center"
    >
        <FlexContainer justifyContent="center" margin="0 0 80px 0" tabletMargin="0 0 60px 0" mobileMargin="0 0 32px 0">
            <StyledTitle>WHY PARTNER UP WITH PEERGAME?</StyledTitle>
        </FlexContainer>
        <FlexContainer maxWidth="900px" justifyContent="space-between" flexWrap tabletFlexWrap mobileFlexWrap>
            {WHY_PARTNER_DATA.map(({
                id, title, description, img,
            }) => (
                <FlexItem key={id} width="46%" mobileWidth="100%" margin="0 0 60px 0" mobileMargin="0 0 32px 0">
                    <SectionTwoCard title={title} description={description} img={img} />
                </FlexItem>
            ))}
        </FlexContainer>
    </StyledFlexContainer>
);

export default SectionTwoMain;
