import { toast } from "react-toastify";
import classNames from "classnames/bind";
import styles from "src/components/toast/assets/styles.scss";

import IconSuccess from "src/components/toast/assets/success.svg?inline";
import IconAlert from "src/components/toast/assets/alert.svg?inline";

const cx = classNames.bind(styles);

export const rewardToast = message => toast(message, { className: cx("reward"), hideProgressBar: false });
export const successToast = message => toast(
    <>
        <IconSuccess />
        <span>{message}</span>
    </>,
    { className: cx("success"), closeButton: false },
);
export const errorToast = message => toast(
    <>
        <IconAlert />
        <span>{message}</span>
    </>,
    { className: cx("error"), closeButton: false },
);
export const warningToast = message => toast(
    <>
        <IconAlert />
        <span>{message}</span>
    </>,
    { className: cx("warning"), closeButton: false },
);
