import { Dialog, DialogContent, DialogTrigger } from "src/components/dialog/Dialog";
import { ReactElement } from "react";
import { WalletBalanceView } from "src/feature/wallet/components/wallet-balance-view/WalletBalanceView";
import { DepositView } from "src/feature/wallet/components/deposit-view/DepositView";
import { WithdrawalView } from "src/feature/wallet/components/withdrawal-view/WithdrawalView";
import WalletButton from "src/feature/wallet/components/wallet-button/WalletButton";
import IconWallet from "src/feature/auth/assets/images/wallet.svg?inline";

import { useWalletDialogContentParam, DialogContentType } from "src/feature/wallet/hooks/useWalletDialogContentParam";
import Breadcrumbs from "src/feature/wallet/components/breadcrumbs/Breadcrumbs";
import { useXSmallOnly } from "src/helpers/responsive";


export const WalletDialog = () => {
    const {walletDialog, setWalletDialog, handleOpenChange} = useWalletDialogContentParam();
    const isXSmall = useXSmallOnly();

    let content: ReactElement | null = null;

    switch (walletDialog) {
        case DialogContentType.Wallet:
            content = <WalletBalanceView setWalletDialog={setWalletDialog} />;
            break;

        case DialogContentType.Deposit:
            content = <DepositView />;
            break;

        case DialogContentType.Withdrawal:
            content = <WithdrawalView />;
            break;
    }

    return (
        <Dialog open={!!walletDialog} onOpenChange={handleOpenChange}>
            <DialogTrigger asChild>
                <WalletButton className={isXSmall ? "p-2" : undefined}>
                    {isXSmall ? <IconWallet className="w-8" /> : "Wallet"}
                </WalletButton>
            </DialogTrigger>

            <DialogContent className="max-w-xl max-h-full md:max-h-[80%]">
                <Breadcrumbs walletDialog={walletDialog} setWalletDialog={setWalletDialog} />
                {content}
            </DialogContent>
        </Dialog>
    )
}