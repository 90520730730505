import styled from "styled-components";

interface IFlexContainerProps {
    position?: string;
    width?: string;
    maxWidth?: string;
    height?: string;
    flexDirection?: string;
    justifyContent?: string;
    alignItems?: string;
    flexWrap?: boolean;
    flexGrow?: boolean;
    padding?: string;
    margin?: string;
    cursor?: string;
    tabletWidth?: string;
    tabletPadding?: string;
    tabletFlexDirection?: string;
    tabletJustifyContent?: string;
    tabletAlignItems?: string;
    tabletMargin?: string;
    tabletHeight?: string;
    tabletFlexWrap?: boolean;
    tabletDisplay?: string;
    mobileWidth?: string;
    mobileHeight?: string;
    mobilePadding?: string;
    mobileMargin?: string;
    mobileFlexDirection?: string;
    mobileJustifyContent?: string;
    mobileAlignItems?: string;
    mobileDisplay?: string;
    mobileFlexWrap?: boolean;
}

// eslint-disable-next-line max-len
const styledContainerProps: (keyof IFlexContainerProps)[] = ["position", "width", "maxWidth", "height", "flexDirection", "justifyContent", "alignItems", "flexWrap", "flexGrow", "padding", "margin", "cursor", "tabletWidth", "tabletPadding", "tabletFlexDirection", "tabletJustifyContent", "tabletAlignItems", "tabletMargin", "tabletHeight", "tabletFlexWrap", "tabletDisplay", "mobileWidth", "mobileHeight", "mobilePadding", "mobileMargin", "mobileFlexDirection", "mobileJustifyContent", "mobileAlignItems", "mobileDisplay", "mobileFlexWrap"];

interface IFlexItemProps {
    flex?: string;
    margin?: string;
    padding?: string;
    width?: string;
    cursor?: string;
    tabletWidth?: string;
    tabletPadding?: string;
    tabletFlexDirection?: string;
    tabletJustifyContent?: string;
    tabletAlignItems?: string;
    tabletMargin?: string;
    mobileWidth?: string;
    mobilePadding?: string;
    mobileFlexDirection?: string;
    mobileJustifyContent?: string;
    mobileMargin?: string;
    mobileDisplay?: string;
}

// eslint-disable-next-line max-len
const styledItemProps: (keyof IFlexItemProps)[] = ["flex", "margin", "padding", "width", "cursor", "tabletWidth", "tabletPadding", "tabletFlexDirection", "tabletJustifyContent", "tabletAlignItems", "tabletMargin", "mobileWidth", "mobilePadding", "mobileFlexDirection", "mobileJustifyContent", "mobileMargin", "mobileDisplay"];

export const FlexContainer = styled("div").withConfig({
    shouldForwardProp: (prop: any) => !styledContainerProps.includes(prop),
})<IFlexContainerProps>`
    position: ${({ position }) => position};
    display: flex;
    width: ${({ width }) => width};
    max-width: ${({ maxWidth }) => maxWidth};
    height: ${({ height }) => height};
    flex-direction: ${({ flexDirection }) => flexDirection || "row"};
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
    flex-wrap: ${({ flexWrap }) => (flexWrap ? "wrap" : "nowrap")};
    flex-grow: ${({ flexGrow }) => (flexGrow ? 1 : "initial")};
    padding: ${({ padding }) => padding};
    margin: ${({ margin }) => margin};
    cursor: ${({ cursor }) => cursor};
    @media only screen and (max-width: 1157px) {
        width: ${({ tabletWidth }) => tabletWidth};
        padding: ${({ tabletPadding }) => tabletPadding};
        flex-direction: ${({ tabletFlexDirection }) => tabletFlexDirection};
        justify-content: ${({ tabletJustifyContent }) => tabletJustifyContent};
        align-items: ${({ tabletAlignItems }) => tabletAlignItems};
        margin: ${({ tabletMargin }) => tabletMargin};
        height: ${({ tabletHeight }) => tabletHeight};
        flex-wrap: ${({ tabletFlexWrap }) => (tabletFlexWrap ? "wrap" : "nowrap")};
        display: ${({ tabletDisplay }) => tabletDisplay};
    }

    @media only screen and (max-width: 568px) {
        width: ${({ mobileWidth }) => mobileWidth};
        height: ${({ mobileHeight }) => mobileHeight};
        padding: ${({ mobilePadding }) => mobilePadding};
        margin: ${({ mobileMargin }) => mobileMargin};
        flex-direction: ${({ mobileFlexDirection }) => mobileFlexDirection};
        justify-content: ${({ mobileJustifyContent }) => mobileJustifyContent};
        align-items: ${({ mobileAlignItems }) => mobileAlignItems};
        display: ${({ mobileDisplay }) => mobileDisplay};
        flex-wrap: ${({ mobileFlexWrap }) => (mobileFlexWrap ? "wrap" : "nowrap")};
    }
`;

export const FlexItem = styled("div").withConfig({
    shouldForwardProp: (prop: any) => !styledItemProps.includes(prop),
})<IFlexItemProps>`
    flex: ${({ flex }) => flex};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    width: ${({ width }) => width};
    cursor: ${({ cursor }) => cursor};

    @media only screen and (max-width: 1157px) {
        width: ${({ tabletWidth }) => tabletWidth};
        padding: ${({ tabletPadding }) => tabletPadding};
        flex-direction: ${({ tabletFlexDirection }) => tabletFlexDirection};
        justify-content: ${({ tabletJustifyContent }) => tabletJustifyContent};
        align-items: ${({ tabletAlignItems }) => tabletAlignItems};
        margin: ${({ tabletMargin }) => tabletMargin};
    }

    @media only screen and (max-width: 568px) {
        width: ${({ mobileWidth }) => mobileWidth};
        padding: ${({ mobilePadding }) => mobilePadding};
        flex-direction: ${({ mobileFlexDirection }) => mobileFlexDirection};
        justify-content: ${({ mobileJustifyContent }) => mobileJustifyContent};
        margin: ${({ mobileMargin }) => mobileMargin};
        display: ${({ mobileDisplay }) => mobileDisplay};
        margin: ${({ mobileMargin }) => mobileMargin || 0};
    }
`;
