import { ReactElement } from "react";

import IconMyAccount from "./assets/images/myaccount-icon.svg?inline";
import IconHistory from "./assets/images/history-icon.svg?inline";
import IconTransactions from "./assets/images/transactions-icon.svg?inline";
import MyAccount from "src/containers/Account/MyAccount/MyAccount";
import History from "src/containers/Account/History/History";
import Transactions from "src/containers/Account/Transactions/Transactions";

interface IAccountRoute {
    label: string;
    path: string;
    icon: ReactElement;
    component: ReactElement;
}

export const basePath = "/account";
export const routes: IAccountRoute[] = [
    {
        label: "My Account",
        path: "",
        component: <MyAccount />,
        icon: <IconMyAccount />,
    },
    {
        label: "Game History",
        path: "/history",
        component: <History />,
        icon: <IconHistory />,
    },
    {
        label: "Transactions",
        path: "/transactions",
        component: <Transactions />,
        icon: <IconTransactions />,
    },
];
