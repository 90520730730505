import styled from "styled-components";

export const StyledAnchor = styled.a<{ margin?: string }>`
    width: 320px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    padding: 12px 24px;
    color: rgb(31, 13, 83);
    align-items: center;
    background-color: ${({ theme }) => theme.color.white};
    transition: all 0.3s ease;
    margin: ${({ margin }) => margin};

    &:hover {
        color: rgb(31, 13, 83);
        background-color: #f0ecff;
    }

    @media only screen and (max-width: 568px) {
        width: 312px;
    }
`;

export const StyledCaption = styled.a`
    color: ${({ theme }) => theme.color.lightGreen};
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.lightGreen};
    }
`;