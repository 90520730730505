import { useState } from "react";
import useScrollingElement from "src/affiliate/hooks/use-scrolling-element";

type TUseModalReturn = [open: boolean, onOpen: () => void, onClose: () => void]

function useModal(): TUseModalReturn {
    const [open, setOpen] = useState(false);
    useScrollingElement(open);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return [open, onOpen, onClose];
}

export default useModal;
