import styled, { css } from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledMainContainer = styled.div`
    position: relative;
`;

export const StyledFlexContainer = styled(FlexContainer)<{
    border: boolean;
    transactionsIcon: boolean;
    trigger: boolean;
}>`
    width: 100%;
    height: 38px;
    border-radius: 10px;
    border: ${({ border }) => (border ? "solid 1px #c7baf1" : "solid 1px rgba(199, 186, 241, 0.2)")};
    background-color: #1f0d53;
    transition: all 0.3s ease-in;
    color: #c7baf1;
    position: relative;
    cursor: pointer;
    justify-content: space-between;
    padding: 0 15px 0 0;
    align-items: center;

    ${({ transactionsIcon, trigger }) => transactionsIcon
        ? css`
            img {
                width: 16px;
            }
        `
        : css`
            img {
                ${() => trigger
                    ? css`
                        transform: rotateZ(-180deg);
                    `
                    : css`
                        transform: rotateZ(-360deg);
                    `};
                transition: all .3s ease;
            }
        }
        `}
    p {
        padding: 8px 0 8px 16px;
    }
`;

export const StyledLabel = styled.label`
    color: #c7baf1;
    margin-bottom: 8px;
    white-space: nowrap;
`;

export const StyledListContainer = styled(FlexContainer)<{
    maxHeight?: boolean
    overflowY?: boolean
}>`
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 20px 0 rgba(13, 4, 40, 0.5);
    background-color: #1f0d53;
    color: #c7baf1;
    position: absolute;
    top: 41px;
    z-index: 1;
    ${({ maxHeight, overflowY }) => maxHeight
        ? css`
            max-height: 220px;
            overflow-y: ${() => (overflowY ? "auto" : "hidden")};
        `
        : css`
            max-height: 0;
        `};
    transition: max-height 0.3s ease;
`;
export const StyledItemWrapper = styled(FlexContainer)<{
    checked?: boolean;
    margin?: boolean;
    lastElement?: string;
}>`
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ checked }) => (checked ? "#22096c" : "transparent")};
    transition: all 0.25s ease-in;
    padding: 8px 16px 8px 16px;
    margin-bottom: ${({ margin }) => (margin ? "" : "5px")};
    border-radius: ${({ lastElement }) => lastElement};
`;