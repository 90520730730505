import React, { FC } from "react";
import * as Sentry from "@sentry/react";
import Crash from "src/containers/Crash/Crash";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Router } from "react-router";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import RouterTrigger from "src/components/RouterTrigger/router-trigger";
import { CookiesProvider } from "react-cookie";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory, History } from "history";
import createStore, { ReduxStore } from "src/redux/create";
import { RootState } from "src/redux/reducer";
import localForage from "localforage";
import NProgress from "nprogress/nprogress";
import asyncMatchRoutes from "src/utils/asyncMatchRoutes";
import { trigger } from "redial/lib";
import { IRouteWithComponent } from "src/routes";
import { Web3ModalProvider } from "src/core/wallet/hoc/wallet-connect-provider/WalletConnectProvider";
import { Provider as ReduxProvider } from "react-redux";
import { axiosInstance } from "src/core/http/axios-instance";

interface IRootProps {
    preloadedState: Partial<RootState>
    online: boolean
    routes: IRouteWithComponent[]
}

const persistConfig = {
    key: "hello", storage: localForage, stateReconciler(inboundState, originalState) {
        // Ignore state from cookies, only use preloadedState from window object
        return originalState;
    }, whitelist: [],
};

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false, refetchOnReconnect: false },
    },
});

const triggerHooks = async (routes: IRouteWithComponent[], pathname: string, store: ReduxStore, history: History<any>) => {
    NProgress.start();

    const { components, match, params } = await asyncMatchRoutes(routes, pathname);
    const triggerLocals = {
        client: axiosInstance, store, match, params, history, location: history.location,
    };

    await trigger("inject", components, triggerLocals);

    // Don't fetch data for initial route, server has already done the work:
    if (window.__PRELOADED__) {
        // Delete initial data so that subsequent data fetches can occur:
        delete window.__PRELOADED__;
    } else {
        // Fetch mandatory data dependencies for 2nd route change onwards:
        await trigger("fetch", components, triggerLocals);
    }
    await trigger("defer", components, triggerLocals);

    NProgress.done();
};

const Root: FC<IRootProps> = ({ preloadedState, online, routes }) => {
    const history = createBrowserHistory();
    const store = createStore({
        data: {
            ...preloadedState,
            ...window.__data,
            online,
        }, persistConfig,
    });

    return (
        <Sentry.ErrorBoundary fallback={<Crash />}>
            <ReduxProvider store={store}>
                <Web3ModalProvider>
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools />
                        <Router history={history}>
                            <QueryParamProvider adapter={ReactRouter5Adapter}>
                                <RouterTrigger trigger={(pathname: string) => triggerHooks(routes, pathname, store, history)}>
                                    <CookiesProvider>{renderRoutes(routes)}</CookiesProvider>
                                </RouterTrigger>
                            </QueryParamProvider>
                        </Router>
                    </QueryClientProvider>
                </Web3ModalProvider>
            </ReduxProvider>
        </Sentry.ErrorBoundary>
    );
};

export default Root;