export default function (): void {
    function loadScript(): void {
        const script = document.createElement("script");
        script.async = true;
        script.src = "//code.tidio.co/rdpju7puk94lptucuaeoejpmle1bkroo.js";
        (document.body || document.head).appendChild(script);
    }

    window.addEventListener("load", loadScript, false);
}
