const pageDescription = "You (hereon referred to as the ‘User’), must read this entire end-user agreement (Agreement) "
    + "before you begin using the services and products of Peergame. The Agreement is a legally binding contract between "
    + "you and Peergame (us), which is the rightful owner of all the games that you can access at Peergame.com "
    + "(the Service). Once you check the “I Agree” button wherever presented or if you choose to use our "
    + "services, you warrant to abide by our terms and conditions present in this Agreement.";

export default function TermsOfService() {
    return (
        <>
            <p>{pageDescription}</p>
            <h5>1. Grant of License</h5>
            <p>
                1.1. Peergame provides the user with a non-exclusive, personal, non-transferable right to use our Services on
                your electronic device with internet access which will enable you to play the games available on Peergame.com,
                albeit this right is conditional to the user’s adherence to the terms and conditions described here.
            </p>
            <p>
                1.2. This Service must not be used if you are aged under 18 or any age that is considered to be the legal
                majority in your jurisdiction. You should not access our Service if it is deemed to be illegal to do so in your
                jurisdiction. Since Peergame cannot verify the legality of its Service in every jurisdiction, the User must
                verify the same before use.
            </p>
            <p>
                1.3. Peergame coupled with its licensors is the sole owners of the Service and the code underlying it, the
                structure, organization, and intellectual property rights. The laws prohibit you from copying, sharing,
                publishing, reverse engineering, translating, altering, or decompiling the website. Furthermore, you must not
                use the Service in any manner that is deemed to be illegal under relevant law or regulations. Peergame reserves
                and retains all the rights, mentioned or implied, which are not granted to the User. You accept that you are
                responsible for any damages or costs incurred due to your Unauthorized Use. If you are aware of any person
                committing Unauthorized Use of Peergame, you must inform us immediately and must cooperate in any further
                investigations that are carried out in that matter.
            </p>
            <p>
                1.4. Peergame is the sole owner of the term 'Peergame,' the domain names, and other trademarks or service names
                used by Peergame through its service. Additionally, everything present on the website, including, but not
                limited to, graphics, images, videos, audio, and text (Site Content), and their intellectual property rights are
                owned by Peergame. You using our Service is a warrant that you understand you do not hold any rights pertaining
                to the Site Content, Trade Marks, etc., all of which cannot be used by the User without explicit consent from
                Peergame. You also agree that you will not commit any action that could harm the rights of Peergame.
            </p>
            <h5>2. No Warranties</h5>
            <p>
                2.1. Peergame does not offer any warranties in relation to the service and its quality, fitness for purpose,
                completeness, or accuracy
            </p>
            <p>
                Though Peergame makes continual efforts to ensure seamless service, it offers no warranty regarding the service
                being uninterrupted, timely or error-free, or that any of the flaws will be amended.
            </p>
            <h5>3. Authority/Terms of Service</h5>
            <p>
                You accept all the game rules mentioned on Peergame.com. Peergame retains any power over issuing, regulation,
                and termination of the Service. Any decisions made by Peergame, regarding the use of service or conflict
                resolution, is considered to be final and cannot be reviewed.
            </p>
            <h5>4. Your Representations and Authorities</h5>
            <p>Before and during your use of our Service, you agree, warrant, acknowledge, and pledge that:</p>
            <p>
                4.1. While using Peergame’s service, you are accepting the risk of losing cryptocurrency and Peergame is not
                responsible for such losses,
            </p>
            <p>
                4.2. Your use of Peergame’s service is your own choice and is committed upon your own discretion with the
                knowledge of your sole risks;
            </p>
            <p>
                4.3. It is the User’s sole responsibility to pay taxes on any cryptocurrency awarded through your use of our
                Service;
            </p>
            <p>
                4.4. Peergame has no control over the telecommunications networks and Internet access services that you need to
                use our Services and it falls beyond the scope of Peergame. Thus, Peergame holds no responsibility for any
                lagging, outages, slowness, bandwidth issues, etc., that you are facing; and
            </p>
            <p>
                4.6. The user is aged above 18 years or any age that is deemed to be the age of majority in their jurisdiction.
                Your accessing of our Service I not deemed to be unlawful or illegal in your jurisdiction and are service is
                considered to be legal there. You are, as of now, not self-excluded from any gambling site or gambling premises.
                Finally, you will notify Peergame about your self-exclusion contract with any gambling service instantly.
            </p>
            <h5>5. Prohibited Uses</h5>
            <p>
                5.1. PERSONAL USE. The Service is only meant for the personal use of the User, who can only bet for their
                recreational purposes. Users are not allowed to create multiple accounts, either for collusion or for exploiting
                any of the services.
            </p>
            <p>
                5.2 JURISDICTIONS. People residing in the following countries are prohibited from using our services:
                the United States, Czech Republic, Syria, Iran, North Korea, Republic of Korea, The Netherlands, France, or Australia
                (the ”Prohibited Jurisdictions”). Furthermore, these restrictions imposed on these jurisdictions, prohibiting them
                from playing for actual money, is applicable to citizens of any other country when they visit any country
                from a Prohibited Jurisdiction. If any individual attempts to override the prohibitions on playing
                from any Prohibited Jurisdiction or Restricted Jurisdiction, it is considered to be an infringement of this Agreement.
                An attempt at evasion includes, but is not limited to, manipulating any information that Peergame uses to detect your location
                or voluntarily providing false information regarding your location or residence to Peergame.
            </p>
            <h5>6. Know your Customer (“KYC”)</h5>
            <p>
                Peergame reserves the right to seek any KYC documentation at any point to verify both the identity and the
                location of a User. Peergame reserves the right to restrict both service and awards until the identity is
                appropriately verified.
            </p>
            <h5>7. Breach</h5>
            <p>
                7.1. Peergame, while not compromising on any other rights, reserves the right to take any appropriate action
                that it determines upon its discretion, if any User infringes any provision contained within this document,
                either partially or entirely. The actions may include terminating this Agreement or any other agreement in place
                with the User or
            </p>
            <p>
                7.2. You agree to indemnify, defend, and hold harmless Peergame and its employees, directors, agents, licensors,
                suppliers, and affiliates against all claims, losses, damages, and costs, including reasonable attorneys' fees,
                resulting from any violation of these Terms of Use by you or arising from or related to your use or misuse of
                the website or our services, or violation any law or any third party rights by you.
            </p>
            <h5>8. Limitations and Liability</h5>
            <p>
                8.1. To the extent that law permits, the website, its employees, directors, agents, licensors, suppliers, and
                affiliates are not liable for any special, incidental, direct, indirect or consequential damages including
                damages for loss of business profits, business interruption, loss of business information, resulting from the
                use of the website, regardless of Peergame’s prior knowledge of the same.
            </p>
            <p>
                Peergame’s limitation of liability does not extend to a User’s death or personal injury due to its negligence
                and no provision in this Agreement allows any such limitation.
            </p>
            <h5>9. Disputes</h5>
            <p>
                In case a User would like to drop a complaint, they can feel free to address the customer service team of
                Peergame at contact@peergame.com. If any conflict has not been resolved to your satisfaction, you can follow-up
                in the governing law jurisdiction set forth below for remedies.
            </p>
            <h5>10. Amendment</h5>
            <p>
                Peergame reserves the right to amend, update or alter this Agreement at any point in time or even alter the
                service without any prior notice and your continued usage after the published changes is your acceptance of such
                amendments. Thus, we recommend you to go through the terms and conditions of Peergame regularly, which is
                contained within the Agreement.
            </p>
            <h5>11. Severability</h5>
            <p>
                If any of these Terms become unlawful, void, or unenforceable by law in any jurisdiction, that specific
                provision is deemed severed from the rest and shall not affect their enforceability in that jurisdiction.
                The same provisions will continue to remain applicable in other jurisdictions.
            </p>
            <h5>12. Assignment</h5>
            <p>
                Peergame reserves the right to assign this agreement, partially or completely, at any point in time, without
                prior notice. The User is prohibited from assigning any of their rights or obligations under this Agreement.
            </p>
            <h5>13. Miscellaneous</h5>
            <p>
                13.1. If Peergame waives any infringement of provisions of this Agreement (including, but not limited to,
                the failure of Peergame to require strict and literal performance of or compliance with any provision of
                this Agreement) it cannot be interpreted as a waiver for any future breaches of such provisions or infringement
                of any other provision contained in this Agreement.
            </p>
            <p>
                13.2. No provision in this Agreement allows conferring of any rights or other benefits in favor of any
                third-parties who are not a part of this Agreement.
            </p>
            <p>
                13.3. No clause contained within this Agreement shall create or be deemed to create a partnership, association,
                agency, trust arrangement, fiduciary relationship, or joint venture between you and Peergame.
            </p>
            <p>
                13.4. Peergame reserves the right to, upon its discretion, to assign, transfer, charge, sub-license, or act
                in any manner, pertaining to the clauses in this Agreement, or sub-contract any of its rights and obligations
                under this Agreement, to another entity.
            </p>
            <p>
                13.5. This Agreement comprises the whole understanding and contract between you and Peergame, related to
                the Service and replaces any previous agreement, understanding, or arrangement between you and Peergame.
            </p>
            <br />
            <strong>
                <p>
                    The market for the Cryptocurrencies is still relatively new and highly volatile. The price or value of
                    cryptocurrencies can rapidly increase or decrease at any time. The risk of loss in trading or holding an
                    interest in cryptocurrencies can be substantial and can result in the loss of the entire value of your
                    interest in cryptocurrency.
                </p>
            </strong>
            <h5>Third Party Privacy Policies</h5>
            <p>
                Peergame's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers for more detailed information. It may include
                their practices and instructions about how to opt-out of certain options. You may find a complete list of these
                Privacy Policies and their links here: Privacy Policy Links.
                <br />
                You can choose to disable cookies through your individual browser options. To know more detailed information
                about cookie management with specific web browsers, it can be found at the browsers' respective websites. What
                Are Cookies?
            </p>
        </>
    );
}
