import React from "react";
import Animated1ChipSVG from "games/components/AnimatedChips/Animated1ChipSVG";
import Animated01ChipSVG from "../components/AnimatedChips/Animated01ChipSVG";
import Animated001ChipSVG from "../components/AnimatedChips/Animated001ChipSVG";
import Animated0001ChipSVG from "../components/AnimatedChips/Animated0001ChipSVG";
import Animated005ChipSVG from "../components/AnimatedChips/Animated005ChipSVG";
import Animated0005ChipSVG from "../components/AnimatedChips/Animated0005ChipSVG";
import { filterNumbers } from "./utils";

// the first item is the one in center top
export const wheelSections = [
    0,
    32,
    15,
    19,
    4,
    21,
    2,
    25,
    17,
    34,
    6,
    27,
    13,
    36,
    11,
    30,
    8,
    23,
    10,
    5,
    24,
    16,
    33,
    1,
    20,
    14,
    31,
    9,
    22,
    18,
    29,
    7,
    28,
    12,
    35,
    3,
    26,
];
export const splits = [
    { d: 0, numbers: [0, 1] },
    { d: 1, numbers: [0, 2] },
    { d: 2, numbers: [0, 3] },
    { d: 3, numbers: [1, 2] },
    { d: 4, numbers: [1, 4] },
    { d: 5, numbers: [2, 3] },
    { d: 6, numbers: [2, 5] },
    { d: 7, numbers: [3, 6] },
    { d: 8, numbers: [4, 5] },
    { d: 9, numbers: [4, 7] },
    { d: 10, numbers: [5, 6] },
    { d: 11, numbers: [5, 8] },
    { d: 12, numbers: [6, 9] },
    { d: 13, numbers: [7, 8] },
    { d: 14, numbers: [7, 10] },
    { d: 15, numbers: [8, 9] },
    { d: 16, numbers: [8, 11] },
    { d: 17, numbers: [9, 12] },
    { d: 18, numbers: [10, 11] },
    { d: 19, numbers: [10, 13] },
    { d: 20, numbers: [11, 12] },
    { d: 21, numbers: [11, 14] },
    { d: 22, numbers: [12, 15] },
    { d: 23, numbers: [13, 14] },
    { d: 24, numbers: [13, 16] },
    { d: 25, numbers: [14, 15] },
    { d: 26, numbers: [14, 17] },
    { d: 27, numbers: [15, 18] },
    { d: 28, numbers: [16, 17] },
    { d: 29, numbers: [16, 19] },
    { d: 30, numbers: [17, 18] },
    { d: 31, numbers: [17, 20] },
    { d: 32, numbers: [18, 21] },
    { d: 33, numbers: [19, 20] },
    { d: 34, numbers: [19, 22] },
    { d: 35, numbers: [20, 21] },
    { d: 36, numbers: [20, 23] },
    { d: 37, numbers: [21, 24] },
    { d: 38, numbers: [22, 23] },
    { d: 39, numbers: [22, 25] },
    { d: 40, numbers: [23, 24] },
    { d: 41, numbers: [23, 26] },
    { d: 42, numbers: [24, 27] },
    { d: 43, numbers: [25, 26] },
    { d: 44, numbers: [25, 28] },
    { d: 45, numbers: [26, 27] },
    { d: 46, numbers: [26, 29] },
    { d: 47, numbers: [27, 30] },
    { d: 48, numbers: [28, 29] },
    { d: 49, numbers: [28, 31] },
    { d: 50, numbers: [29, 30] },
    { d: 51, numbers: [29, 32] },
    { d: 52, numbers: [30, 33] },
    { d: 53, numbers: [31, 32] },
    { d: 54, numbers: [31, 34] },
    { d: 55, numbers: [32, 33] },
    { d: 56, numbers: [32, 35] },
    { d: 57, numbers: [33, 36] },
    { d: 58, numbers: [34, 35] },
    { d: 59, numbers: [35, 36] },
];
export const corners = [
    { d: 0, numbers: [0, 1, 2, 3] },
    { d: 1, numbers: [1, 2, 4, 5] },
    { d: 2, numbers: [2, 3, 5, 6] },
    { d: 3, numbers: [4, 5, 7, 8] },
    { d: 4, numbers: [5, 6, 8, 9] },
    { d: 5, numbers: [7, 8, 10, 11] },
    { d: 6, numbers: [8, 9, 11, 12] },
    { d: 7, numbers: [10, 11, 13, 14] },
    { d: 8, numbers: [11, 12, 14, 15] },
    { d: 9, numbers: [13, 14, 16, 17] },
    { d: 10, numbers: [14, 15, 17, 18] },
    { d: 11, numbers: [16, 17, 19, 20] },
    { d: 12, numbers: [17, 18, 20, 21] },
    { d: 13, numbers: [19, 20, 22, 23] },
    { d: 14, numbers: [20, 21, 23, 24] },
    { d: 15, numbers: [22, 23, 25, 26] },
    { d: 16, numbers: [23, 24, 26, 27] },
    { d: 17, numbers: [25, 26, 28, 29] },
    { d: 18, numbers: [26, 27, 29, 30] },
    { d: 19, numbers: [28, 29, 31, 32] },
    { d: 20, numbers: [29, 30, 32, 33] },
    { d: 21, numbers: [31, 32, 34, 35] },
    { d: 22, numbers: [32, 33, 35, 36] },
];
export const tableLayout = {
    desktop: [
        [
            { value: 0, color: "GREEN" },
            {
                value: 3,
                color: "RED",
            },
            { value: 6, color: "BLACK" },
            { value: 9, color: "RED" },
            { value: 12, color: "RED" },
            { value: 15, color: "BLACK" },
            { value: 18, color: "RED" },
            { value: 21, color: "RED" },
            { value: 24, color: "BLACK" },
            { value: 27, color: "RED" },
            { value: 30, color: "RED" },
            { value: 33, color: "BLACK" },
            { value: 36, color: "RED" },
        ],
        [
            { value: 0, color: "GREEN" },
            { value: 2, color: "BLACK" },
            { value: 5, color: "RED" },
            { value: 8, color: "BLACK" },
            { value: 11, color: "BLACK" },
            { value: 14, color: "RED" },
            { value: 17, color: "BLACK" },
            { value: 20, color: "BLACK" },
            { value: 23, color: "RED" },
            { value: 26, color: "BLACK" },
            { value: 29, color: "BLACK" },
            { value: 32, color: "RED" },
            { value: 35, color: "BLACK" },
        ],
        [
            { value: 0, color: "GREEN" },
            {
                value: 1,
                color: "RED",
            },
            {
                value: 4,
                color: "BLACK",
            },
            {
                value: 7,
                color: "RED",
            },
            {
                value: 10,
                color: "BLACK",
            },
            {
                value: 13,
                color: "BLACK",
            },
            {
                value: 16,
                color: "RED",
            },
            {
                value: 19,
                color: "RED",
            },
            {
                value: 22,
                color: "BLACK",
            },
            {
                value: 25,
                color: "RED",
            },
            {
                value: 28,
                color: "BLACK",
            },
            {
                value: 31,
                color: "BLACK",
            },
            {
                value: 34,
                color: "RED",
            },
        ],
    ],
    mobile: [
        [
            { value: 0, color: "GREEN" },
            { value: 0, color: "GREEN" },
            { value: 0, color: "GREEN" },
        ],
        [
            { value: 1, color: "RED" },
            { value: 2, color: "BLACK" },
            { value: 3, color: "RED" },
        ],
        [
            { value: 4, color: "BLACK" },
            { value: 5, color: "RED" },
            { value: 6, color: "BLACK" },
        ],
        [
            { value: 7, color: "RED" },
            { value: 8, color: "BLACK" },
            { value: 9, color: "RED" },
        ],
        [
            { value: 10, color: "BLACK" },
            { value: 11, color: "BLACK" },
            { value: 12, color: "RED" },
        ],
        [
            { value: 13, color: "BLACK" },
            { value: 14, color: "RED" },
            { value: 15, color: "BLACK" },
        ],
        [
            { value: 16, color: "RED" },
            { value: 17, color: "BLACK" },
            { value: 18, color: "RED" },
        ],
        [
            { value: 19, color: "RED" },
            { value: 20, color: "BLACK" },
            { value: 21, color: "RED" },
        ],
        [
            { value: 22, color: "BLACK" },
            { value: 23, color: "RED" },
            { value: 24, color: "BLACK" },
        ],
        [
            { value: 25, color: "RED" },
            { value: 26, color: "BLACK" },
            { value: 27, color: "RED" },
        ],
        [
            { value: 28, color: "BLACK" },
            { value: 29, color: "BLACK" },
            { value: 30, color: "RED" },
        ],
        [
            { value: 31, color: "BLACK" },
            { value: 32, color: "RED" },
            { value: 33, color: "BLACK" },
        ],
        [
            { value: 34, color: "RED" },
            { value: 35, color: "BLACK" },
            { value: 36, color: "RED" },
        ],
    ],
};

export const chips = process.env.REDUCED_BETS === "true"
    ? {
        0.01: <Animated1ChipSVG />,
        0.001: <Animated01ChipSVG />,
        0.0001: <Animated001ChipSVG />,
        0.00001: <Animated0001ChipSVG />,
        0.0005: <Animated005ChipSVG />,
        0.00005: <Animated0005ChipSVG />,
    }
    : {
        1: <Animated1ChipSVG />,
        0.1: <Animated01ChipSVG />,
        0.01: <Animated001ChipSVG />,
        0.001: <Animated0001ChipSVG />,
        0.05: <Animated005ChipSVG />,
        0.005: <Animated0005ChipSVG />,
    };

export const areas = {
    COLOR: {
        getLabel: color => (color ? color[0] + color.slice(1).toLowerCase() : "Red/Black"),
        description:
            "To be placed on either the “Red” or “Black” boxes of the betting area. There are 18 red numbers and 18 black numbers. The 0 is not included. ",
        getNumbersLabel: color => `All ${areas.COLOR.getLabel(color)}s`,
        getNumbers: (_color, layout = "desktop") => filterNumbers(tableLayout[layout], ({ color }) => color === _color),
        getChipPosition: (type, layout) => {
            if (layout === "desktop") {
                return { row: 9, col: type === "RED" ? 12 : 16 };
            }
            return { col: 1, row: type === "RED" ? 12 : 16 };
        },
        isOutside: true,
    },
    EVENODD: {
        getLabel: type => (type ? type[0] + type.slice(1).toLowerCase() : "Even/Odd"),
        description:
            "To be placed on either the “Odd” or “Even” boxes of the betting area. There are 18 odd numbers and 18 even numbers. The 0 is not included. ",
        getNumbersLabel: type => `All ${areas.EVENODD.getLabel(type)}s`,
        getNumbers: (type, layout = "desktop") => filterNumbers(tableLayout[layout], ({ value }) => value !== 0 && type === "EVEN" ? value % 2 === 0 : value % 2 !== 0),
        getChipPosition: (type, layout) => {
            if (layout === "desktop") {
                return { row: 9, col: type === "EVEN" ? 8 : 20 };
            }
            return { col: 1, row: type === "EVEN" ? 8 : 20 };
        },
        isOutside: true,
    },
    HIGHLOW: {
        getLabel: type => (type ? type[0] + type.slice(1).toLowerCase() : "Low/High"),
        description:
            "This bet covers 18 numbers.  You can place the bet on either the “1 to 18” or  “19 to 36”.  The 0 is not included.",
        getNumbersLabel: type => (type === "LOW" ? "1 to 18" : "19 to 36"),
        getNumbers: (type, layout = "desktop") => filterNumbers(tableLayout[layout], ({ value }) => type === "LOW" ? value >= 1 && value <= 18 : value >= 19 && value <= 36),
        getChipPosition: (type, layout) => {
            if (layout === "desktop") {
                return { row: 9, col: type === "LOW" ? 4 : 24 };
            }
            return { col: 1, row: type === "LOW" ? 4 : 24 };
        },
        isOutside: true,
    },
    DOZEN: {
        getLabel: () => "Dozen",
        description:
            "This bet covers 12 numbers. The boxes are tagged “1st 12”, “2nd 12” and “3rd 12”. You can bet on any of the three columns by placing chips on these boxes.",
        getNumbers: (details, layout = "desktop") => filterNumbers(tableLayout[layout], ({ value }) => value >= 1 + details * 12 && value <= 12 * (details + 1)),
        getNumbersLabel: details => {
            const numbers = areas.DOZEN.getNumbers(details).sort((a, b) => a.value - b.value);
            return `${numbers[0].value} to ${numbers[numbers.length - 1].value}`;
        },
        getChipPosition: (details, layout) => {
            if (layout === "desktop") {
                return { row: 7, col: 6 + 8 * details };
            }
            return { col: 3, row: 6 + 8 * details };
        },
        isOutside: true,
    },
    COLUMN: {
        getLabel: () => "Column",
        description:
            "This bet covers 12 numbers. The boxes are tagged “2 to 1”. You can bet on any of the three columns by placing chips on the “2 to 1” tagged boxes.",
        getNumbersLabel: details => {
            switch (details) {
                case 0:
                    return "Left";
                case 1:
                    return "Middle";
                case 2:
                    return "Right";
                default:
            }
        },
        getNumbers: (details, layout = "desktop") => filterNumbers(tableLayout[layout], ({ row, col }) => {
            if (layout === "mobile") {
                return row !== 0 && col === details;
            }
            return col !== 0 && row === 2 - details;
        }),
        getChipPosition: (details, layout) => {
            if (layout === "desktop") {
                return { row: 1 + 2 * (2 - details), col: 27 };
            }
            return { col: 5 + 2 * details, row: 27 };
        },
        isOutside: true,
    },
    SIXLINE: {
        getLabel: () => "Six Line",
        description:
            "This bet covers 6 numbers, you can place a bet on the exterior top border line where the line dividing the two streets is cut.",
        getNumbers: (details, layout = "desktop") => filterNumbers(tableLayout[layout], ({ row, col }) => {
            if (layout === "mobile") {
                return row === details + 1 || row === details + 2;
            }
            return col === details + 1 || col === details + 2;
        }),
        getNumbersLabel: details => {
            const numbers = areas.SIXLINE.getNumbers(details).sort((a, b) => a.value - b.value);
            return `${numbers[0].value} to ${numbers[numbers.length - 1].value}`;
        },
        getChipPosition: (details, layout) => {
            if (layout === "desktop") {
                return { row: 0, col: 4 + 2 * details };
            }
            return { col: 10, row: 4 + 2 * details };
        },
    },
    CORNER: {
        getLabel: () => "Corner",
        description:
            "You can place a bet on four numbers at a time by placing chips on the corners where the four numbers meet. ",
        getNumbers: (details, layout = "desktop") => filterNumbers(tableLayout[layout], ({ value }) => {
            const cornerNumbers = corners.find(({ d }) => d === details).numbers;
            return cornerNumbers.includes(value);
        }),
        getNumbersLabel: details => {
            const numbers = areas.CORNER.getNumbers(details);
            return `${numbers[0].value}, ${numbers[1].value}, ${numbers[2].value}, ${numbers[3].value}`;
        },
        getChipPosition: (details, layout) => {
            // special placement
            if (details === 0) {
                return layout === "desktop" ? { row: 0, col: 2 } : { row: 2, col: 10 };
            }
            const cornerNumbers = corners.find(({ d }) => d === details).numbers;
            return cornerNumbers.reduce(
                (accumulator, _value) => {
                    // eslint-disable-next-line no-shadow
                    const { row, col } = filterNumbers(tableLayout[layout], ({ value }) => _value === value);
                    return {
                        row: accumulator.row + row / 2,
                        col: accumulator.col + col / 2,
                    };
                },
                layout === "desktop" ? { row: 1, col: 1 } : { row: 1, col: 5 },
            );
        },
    },
    STREET: {
        getLabel: () => "Street",
        description:
            "The bet covers three numbers that go in a sequence. The player can also bet on the following groups: 0, 1 and 2 or 0, 2 and 3",
        getNumbersLabel: details => {
            const numbers = areas.STREET.getNumbers(details);
            return `${numbers[0].value}, ${numbers[1].value}, ${numbers[2].value}`;
        },
        getNumbers: (details, layout = "desktop") => filterNumbers(tableLayout[layout], ({ row, col }) => {
            if (layout === "desktop") {
                if (details === 0 || details === 1) {
                    return col === 0 || (col === 1 && (2 - row === details || 2 - row === details + 1));
                }
                return col === details - 1;
            }
            if (details === 0 || details === 1) {
                return row === 0 || (row === 1 && (col === details || col === details + 1));
            }
            return row === details - 1;
        }),
        getChipPosition: (details, layout) => {
            if (layout === "desktop") {
                // special placement
                if (details === 0 || details === 1) {
                    return { row: 4 - 2 * details, col: 2 };
                }
                return { row: 0, col: 3 + 2 * (details - 2) };
            }
            // special placement
            if (details === 0 || details === 1) {
                return { col: 6 + 2 * details, row: 2 };
            }
            return { col: 10, row: 3 + 2 * (details - 2) };
        },
    },
    SPLIT: {
        getLabel: () => "Split",
        description: "This bet is placed on more than one number by placing your chip(s) on the line between two numbers.",
        getNumbers: (details, layout = "desktop") => filterNumbers(tableLayout[layout], ({ value }) => {
            const splitNumbers = splits.find(({ d }) => d === details).numbers;
            return splitNumbers.includes(value);
        }),
        getNumbersLabel: details => {
            const numbers = areas.SPLIT.getNumbers(details);
            return `${numbers[0].value}, ${numbers[1].value}`;
        },
        getChipPosition: (details, layout) => {
            const splitNumbers = splits.find(({ d }) => d === details).numbers;
            return splitNumbers.reduce(
                (accumulator, _value) => {
                    let { row, col } = filterNumbers(tableLayout[layout], ({ value }) => _value === value);
                    // shift by row if 0 is included (first column special condition)
                    if (layout === "desktop") {
                        row += splitNumbers.includes(0) ? row : 0;
                    } else {
                        col += splitNumbers.includes(0) ? col : 0;
                    }
                    return {
                        row: accumulator.row + row,
                        col: accumulator.col + col,
                    };
                },
                layout === "desktop" ? { row: 1, col: 1 } : { row: 1, col: 5 },
            );
        },
    },
    STRAIGHT: {
        getLabel: () => "Straight Up",
        description: "This is a bet that covers only one number and you can place it on any single number as well as on 0.",
        getNumbersLabel: value => value.toString(),
        getNumbers: (_value, layout = "desktop") => [filterNumbers(tableLayout[layout], ({ value }) => _value === value)],
        getChipPosition: (_value, layout) => {
            // eslint-disable-next-line no-shadow
            const { row, col } = filterNumbers(tableLayout[layout], ({ value }) => _value === value);
            if (layout === "desktop") {
                // special placement
                if (_value === 0) {
                    return { row: 3, col: 1 };
                }
                return { row: 1 + 2 * row, col: 3 + 2 * (col - 1) };
            }
            if (_value === 0) {
                return { row: 1, col: 7 };
            }
            return { row: 1 + 2 * row, col: 7 + 2 * (col - 1) };
        },
    },
};
