const COLORS = {
    white: "#ffffff",
    purple: "#1f0d53",
    lightGreen: "#c5fff1",
    error: "#ff2976",
};

const THEME = {
    color: {
        white: COLORS.white,
        purple: COLORS.purple,
        lightGreen: COLORS.lightGreen,
        error: COLORS.error,
    },
    typography: {
        h1: 40,
        h2: 16,
        h3: 24,
        p: 20,
        caption: 14,
        info: 12,
        span: 20,
    },
};

export default THEME;
