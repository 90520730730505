import classNames from "classnames/bind";
import { useQueryClient } from "@tanstack/react-query";
import styles from "./assets/styles.scss";
import useCountdown from "src/helpers/use-countdown";

interface ICountdownTimerProps {
    endsAt: string | null
}

const cx = classNames.bind(styles);

export default function CountdownTimer({ endsAt }: ICountdownTimerProps) {
    const queryClient = useQueryClient();
    const {
        days, hours, minutes, seconds,
    } = useCountdown(endsAt || "", () => queryClient.invalidateQueries({ queryKey: ["settings", "races"] }));

    return (
        <div className={cx("countdown-timer")}>
            <p className={cx("xsmall")}>REMAINING TIME</p>
            <div className={cx("timer-row")}>
                <div className={cx("time-column")}>
                    <div>
                        <h4>{days}</h4>
                    </div>
                    <p className={cx("xsmall")}>Day</p>
                </div>
                <div className={cx("time-column")}>
                    <div>
                        <h4>{hours}</h4>
                    </div>
                    <p className={cx("xsmall")}>Hour</p>
                </div>
                <div className={cx("time-column")}>
                    <div>
                        <h4>{minutes}</h4>
                    </div>
                    <p className={cx("xsmall")}>Min</p>
                </div>
                <div className={cx("time-column")}>
                    <div>
                        <h4>{seconds}</h4>
                    </div>
                    <p className={cx("xsmall")}>Sec</p>
                </div>
            </div>
        </div>
    );
}
