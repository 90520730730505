import Helmet from "react-helmet";
import ResourcesCard from "src/affiliate/components/resources/ResourcesCard";
import peerGameImage from "./assets/image.png";
import freeCoinEventImage from "./assets/free-coin.png";
import diceImage from "./assets/dice.png";
import { StyledFlexContainer } from "src/containers/Affiliate/resources/assets/styles/resources.styled";
import Layout from "src/affiliate/components/Layout";
import DashBoardLayout from "src/affiliate/components/reusable/DashBoardLayout";

const Resources = () => (
    <>
        <Helmet>
            <title>Resources</title>
            <meta
                property="og:title"
                content="Peergame Partners - Partner up with the highest-paid commission casino affiliate program."
            />
            <meta
                name="description"
                content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
            />
        </Helmet>
        <Layout sideNav transparent>
            <DashBoardLayout title="Resources" mobilePadding="22px 0 0 0">
                <StyledFlexContainer padding="32px" mobilePadding="24px">
                    <ResourcesCard
                        brandTitle="Brand Assets"
                        img={peerGameImage}
                        href="https://www.dropbox.com/sh/t790v1r7l4ubjj3/AADTxCP8RPUz-s6Jh9kNAWtna?dl=0"
                    />
                    <ResourcesCard
                        brandTitle="Game Assets"
                        img={freeCoinEventImage}
                        href="https://www.dropbox.com/sh/sok3zvhh5kny9qg/AABhZQ4FX7lujx3w3OzTDzLMa?dl=0"
                    />
                    <ResourcesCard
                        brandTitle="Promotion Assets"
                        img={diceImage}
                        href="https://www.dropbox.com/sh/1d3n8qlkh458pht/AACsjYf3qpcSMkQDZOWK_AvVa?dl=0"
                    />
                </StyledFlexContainer>
            </DashBoardLayout>
        </Layout>
    </>
);

export default Resources;
