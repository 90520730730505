import React, { memo } from "react";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { StyledButton, StyledFlexContainer, StyledFlexItem, StyledSpan } from "src/affiliate/components/reusable/assets/styles/pagination.styled";
import { IPagination } from "src/affiliate/components/reusable/types/pagination";

interface IPaginationProps {
    pagination: IPagination[];
    prevPage?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    changePage?: (id: number, e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    nextPage?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    margin?: boolean;
}

const Pagination = ({
    pagination,
    prevPage,
    changePage,
    nextPage,
    margin = false,
}: IPaginationProps) => (
    <>
        <FlexContainer alignItems="center" justifyContent="center" margin={margin ? "" : "24px 0 0 0"}>
            <StyledButton onClick={e => prevPage?.(e)}>Prev</StyledButton>
            <StyledFlexContainer margin="0 20px 0 20px" alignItems="center" padding="0 4px 0 4px">
                {pagination.map(({
                    id, current, ellipsis, value,
                }) => !ellipsis ? (
                    <StyledFlexItem key={id} current={current} onClick={e => changePage?.(id, e)}>
                        <span className="small">{value}</span>
                    </StyledFlexItem>
                ) : (
                    <StyledFlexItem key={id} cursor="true">
                        <StyledSpan className="small">&hellip;</StyledSpan>
                    </StyledFlexItem>
                ))}
            </StyledFlexContainer>
            <StyledButton onClick={e => nextPage?.(e)} className="small">
                Next
            </StyledButton>
        </FlexContainer>
    </>
);

export default memo(Pagination);
