import styled from "styled-components";

interface ICommonProps {
    height: string
}

export const StyledTopBlock = styled.div<ICommonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${({ height }) => height};
    padding: 8px 0 16px 0;
    border-radius: 10px;
    background-color: #ffffff;
    position: relative;
    top: 8px;
`;

export const StyledCaption = styled.h4`
    text-align: center;
    color: #22096c;
`;

export const StyledBottomBlock = styled.div<ICommonProps>`
    width: 100%;
    height: ${({ height }) => height};
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(57, 24, 62, 0.4);
    background-image: linear-gradient(to bottom, #ff4c75, #4b0074);
`;

export const StyledH3 = styled.h2`
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
    color: #ffffff;
`;