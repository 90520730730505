import { useEffect, useState } from "react";

function getTimeComponents(remainingSeconds: number) {
    const days = Math.floor(remainingSeconds / (3600 * 24));
    const hours = Math.floor((remainingSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = Math.floor(remainingSeconds % 60);

    return {
        days: days < 10 ? `0${days}` : days,
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
    };
}

// endsAt is an iso string representation of the ending time
export default function useCountdown(endsAt: string | number = 0, onFinished?: () => void) {
    const [timeRemaining, setTimeRemaining] = useState(() => new Date(endsAt).getTime());

    function getRemainingSeconds() {
        const remainingSeconds = Math.floor((new Date(endsAt).getTime() - Date.now()) / 1000);
        return remainingSeconds < 0 ? 0 : remainingSeconds;
    }

    useEffect(() => {
        if (endsAt) {
            if (timeRemaining <= 0) {
                if (onFinished) onFinished();
                return;
            }
            const timerId = setTimeout(() => {
                setTimeRemaining(getRemainingSeconds());
            }, 1000);

            return () => clearTimeout(timerId);
        }
    }, [timeRemaining]);

    useEffect(() => {
        if (endsAt) {
            setTimeRemaining(getRemainingSeconds());
        }
    }, [endsAt]);

    return getTimeComponents(timeRemaining);
}
