import RealTimePayments from "src/affiliate/components/affiliate-landing/section-three/payments/RealTimePayments";
import Picture from "src/affiliate/components/reusable/Picture";
import paymentsImage from "../assets/Payments Image.png";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { StyledTitle } from "src/affiliate/components/affiliate-landing/section-two/assets/styles/section-two-main.styled";

const Payments = () => (
    <FlexContainer
        flexDirection="column"
        alignItems="center"
        mobileAlignItems="baseline"
        padding="0 0 180px 0"
        tabletPadding="0 60px 60px 60px"
        mobilePadding="0 24px 89px 24px"
    >
        <StyledTitle>PAYMENTS</StyledTitle>
        <FlexContainer
            width="100%"
            maxWidth="900px"
            padding="80px 0 0 0"
            tabletPadding="60px 0 0 0"
            mobilePadding="32px 0 0 0"
            justifyContent="space-between"
            tabletJustifyContent="center"
            alignItems="center"
            tabletFlexWrap
            mobileFlexWrap
        >
            <RealTimePayments />
            <FlexContainer width="260px" mobileWidth="240px">
                <Picture img={paymentsImage} />
            </FlexContainer>
        </FlexContainer>
    </FlexContainer>
);

export default Payments;
