import styled, { css } from "styled-components";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";

export const StyledButton = styled.span`
    cursor: pointer;
    color: #c7baf1;
`;

export const StyledFlexContainer = styled(FlexContainer)`
    justify-content: space-around;
    min-width: 153px;
    height: 30px;
    border-radius: 4px;
    background-color: #22096c;
`;

export const StyledFlexItem = styled(FlexItem)<{ current?: boolean; ellipsis?: boolean }>`
    width: 25px;
    height: 22px;
    cursor: ${({ cursor }) => (cursor ? "" : "pointer")};
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    background-color: ${({ current }) => (current ? "rgba(199, 186, 241, 0.2)" : "transparent")};
    ${({ ellipsis }) => ellipsis
        ? css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        `
        : ""}
`;

export const StyledSpan = styled.span`
    width: 25px;
    height: 22px;
    color: #ffffff;
`;