import { useEffect } from "react";

type Handler = (event: Event) => void;

export default function usePageScroll(handler: Handler): void {
    useEffect(() => {
        function onScroll(e: Event): void {
            handler(e);
        }

        document.body.addEventListener("scroll", onScroll);
        return () => document.body.removeEventListener("scroll", onScroll);
    }, [handler]);
}
