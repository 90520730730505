import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import Input from "src/affiliate/components/reusable/Input";
import protectedMail from "src/affiliate/utils/protectedMail";

interface IFormProps {
    name: string;
    email: string;
    sot: string;
    commissionPayoutAddress: string;
}

const Form = ({
    name, email, sot, commissionPayoutAddress,
}: IFormProps) => (
    <>
        <FlexContainer flexDirection="column" width="60%" mobileWidth="100%">
            <FlexItem margin="0 0 30px 0" mobileMargin="0 0 24px 0">
                <Input
                    disabled
                    readOnly
                    backGroundColor="#22096c"
                    name="fullName"
                    label="Full Name"
                    value={name}
                    inputType="text"
                    border="none"
                    accountSettings
                />
            </FlexItem>
            <FlexItem margin="0 0 30px 0" mobileMargin="0 0 24px 0">
                <Input
                    disabled
                    readOnly
                    name="email"
                    label="Email"
                    value={protectedMail(email)}
                    inputType="email"
                    backGroundColor="#22096c"
                    border="none"
                    accountSettings
                />
            </FlexItem>
            <FlexItem margin="0 0 30px 0" mobileMargin="0 0 24px 0">
                <Input
                    disabled
                    readOnly
                    name="CommissionPayoutAddress"
                    label="Commission Payout Address"
                    value={commissionPayoutAddress}
                    inputType="email"
                    backGroundColor="#22096c"
                    border="none"
                    accountSettings
                />
            </FlexItem>
            <FlexItem margin="0 0 30px 0" mobileMargin="0 0 24px 0">
                <Input
                    disabled
                    readOnly
                    name="source"
                    label="Source of Traffic ( Website, Blog, Social Media )"
                    value={sot}
                    inputType="text"
                    backGroundColor="#22096c"
                    border="none"
                    accountSettings
                />
            </FlexItem>
        </FlexContainer>
    </>
);

export default Form;
