import {useMemo} from "react";
import {BigNumber} from "bignumber.js";

export default function useResultNumber(randomNumber, modulusBy) {
    return useMemo(() => {
        if (randomNumber) {
            return new BigNumber(randomNumber, 16)
                .mod(modulusBy, 10)
                .toNumber()
                .toString();
        }
    }, [randomNumber, modulusBy]);
}
