const pageDescription = "At Peergame, we aspire to offer you a platform that is safe, protected, and regulated "
    + "to enhance your experience and ensure you have a peaceful and fun time. We urge all our users to use our website "
    + "and services in a responsible and rational manner. You can find adequate information regarding how to play "
    + "responsibly and s everal firms also offer expert support to those who are dealing with the bad habit of "
    + "compulsive gambling, which is moving towards the construction of addiction.";

export default function ResponsibleGaming() {
    return (
        <>
            <p>{pageDescription}</p>
            <h5>Play responsibly</h5>
            <p>
                Typically, online gambling is meant for entertainment and recreation. However, several individuals seem to
                lose complete control over their actions while engaging in this practice. It is a good practice to monitor the
                time spent and the amount of money spent at an online casino, on a daily basis.
            </p>
            <h5>Assistance for problem gaming</h5>
            <p>
                In case you are struck with the realization that you are spending more than what your financial situation
                enables you to afford, or if your gaming renders you dysfunctional in terms of your normal routine, we urge
                you to take up important measures and adopt safe practices which can facilitate choosing the option of
                self-exclusion, and assists you in seeking the required help from relevant entities.
            </p>
            <h5>How do I know if I’m playing responsibly?</h5>
            <p>
                It may be a daunting task to determine if your gaming style is healthy and normal or if it is inching towards
                compulsive behavior. If you are doubtful about where you stand in terms of your gaming, it is time to ask
                yourself these questions:
            </p>
            <p>1. Has gambling ever been the reason for skipping class or work? </p>
            <p>2. Is gambling a way to pass your time or evade boredom?</p>
            <p>3. Does your gambling stretch over a long period even when you are playing alone?</p>
            <p>4. Have people around you disapproved of your gambling habits?</p>
            <p>5. Do you pay less interest in or attention to family, friends, or hobbies due to gambling?</p>
            <p>6. Did you ever steal or borrow money to sustain your gambling habit?</p>
            <p>
                7. Do you find yourself unwilling to spend the money you deemed to be used for gambling, on anything else?{" "}
            </p>
            <p>8. Does your gambling extend until you lose all your money?</p>
            <p>9. Once you lose the money, do you feel the need to earn it back as soon as possible?</p>
            <p>
                10. Do you feel a sense of loss and despair every time you lose money through gambling and do you make efforts
                to gamble right after?{" "}
            </p>
            <p>11. Do bad days push you to gamble even more?</p>
            <p>12. Has gambling ever made you feel depressed or even suicidal?</p>
            <p>
                In case you’ve found yourself saying yes to many of these questions, you should consider contacting an
                organization that assists individuals with gambling counseling, some of which have been listed below.
            </p>
            <h5>Gambling Therapy</h5>
            <p>
                Gambling Therapy offers support and counseling in various languages to any person who feels they are facing
                negative consequences through gambling.
            </p>
            <a href="https://www.gamblingtherapy.org" target="_blank" rel="noopener noreferrer">
                www.gamblingtherapy.org
            </a>
            <h5>Gamblers Anonymous</h5>
            <p>
                A fellowship of several people who have united to help each other get through their addiction to gambling and
                also assist others who are dealing with similar problems due to online gaming.
            </p>
            <a href="https://www.gamblersanonymous.org.uk" target="_blank" rel="noopener noreferrer">
                www.gamblersanonymous.org.uk
            </a>
            <h5>The Responsible Gaming Foundation</h5>
            <p>
                This foundation supports people who are unable to play responsibly and they also offer support and guidance to
                the family members of such individuals. You can connect with them for free at 1777, or chat directly with
                their staff through their website.
            </p>
            <a href="https://www.rgf.org.mt" target="_blank" rel="noopener noreferrer">
                www.rgf.org.mt
            </a>
            <h5>Be Gamble Aware </h5>
            <p>
                BeGambleAware® is managed and sponsored by an independent charity GambleAware®, which aims to protect people
                from gambling-related risks.
            </p>
            <a href="https://www.begambleaware.org" target="_blank" rel="noopener noreferrer">
                www.begambleaware.org
            </a>
            <h5>Protecting Minors</h5>
            <p>
                Minors are prohibited from playing casino online and the parents are responsible for ensuring their children
                do not partake in any such activities. We recommend you monitor your children’s online activities and prevent
                their access to such websites using filtering software
            </p>
            <p>
                In case you have a shared computer which is accessed by those who are legally minors or those who have
                requested self-exclusion from other gambling sites, please consider utilizing filtering solutions like:
            </p>
            <a href="https://www.netnanny.com" target="_blank" rel="noopener noreferrer">
                www.netnanny.com
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="https://www.gamban.com" target="_blank" rel="noopener noreferrer">
                www.gamban.com
            </a>
        </>
    );
}
