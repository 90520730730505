import { Caption } from "src/affiliate/styled/typography.styled";
import CopyClip from "src/affiliate/components/reusable/copy-clip/CopyClip";
import dateFormat from "src/affiliate/utils/dateFormat";
import { StyledFlexContainer, StyledH5 } from "src/affiliate/components/dash-board/assets/styles/dasboard-blog.styled";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";

interface IDashboardBlogProps {
    name: string;
    link: string;
    createdAt: string;
}

const DashboardBlog = ({ name, link, createdAt }: IDashboardBlogProps) => (
    <>
        <StyledFlexContainer
            alignItems="center"
            mobilePadding="16px"
            mobileFlexDirection="column"
            padding="24px"
            justifyContent="space-between"
        >
            <FlexContainer flexDirection="column" mobileWidth="100%" mobileMargin="0 0 31px 0">
                <StyledH5>{name}</StyledH5>
                <Caption color="#c7baf1">{dateFormat(createdAt)}</Caption>
            </FlexContainer>
            <FlexItem width="65%" mobileWidth="100%">
                <CopyClip link={link} />
            </FlexItem>
        </StyledFlexContainer>
    </>
);

export default DashboardBlog;
