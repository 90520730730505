import { createWeb3Modal } from "@web3modal/wagmi/react"
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config"

import { WagmiProvider } from "wagmi"
import { arbitrum, mainnet } from "wagmi/chains"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// 0. Setup queryClient
const queryClient = new QueryClient()

const hostToProjectMap = {
    "satocash.com": "428b9ecf64b9d839b608b217fabc82cc",
    "localweb.satocash.com": "428b9ecf64b9d839b608b217fabc82cc",
}

const host = window.location.host;

// 2. Create wagmiConfig
const metadata = {
    name: "ntropy-test",
    description: "AppKit Example",
    url: `https://${host}/`, // origin must match your domain & subdomain
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
}

const chains = [mainnet, arbitrum] as const
const config = defaultWagmiConfig({
    chains,
    projectId: hostToProjectMap[host],
    metadata,
    auth: {
        email: false,
    },
})

// 3. Create modal
createWeb3Modal({
    wagmiConfig: config,
    projectId: hostToProjectMap[host],
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true, // Optional - false as default
})

export function Web3ModalProvider({ children }) {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </WagmiProvider>
    )
}