import RelativeLink from "src/components/relative-link/RelativeLink";
import {
    StyledRealTimePayment,
    StyledCaption,
} from "src/affiliate/components/affiliate-landing/section-three/payments/assets/styles/real-time-payments.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { StyledButton } from "src/affiliate/components/affiliate-landing/section-one/assets/styles/paid-afiliate.styled";

const RealTimePayments = () => (
    <FlexContainer
        flexDirection="column"
        width="60%"
        tabletWidth="100%"
        tabletPadding="0 0 70px 0"
        mobilePadding="0 0 40px 0"
    >
        <StyledRealTimePayment>HOW REAL TIME PAYMENTS WORK</StyledRealTimePayment>
        <StyledCaption margin="16px 0 32px 0" tabletMargin="0 0 16px 0" className="xlarge">
            Our system automatically pays you as your referees' wager in Peergame. Plus there is no need to worry about
            withdrawing your commissions from us. Connect your private wallet, and your commissions will be directly deposited
            into it. Enjoy your own wallet balance grow in real-time for a lifetime.
        </StyledCaption>
        <StyledCaption margin="0 0 40px 0" tabletMargin="0 0 20px 0" mobileMargin="0 0 32px 0" className="xlarge">
            Don’t miss your chance, Sign up today!
        </StyledCaption>
        <StyledButton as={RelativeLink} to="/registration" width="328px">
            BECOME A PARTNER
        </StyledButton>
    </FlexContainer>
);

export default RealTimePayments;
