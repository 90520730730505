import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";

import ImgPartners from "./assets/partners-banner-image.png";

interface IPartnersBannerProps {
    isMobileOnly: boolean
}

const cx = classNames.bind(styles);

export default function PartnersBanner({ isMobileOnly }: IPartnersBannerProps) {
    function getContent() {
        if (isMobileOnly) {
            return (
                <>
                    <div className={cx("text-section")}>
                        <span>Be Our Partner</span>
                        <span className={cx("xlarge", "bold")}>Earn Money</span>
                    </div>
                    <Link className={cx("bold")} to="/partners">
                        Invite
                    </Link>
                    <img src={ImgPartners} alt="Peergame partners" />
                </>
            );
        }
        return (
            <>
                <div className={cx("text-section")}>
                    <span className={cx("xlarge")}>Be Our Partner</span>
                    <h3>Earn Money</h3>
                    <Link className={cx("bold")} to="/partners">
                        Refer a Friend
                    </Link>
                </div>
                <img src={ImgPartners} alt="Peergame partners" />
            </>
        );
    }

    return <div className={cx("partners-banner")}>{getContent()}</div>;
}
