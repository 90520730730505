import { axiosInstance } from "src/core/http/axios-instance";
import config from "src/config";
import { CryptoCurrency } from "src/core/currency/currency.model";

export interface ISingleGameHistory {
    game_id: number;
    area: string;
    amount: number;
    balance: number;
    asset: CryptoCurrency;
    multiplier: string | number;
    payout: number;
    payout_balance: number | null;
    winning_rate: number | null;
    winner: boolean;
    winner_player: string | null;
    game_result: string;
    game: string;
    player_id: number;
    id: number;
    status: string;
    txid: string;
    payout_txid: string | null;
    txid_for_url: string;
    game_name: string;
}

export interface IGameHistoryResponse {
    timestamp: number;
    data: ISingleGameHistory[]
}

interface IGameHistoryParams {
    betStatus?: string
    pageParam?: string | null
    count?: number
    withGameResultOnly?: boolean
}

export async function getGameHistory(
    gameCode: string, // TODO: replace with enum
    {
        count,
        pageParam,
        betStatus,
        withGameResultOnly,
    }: IGameHistoryParams,
) {
    const lastIdParamName = gameCode === "cfBattle" || gameCode === "blackjack" ? "last_id" : "last_game_id";

    const { data } = await axiosInstance.get<IGameHistoryResponse>("/v1/mypage/gamehistory", {
        params: {
            asset: config.asset,
            count,
            [lastIdParamName]: pageParam,
            game_code: gameCode,
            status: betStatus,
        },
    })

    if (withGameResultOnly) {
        return data.filter(({ game_result: gameResult }) => !!gameResult);
    } else {
        return data;
    }
}