import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledTabHeader = styled(FlexContainer)`
    width: 100%;
    border-bottom: 1px solid rgba(199, 186, 241, 0.2);
    margin-bottom: 4px;
`;

export const StyledTabTitle = styled.p<{ visited?: boolean }>`
    cursor: pointer;
    padding-bottom: 4px;
    margin-right: 40px;
    display: inline-block;
    position: relative;
    color: ${({ visited }) => (visited ? "#ff2976" : "#6e658a")};
    transition: all 0.3s ease-in;

    &:hover {
        color: ${({ visited }) => (visited ? "" : " #c7baf1")};
    }

    &:after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -1px;
        display: block;
        background-color: ${({ visited }) => (visited ? "#ff2976" : "")};
        transition: all 0.3s ease-in;
    }
`;