import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import Input from "src/components/input/Input";
import Detail from "src/components/detail/Detail";

const cx = classNames.bind(styles);

interface IRandomNumberProps {
    txIds?: string[];
    seedHash?: string;
    onTxIdChange?: (index: number, newValue: string | number) => void;
    isCardOrder?: boolean;
}

const RandomNumber = ({ txIds, seedHash, onTxIdChange, isCardOrder }: IRandomNumberProps) => {
    function getContent() {
        return (
            <div className={cx("hidden")}>
                <span className={cx("small")}>{seedHash}</span>
                {txIds?.map((txId, index) => (
                    <div className={cx("mt-8", "flex-center")} key={index}>
                        <span className={cx("small")}>+</span>
                        <Input value={txId} className={cx("list-detail")} onChange={newValue => onTxIdChange?.(index, newValue)} />
                    </div>
                ))}
                {isCardOrder && (
                    <>
                        <hr className={cx("hr")} />
                        <div>
                            <span>+</span>
                            <span className={cx("algorithm", "small")}>Fisher-Yates Shuffle Algorithm</span>
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <Detail
            className={cx("random-number")}
            contentClassName={cx("content")}
            label={isCardOrder ? "Card Order" : "Random Number"}
            content={getContent()}
            copyText={seedHash}
            copiable={!!seedHash}
        />
    );
};

export default RandomNumber;
