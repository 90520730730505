import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledMessageContainer = styled(FlexContainer)<{ error?: boolean }>`
    width: 100%;
    height: auto;
    min-height: 40px;
    border-radius: 4px;
    background-color: ${({ error }) => (error ? "#ffcbde" : "#c5fff1")};
    justify-content: flex-start;
    align-items: center;
    padding: 8px;

    p {
        color: ${({ error }) => (error ? "#d10a52" : "#066f55")};
    }

    img {
        width: 20px;
        margin-right: 8px;
    }
`;