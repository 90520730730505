import styled from "styled-components";

export const StyledDiv = styled.div<{ img: string }>`
    width: 100%;
    height: 189px;
    border-radius: 6px;
    background-repeat: no-repeat;
    background-image: ${({ img }) => `url(${img})`};
    background-size: 100% 100%;
    position: relative;
    display: block;
    @media only screen and (max-width: 568px) {
        height: 140px;
    }
`;

export const StyledTitle = styled.p`
    color: #ffffff;
    margin-bottom: 16px;
    @media only screen and (max-width: 568px) {
        margin: 4px 0 8px 0;
    }
`;