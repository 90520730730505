import BigNumber from "bignumber.js";
import dateFormat from "src/affiliate/utils/dateFormat";
import bsvIcon from "./assets/bsv-icon.svg";
import { StyledTableContainer, StyledTable, StyledTr, StyledTh, StyledTbody, StyledTd } from "src/affiliate/components/reusable/assets/styles/table.styled";
import Pagination from "src/affiliate/components/reusable/Pagination";
import EmptyState from "src/affiliate/components/reusable/EmptyState";
import React from "react";
import { IPagination } from "src/affiliate/components/reusable/types/pagination";

interface ITableProps {
    headerTitles: any;
    bodyValues: any[];
    rowKey: string;
    prevPage?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    changePage?: (id: number, e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    nextPage?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    pagination?: IPagination[];
    withPagination?: boolean;
    transactions?: boolean;
    textAlign?: string;
}

const Table = ({
    pagination = [],
    nextPage,
    changePage,
    prevPage,
    transactions = false,
    withPagination = false,
    textAlign = "",
    rowKey,
    headerTitles,
    bodyValues,
}: ITableProps) => (
    <>
        <StyledTableContainer>
            <StyledTable>
                <thead>
                <StyledTr transparent borderBottom className="small">
                    {Object.keys(headerTitles).map(title => (
                        <StyledTh textAlign={textAlign} key={title}>
                            <div className="flex items-center gap-2">
                                {headerTitles[title]}
                                {headerTitles[title] === headerTitles.wagered ||
                                headerTitles[title] === headerTitles.commissions ||
                                headerTitles[title] === headerTitles.commission ||
                                headerTitles[title] === headerTitles.amount ? (
                                    <img src={bsvIcon} alt="icon" />
                                ) : null}
                            </div>
                        </StyledTh>
                    ))}
                </StyledTr>
                </thead>
                {bodyValues.length ? (
                    <StyledTbody>
                        {transactions
                            ? bodyValues.map(item => (
                                <StyledTr key={item[rowKey]}>
                                    {Object.keys(headerTitles).map(key => {
                                        if (key === "created_at") {
                                            return (
                                                <StyledTd key={key}>
                                                    {dateFormat(item[key])}
                                                </StyledTd>
                                            );
                                        }
                                        if (key === "game_name") {
                                            return <StyledTd key={key}>{item[key].charAt(0).toUpperCase() + item[key].slice(1)}</StyledTd>;
                                        }
                                        return (
                                            <StyledTd textAlign={textAlign} key={key} transactions={transactions && key === rowKey}>
                                                {typeof item[key] === "number" ? new BigNumber(item[key]).toFixed() : item[key]}
                                            </StyledTd>
                                        );
                                    })}
                                </StyledTr>
                            ))
                            : bodyValues.map(item => (
                                <StyledTr key={item[rowKey]}>
                                    {Object.keys(headerTitles).map(key => key === "created_at" ? (
                                        <StyledTd key={key}>
                                            {dateFormat(item[key])}
                                        </StyledTd>
                                    ) : (
                                        <StyledTd
                                            textAlign={textAlign}
                                            key={key}
                                        >
                                            {typeof item[key] === "number" ? new BigNumber(item[key]).toFixed() : item[key]}
                                        </StyledTd>
                                    ))}
                                </StyledTr>
                            ))}
                    </StyledTbody>
                ) : null}
            </StyledTable>
        </StyledTableContainer>
        {withPagination ? (
            <Pagination prevPage={prevPage} pagination={pagination} nextPage={nextPage} changePage={changePage} />
        ) : null}
        {bodyValues.length ? null : <EmptyState />}
    </>
);

export default React.memo(Table);
