import classNames from "classnames/bind";
import { Switch, Route, Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import styles from "./assets/styles.scss";
import PageContainer from "src/containers/PageContainer/PageContainer";
import RelativeLink from "src/components/relative-link/RelativeLink";
import Protected from "src/components/protected/Protected";
import { routes, basePath } from "src/containers/Account/accountRoutes";
import { useMobileOnly } from "src/helpers/responsive";

const cx = classNames.bind(styles);

export default function Account(): JSX.Element {
    const { pathname } = useLocation();
    const isMobileOnly = useMobileOnly();

    return (
        <PageContainer
            showFooter
            showHeader
            dark
            pageTitle={routes.find(({ path }) => pathname === `${basePath}${path}`)?.label || ""}
            contentClass={cx("account")}
        >
            <Protected requireLogin>
                {!isMobileOnly ? (
                    <div className={cx("navigation")}>
                        {routes.map(({ label, path, icon }) => (
                            <RelativeLink key={path} to={path} className={cx({ active: pathname === `${basePath}${path}` })}>
                                {icon}
                                {label}
                            </RelativeLink>
                        ))}
                    </div>
                ) : null}
                <Switch>
                    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                    {routes.map(({ path, component }) => (
                        <Route key={path} path={`${basePath}${path}`} exact>
                            {component}
                        </Route>
                    ))}
                </Switch>
            </Protected>
        </PageContainer>
    );
}
