import Button from "src/affiliate/components/reusable/Button";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import { StyledCaption, StyledSpan } from "src/affiliate/components/campaigns/assets/styles/campaign-delete-modal-content.styled";

interface ICampaignDeleteModalContentProps {
    onCancel: () => void;
    onDelete: () => void;
}

const CampaignDeleteModalContent = ({ onCancel, onDelete }: ICampaignDeleteModalContentProps) => (
    <>
        <FlexContainer margin="32px 0 0 0" flexWrap tabletFlexWrap mobileFlexWrap justifyContent="space-between">
            <FlexItem width="100%">
                <StyledCaption>
                    You will permanently lose your <StyledSpan className="bold">campaign url</StyledSpan> and{" "}
                    <StyledSpan className="bold">campaign history</StyledSpan>.
                </StyledCaption>
            </FlexItem>
            <FlexItem width="48%">
                <Button onClick={() => onCancel()} buttonShape="bordered">
                    Cancel
                </Button>
            </FlexItem>
            <FlexItem width="48%">
                <Button onClick={() => onDelete()}>Delete</Button>
            </FlexItem>
        </FlexContainer>
    </>
);

export default CampaignDeleteModalContent;
