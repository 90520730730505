import { axiosInstance } from "src/core/http/axios-instance";

export interface IAffiliateState {
    loaded: boolean;
    email?: string;
    status?: string;
    [key: string]: any; // To allow any additional properties in the state
}

export const LOAD = "affiliate/LOAD";
export const LOAD_SUCCESS = "affiliate/LOAD_SUCCESS";
export const LOAD_FAIL = "affiliate/LOAD_FAIL";
export const SIGNUP = "affiliate/SIGNUP";
export const SIGNUP_SUCCESS = "affiliate/SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "affiliate/SIGNUP_FAIL";
export const VERIFY_EMAIL = "affiliate/VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "affiliate/VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAIL = "affiliate/VERIFY_EMAIL_FAIL";
export const UPDATE_SETTINGS = "affiliate/UPDATE_SETTINGS";
export const UPDATE_SETTINGS_SUCCESS = "affiliate/UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAIL = "affiliate/UPDATE_SETTINGS_FAIL";
export const LOGOUT = "game/auth/LOGOUT";

// Action interfaces
interface ILoadSuccessAction {
    type: typeof LOAD_SUCCESS;
    result: Partial<IAffiliateState>;
}

interface ILoadFailAction {
    type: typeof LOAD_FAIL;
}

interface ISignUpSuccessAction {
    type: typeof SIGNUP_SUCCESS;
    result: string;
}

interface IVerifyEmailSuccessAction {
    type: typeof VERIFY_EMAIL_SUCCESS;
    result: { email?: string };
}

interface IVerifyEmailFailAction {
    type: typeof VERIFY_EMAIL_FAIL;
}

interface IUpdateSettingsSuccessAction {
    type: typeof UPDATE_SETTINGS_SUCCESS;
    result: Partial<IAffiliateState>;
}

interface ILogoutAction {
    type: typeof LOGOUT;
}

type TAffiliateActionTypes =
    | ILoadSuccessAction
    | ILoadFailAction
    | ISignUpSuccessAction
    | IVerifyEmailSuccessAction
    | IVerifyEmailFailAction
    | IUpdateSettingsSuccessAction
    | ILogoutAction;

const initialState: IAffiliateState = {
    loaded: false,
};

export default function affiliateReducer(
    state = initialState,
    action: TAffiliateActionTypes
): IAffiliateState {
    switch (action.type) {
        case LOAD_SUCCESS:
            return {
                ...state,
                ...action.result,
                loaded: true,
            };
        case LOAD_FAIL:
            return {
                ...state,
                loaded: true,
            };
        case VERIFY_EMAIL_SUCCESS:
            if (action.result.email) {
                return { ...state, email: action.result.email };
            }
            return {
                ...state,
                status: "REVIEWING",
            };
        case VERIFY_EMAIL_FAIL:
            return {
                ...state,
                status: "PENDING",
            };
        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.result,
            };
        default:
            return state;
    }
}

// Define the return types for each action
interface Client {
    get: (url: string) => Promise<any>;
    post: (url: string, data?: any) => Promise<any>;
    put: (url: string, data?: any) => Promise<any>;
}

export function load() {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: async () => axiosInstance.get("/api/v1/affiliate/account/info"),
    };
}

export function signUp(registrationData: { email: string }) {
    return {
        types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
        promise: async () => {
            await axiosInstance.post("/api/v1/affiliate/auth/signup", registrationData);
            return registrationData.email;
        },
    };
}

export function verifyEmail(authToken: string) {
    return {
        types: [VERIFY_EMAIL, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAIL],
        promise: async () => axiosInstance.post(`/affiliate/auth/verify/${authToken}`),
    };
}

export function updateSettings(updates: { payout_limit?: number; report_enable?: boolean }) {
    return {
        types: [UPDATE_SETTINGS, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAIL],
        promise: async () => {
            const { payout_limit, report_enable } = updates;
            if (payout_limit) {
                await axiosInstance.put("/api/v1/affiliate/account/payoutlimit", { payout_limit });
            } else if (report_enable) {
                await axiosInstance.put("/api/v1/affiliate/account/report", { report_enable });
            }
            return updates;
        },
    };
}