import styled from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledFlexContainer = styled(FlexContainer)`
    display: none;

    @media only screen and (max-width: 568px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 80px;
    }
`;