import { CryptoCurrency } from "src/core/currency/currency.model";
import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";

// value is {number}$ for 1{CryptoCurrency}, ex. _60569.33_ USD for 1 BTC
type ICryptoRates = Partial<Record<CryptoCurrency, number>>;

interface IUseExchangeRatesInput<TResult = ICryptoRates> {
    enabled?: boolean
    select?(data: ICryptoRates): TResult
}

export function useExchangeRates<TResult = ICryptoRates>({
    enabled = true,
    select,
}: IUseExchangeRatesInput<TResult> = {}): [TResult, boolean] {
    const { data, isLoading } = useQuery({
        queryKey: ["exchange-rates"],
        initialData: {} as ICryptoRates,
        queryFn: () => axiosInstance.get<ICryptoRates>(Endpoint.Rates),
        select,
        enabled,
    })

    return [data, isLoading];
}