import Button from "src/affiliate/components/reusable/Button";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import { StyledDiv, StyledTitle } from "src/affiliate/components/resources/assets/styles/resources-card.styled";

interface IResourcesCardProps {
    brandTitle: string;
    img: string;
    href: string;
}

const ResourcesCard = ({ brandTitle, img, href }: IResourcesCardProps) => (
    <>
        <FlexContainer justifyContent="space-between" margin="0 0 32px 0" mobileFlexDirection="column">
            <FlexItem width="60%" mobileWidth="100%">
                <StyledDiv img={img} />
            </FlexItem>
            <FlexContainer flexDirection="column" width="34%" mobileWidth="100%" justifyContent="flex-end">
                <StyledTitle className="xlarge bold">{brandTitle}</StyledTitle>
                <a href={href} target="_blank" rel="noreferrer noopener">
                    <Button width="175px">
                        Download
                    </Button>
                </a>
            </FlexContainer>
        </FlexContainer>
    </>
);

export default ResourcesCard;
