import classNames from "classnames/bind";
import { useQuery } from "@tanstack/react-query";
import styles from "./assets/styles.scss";
import useCountdown from "src/helpers/use-countdown";

import ImgRaces from "./assets/races-logo.png";
import { axiosInstance } from "src/core/http/axios-instance";
import { IRacesLeaderboardRankingResponse } from "src/components/stats-table/Races/races.model";

const cx = classNames.bind(styles);

async function getSettings() {
    const { data } = await axiosInstance.get<IRacesLeaderboardRankingResponse>("/v1/leaderboard/setting");

    return data;
}

export default function RacesBanner() {
    const { data: settings } = useQuery({ queryKey: ["settings", "races"], queryFn: getSettings });
    const { end_time: endTime } = settings?.weeklyRace || {};
    const {
        days, hours, minutes, seconds,
    } = useCountdown(endTime);

    return (
        <div className={cx("races-banner")}>
            <div className={cx("text-section")}>
                <h3>
                    PRIZE POOL <br /> UP TO <span>$2000</span>
                </h3>
                <p className={cx("large")}>The best way to enjoy Peergame</p>
                <div className={cx("countdown-timer")}>
                    <p className={cx("xsmall")}>REMAINING TIME</p>
                    <div className={cx("timer-row")}>
                        <div className={cx("time-column")}>
                            <div>{days}</div>
                            <span className={cx("xsmall")}>Day</span>
                        </div>
                        <div className={cx("time-column")}>
                            <div>{hours}</div>
                            <span className={cx("xsmall")}>Hour</span>
                        </div>
                        <div className={cx("time-column")}>
                            <div>{minutes}</div>
                            <span className={cx("xsmall")}>Min</span>
                        </div>
                        <div className={cx("time-column")}>
                            <div>{seconds}</div>
                            <span className={cx("xsmall")}>Sec</span>
                        </div>
                    </div>
                </div>
            </div>
            <img src={ImgRaces} alt="Peergame races" />
        </div>
    );
}
