import styled from "styled-components";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";

export const StyledFlexContainer = styled(FlexContainer)`
    border-radius: 4px;
    background-color: #22096c;
    @media only screen and (max-width: 568px) {
        border-radius: 0;
    }
`;

export const StyledH3 = styled.h5`
    margin: 0 0 8px 0;
    color: #ffffff;
    white-space: nowrap;
`;

export const StyledIconsContainer = styled(FlexContainer)`
    display: none;

    img {
        cursor: pointer;
    }

    @media only screen and (max-width: 568px) {
        display: flex;
        align-items: baseline;
    }
`;

export const StyledSettingsIconContainer = styled(FlexItem)`
    display: flex;

    img {
        cursor: pointer;
        padding-top: 13px;
        margin: 0 33px 0 0;
    }

    @media only screen and (max-width: 568px) {
        display: none;
    }
`;
