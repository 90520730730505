import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./styles.scss";
import IconPlay from "./play-circle.svg?inline";

interface IGameThumbnailProps {
    title: string;
    url: string;
    thumbnailSrc: string;
    provider: string;
}

const cx = classNames.bind(styles);

export default function GameThumbnail({
    title, url, thumbnailSrc, provider,
}: IGameThumbnailProps) {
    return (
        <Link to={url} className={cx("thumbnail")}>
            <img src={thumbnailSrc} alt={title} />
            <div className={cx("hover-overlay")}>
                <span>{title}</span>
                <IconPlay />
                <span className={cx("small")}>{provider}</span>
            </div>
        </Link>
    );
}

