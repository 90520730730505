export default function useModulusBy(gameCode) {
    switch (gameCode) {
        case "roulette":
            return 37;
        case "dice":
            return 10000;
        case "coinflip":
            return 2;
        case "wheel":
            return 54;
        case "turtle":
            return 6;
        case "ladder":
            return 4;
        default:
            return 2;
    }
}
