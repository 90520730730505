import classNames from "classnames/bind";

import ImgBitcoinSV from "./assets/bitcoin-sv.jpg";
import ImgBitcoinSVDiagram from "./assets/what_is_bitcoinsv.png";

import styles from "./assets/styles.scss";

const cx = classNames.bind(styles);

export default function BitcoinSV() {
    return (
        <div className={cx("bitcoin-sv")}>
            <img src={ImgBitcoinSV} alt="" />
            <p>
                Bitcoin Satoshi Vision, commonly known as Bitcoin SV (BSV), is the original Bitcoin protocol described in the
                White paper. The Bitcoin Protocol restored to its unique design with Bitcoin SV.
            </p>
            <p>
                Bitcoin SV is more than just a digital currency for global payments; it is also an immutable global public data
                ledger. It is the best solution in the market for enterprises to create blockchain applications and boost their
                businesses.
            </p>
            <img src={ImgBitcoinSVDiagram} alt="" />
            <p>
                The protocol developers using the BTC ticker symbol decided to fork off of the original Bitcoin Protocol and
                take their ticker symbol when they integrated Segregated Witness into their node software on August 1st, 2017.
                They have also further deviated from the Bitcoin Protocol by creating an off-chain payment channel system for
                processing transactions called the Lightning Network and using the Bitcoin Blockchain as a settlement ledger for
                Lightning transactions.
            </p>
            <p>
                The protocol developers using the BCH ticker symbol decided to fork off of the original Bitcoin Protocol and
                take their ticker symbol when they integrated CTOR (Canonical Transaction Ordering) and OP_checkdatasig – both
                are deviations from Bitcoin’s original design - into their node software on November 15th, 2018. Then both on
                the first day of the hash war and also 3 days later (on November 18) at the height of the Hash War, they further
                deviated from Bitcoin’s design by adding Check Points to their new protocol (centralized decision making for
                developers, rather than miner consensus to decide the true blockchain).
            </p>
            <h5>What Are The Advantages Of Bitcoin SV?</h5>
            <p>The main advantages of Bitcoin SV (BSV) include:</p>
            <p>
                <b>Stability:</b> Eliminate technical and economic uncertainty by fixing protocols.
            </p>
            <p>
                <b>Scalability:</b> Capable of processing large and large data.
            </p>
            <p>
                <b>Security:</b> Security beyond the financial system level.
            </p>
            <p>
                <b>Neutrality:</b> Perminsionless Network that anyone can access and use.
            </p>
            <p>
                <b>Regulation-friendly environment:</b> Securing legal safety of blockchain use.
            </p>
            <p>
                <b>Low fee and Safe instant data transaction:</b> Easy and Essential for user experience.
            </p>
            <h5>Why Peergame uses Bitcoin SV (BSV)?</h5>
            <p>
                BSV is the only blockchain network that can provide the realization of our mission, Casino 2.0. Thanks to the
                advantages of Bitcoin SV, users can enjoy a frictionless, fast, and user-friendly experience. Bitcoin SV
                provides “stable protocol and massive on-chain scaling.” Thanks to these features, it is the most efficient
                technology to build a business on this foundation.
            </p>
            <h5>How To Buy And Where To Store Bitcoin SV (BSV)</h5>
            <p>
                The best way to buy Bitcoin SV (BSV) is from a popular exchange. Make sure to choose a reputable cryptocurrency
                exchange when buying Bitcoin SV. Alternatively, you can purchase using a credit card quickly and simply through{" "}
                <a href="https://buybsv.com/" target="_blank" rel="noopener noreferrer">
                    buybsv.com
                </a>
                . When it comes to storing Bitcoin SV, you need to choose a secure wallet.
            </p>
            <a className={cx("button")} href="https://buybsv.com/" target="_blank" rel="noopener noreferrer">
                Buy <b>BSV</b>
            </a>
        </div>
    );
}
