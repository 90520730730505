import styled, { css } from "styled-components";

export const StyledTable = styled.table`
    width: 100%;
`;

export const StyledTbody = styled.tbody`
    &:before {
        content: '-';
        display: block;
        line-height: 1px;
        color: transparent;
    }
`;

export const StyledTr = styled.tr<{
    transparent?: boolean;
    selected?: boolean;
    borderBottom?: boolean;
}>`
    background-color: ${({ transparent }) => (transparent ? "transparent" : "#1f0d53")};
    width: 100%;
    box-shadow: ${({ selected }) => (selected ? "inset 0 0 60px 0 rgba(255, 41, 118, 0.7)" : "")};
    border-bottom: ${({ borderBottom }) => (borderBottom ? "1px solid rgba(199, 186, 241, 0.2)" : "4px solid #12033d")};

}
`;

export const StyledTh = styled.th<{
    textAlign?: string;
    transactions?: boolean;
}>`
    padding: 8px 0 8px 24px;
    white-space: nowrap;
    color: #f0ecff;
    text-align: left;

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        padding: 12px 24px 12px 24px;
        text-align: end;
    }

    &:nth-child(3) {
        text-align: ${({ textAlign }) => textAlign};
    }

    ${({ transactions }) => transactions
        ? css`
            &:nth-child(3) {
                width: 120px;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        `
        : ""}
    img {
        width: 14px;
        margin-left: 4px;
    }

    span {
        opacity: 0.6;
    }
`;

export const StyledTd = styled.td<{
    textAlign?: string;
    transactions?: boolean;
}>`
    padding: 12px 0 12px 24px;
    text-align: left;
    color: ${({ color, theme }) => (color ? "#ffbd3e" : theme.color.white)};

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        padding: 12px 24px 12px 24px;
        text-align: end;
    }

    &:nth-child(3) {
        text-align: ${({ textAlign }) => textAlign};
    }

    ${({ transactions }) => transactions
        ? css`
            &:nth-child(3) {
                width: 120px;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        `
        : ""}
`;

export const StyledTableContainer = styled.div`
    overflow-x: auto;
`;