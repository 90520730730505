import { memo } from "react";
import { Caption } from "src/affiliate/styled/typography.styled";
import settingsIcon from "./assets/Settings.svg";
import deleteIcon from "./assets/Delete.svg";
import DataCard from "src/affiliate/components/reusable/DataCard";
import CopyClip from "src/affiliate/components/reusable/copy-clip/CopyClip";
import dateFormat from "src/affiliate/utils/dateFormat";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import {
    StyledH3,
    StyledFlexContainer,
    StyledIconsContainer,
    StyledSettingsIconContainer,
} from "src/affiliate/components/campaigns/assets/styles/campaigns-blog.styled";

interface ICampaignsBlogProps {
    name: string;
    visits: number;
    referrals: number;
    wagered: number;
    commission: number;
    link: string;
    createdAt: string | Date;
    deleteOnOpen: () => void;
    editOnOpen: () => void;
}

const CampaignsBlog = ({
    name,
    visits,
    referrals,
    wagered,
    commission,
    link,
    createdAt,
    deleteOnOpen,
    editOnOpen,
}: ICampaignsBlogProps) => (
    <StyledFlexContainer mobilePadding="16px" padding="32px" flexDirection="column" margin="0 0 8px 0">
        <FlexContainer
            mobileFlexWrap
            width="100%"
            justifyContent="space-between"
            mobileJustifyContent="space-between"
            tabletFlexWrap
        >
            <FlexContainer flexDirection="column" width="27%" mobileWidth="57%" mobileMargin="0 0 31px 0">
                <StyledH3>{name}</StyledH3>
                <Caption color="#c7baf1">{dateFormat(createdAt)}</Caption>
            </FlexContainer>

            <StyledIconsContainer
                mobileJustifyContent={name === "Default Campaign" ? "flex-end" : "space-between"}
                mobileWidth="20%"
            >
                <FlexItem onClick={() => editOnOpen()}>
                    <img src={settingsIcon} alt="settingIcon" />
                </FlexItem>
                {name === "Default Campaign" ? null : (
                    <FlexItem onClick={() => deleteOnOpen()}>
                        <img src={deleteIcon} alt="deleteIcon" />
                    </FlexItem>
                )}
            </StyledIconsContainer>

            <FlexContainer
                justifyContent="space-between"
                mobileJustifyContent="space-between"
                tabletJustifyContent="flex-end"
                width="69%"
                tabletWidth="100%"
                mobileFlexWrap
                tabletMargin="24px 0 0 0"
            >
                <FlexItem width="19.5%" mobileWidth="49%" tabletWidth="16%">
                    <DataCard campaignBlogCard name="Visits" value={visits} padding="16px 0 18px 16px" gradientDirection />
                </FlexItem>
                <FlexItem width="19.5%" mobileWidth="49%" tabletWidth="16%" tabletMargin="0 0 0 8px">
                    <DataCard campaignBlogCard name="Referrals" value={referrals} padding="16px 0 18px 16px" gradientDirection />
                </FlexItem>
                <FlexItem width="28%" mobileWidth="49%" tabletWidth="23%" tabletMargin="0 0 0 8px">
                    <DataCard
                        campaignBlogCard
                        name="Wagered"
                        value={wagered}
                        padding="16px 0 18px 16px"
                        gradientDirection
                        bsIcon
                    />
                </FlexItem>
                <FlexItem width="28%" mobileWidth="49%" tabletWidth="23%" tabletMargin="0 0 0 8px">
                    <DataCard
                        campaignBlogCard
                        name="Commission"
                        value={commission}
                        padding="16px 0 18px 16px"
                        gradientDirection
                        bsIcon
                    />
                </FlexItem>
            </FlexContainer>
        </FlexContainer>

        <FlexContainer justifyContent="space-between" mobileMargin="24px 0 0 0" margin="24px 0 0 0">
            <StyledSettingsIconContainer width="50%" margin="0 0 0 0" mobileDisplay="none">
                <FlexItem onClick={() => editOnOpen()}>
                    <img src={settingsIcon} alt="settingsIcon" />
                </FlexItem>
                {name === "Default Campaign" ? null : (
                    <FlexItem onClick={() => deleteOnOpen()}>
                        {" "}
                        <img src={deleteIcon} alt="deleteIcon" />{" "}
                    </FlexItem>
                )}
            </StyledSettingsIconContainer>
            <FlexItem width="100%" mobileWidth="100%">
                <CopyClip link={link} />
            </FlexItem>
        </FlexContainer>
    </StyledFlexContainer>
);

export default memo(CampaignsBlog);
