import styled, { css } from "styled-components";

export const StyledLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 48px;
    height: 28px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
`;

export const StyledSpan = styled.span<{ backGroundColor?: boolean; transform?: boolean }>`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 6px 0 #414687;
    background-color: #050a37;

    ${({ backGroundColor }) => backGroundColor
        ? css`
            background-image: linear-gradient(to top, #e5c87a, #fc9803);
            background-repeat: no-repeat;
            background-size: 100%;
        `
        : css`
            background-image: linear-gradient(to top, #e5c87a, #fc9803);
            background-repeat: no-repeat;
            background-size: 0;
        `};
    transition: all 0.1s ease;
    border-radius: 100px;

    &:before {
        position: absolute;
        content: '';
        border-radius: 50%;
        width: 24px;
        height: 24px;
        left: 2px;
        bottom: 3px;
        background-color: #ffffff;
        box-shadow: 0 4px 8px 0 #3c1b63;
        ${({ transform }) => transform
            ? css`
                transform: translateX(20px);
            `
            : css`
                transform: translateX(0px);
            `};
        transition: all 0.1s ease;
    }
`;