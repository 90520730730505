import styled from "styled-components";

export const CheckboxContainer = styled.div<{ padding?: string }>`
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    padding: ${({ padding }) => padding};
`;

export const Icon = styled.svg`
    fill: none;
    stroke: white;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    border: solid 1px #c7baf1;
    background-color: transparent;
`;

export const StyledCheckbox = styled.div<{ checked?: boolean }>`
    background: ${({ checked }) => (checked ? "#08a57f" : "transparent")};
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: ${({ checked }) => (checked ? "1px solid transparent" : "solid 1px #c7baf1")};

    transition: all 150ms;

    ${Icon} {
        visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
    }
`;
