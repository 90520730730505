import Picture from "src/affiliate/components/reusable/Picture";
import commissionPlanImage from "../assets/Commission Plan Image.png";
import { COMMISSION_PLAN_DATA } from "src/affiliate/components/affiliate-landing/constant/constant";
import {
    StyledBestCommissions,
    StyledCaption,
    StyledImageContainer,
} from "src/affiliate/components/affiliate-landing/section-three/commission-plans/assets/styles/commision-plans.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import CommissionCard from "src/affiliate/components/affiliate-landing/section-three/commission-plans/CommissionCard";
import { StyledTitle } from "src/affiliate/components/affiliate-landing/section-two/assets/styles/section-two-main.styled";

const CommissionPlans = () => (
    <FlexContainer
        flexDirection="column"
        mobileAlignItems="baseline"
        alignItems="center"
        padding="120px 0 302px 0"
        tabletPadding="60px 60px"
        mobilePadding="60px 24px"
    >
        <StyledTitle>COMMISSION PLANS</StyledTitle>
        <FlexContainer
            width="100%"
            maxWidth="900px"
            padding="60px 0 0 0"
            mobilePadding="32px 0 0 0"
            justifyContent="space-between"
            tabletJustifyContent="center"
            alignItems="center"
            flexWrap
            tabletFlexWrap
            mobileFlexWrap
        >
            <FlexContainer flexDirection="column" width="50%" tabletWidth="100%" tabletPadding="0 0 70px 0" mobilePadding="0">
                <StyledBestCommissions>BASED ON WAGER, NOT REVENUE PARTNER UP AND EARN UP TO 40%</StyledBestCommissions>
                <StyledCaption className="xlarge">
                    We know you work hard, so Peergame Partners will reward you with some of the highest commissions in the online
                    gaming industry
                </StyledCaption>
            </FlexContainer>
            <StyledImageContainer width="210px">
                <Picture img={commissionPlanImage} />
            </StyledImageContainer>
        </FlexContainer>
        <FlexContainer
            width="100%"
            maxWidth="900px"
            padding="60px 0 0 0"
            mobilePadding="40px 0 0 0"
            justifyContent="space-between"
            tabletFlexWrap
            mobileFlexWrap
        >
            {COMMISSION_PLAN_DATA.map(({
                id, bsv, percentage, height,
            }) => (
                <CommissionCard key={id} height={height} bsv={bsv} percentage={percentage} />
            ))}
        </FlexContainer>
    </FlexContainer>
);

export default CommissionPlans;
