import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames/bind";
import { useSpring, animated } from "@react-spring/web";
import styles from "./assets/styles.scss";
import IconSelected from "./assets/selected.svg?inline";
import useClickOutside from "src/helpers/useClickOutside";
import IconArrowDown from "src/components/game/assets/images/arrow-down.svg?inline";

interface IItem {
    label: string;
    value: string | number;
}

interface IDropdownProps {
    items: IItem[];
    onChange: (newSelection: string | number) => void;
    selectedValue?: string | number | null;
}

const cx = classNames.bind(styles);

const PADDING_BOTTOM = 10;
const INITIAL_DROPDOWN_HEIGHT = 40;

export default function Dropdown({ items = [], selectedValue, onChange }: IDropdownProps) {
    const dropdownHeight = INITIAL_DROPDOWN_HEIGHT * items.length + PADDING_BOTTOM;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownStyle] = useSpring(
        {
            to: {
                height: isOpen ? dropdownHeight : 0,
                paddingBottom: isOpen ? PADDING_BOTTOM : 0,
            },
        },
        [isOpen]
    );

    useEffect(() => {
        function onScroll() {
            setIsOpen(false);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useClickOutside(() => {
        setIsOpen(false);
    }, [containerRef]);

    function onSelection(newSelection: string | number) {
        setIsOpen(false);
        onChange(newSelection);
    }

    return (
        <div ref={containerRef} className={cx("dropdown-container")}>
            <button type="button" className={cx("dropdown-trigger", { open: isOpen })} onClick={() => setIsOpen(!isOpen)}>
                {items.find(({ value }) => value === selectedValue)?.label}
                <IconArrowDown className={cx({ rotate: isOpen })} />
            </button>
            <animated.div style={dropdownStyle} className={cx("dropdown")}>
                {items.map(({ label, value }) => (
                    <button
                        key={label}
                        type="button"
                        className={cx({ selected: value === selectedValue })}
                        onClick={() => onSelection(value)}
                    >
                        {label}
                        {value === selectedValue && <IconSelected />}
                    </button>
                ))}
            </animated.div>
        </div>
    );
}
