import { useCookies } from "react-cookie";
import { useCallback, useEffect } from "react";
import config from "src/config";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import { useForceUpdate } from "@ntropy/hooks";

const authorizedCookie = "pg.authorized";
const domainRegExp = /[^.]*\.com/;

export function useAuthorizedCookie() {
    const [cookies, setCookie] = useCookies([authorizedCookie]);

    const setAuthorized = useCallback((authorized: boolean) => {
        const [domain] = window.location.host.match(domainRegExp) ?? config.apiHost.match(domainRegExp) ?? [];
        const domainOptions = {
            domain: `.${domain}`,
        };

        setCookie(authorizedCookie, String(authorized), domainOptions);
    }, [setCookie])

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        axiosInstance.on(Endpoint.AuthChallengeVerify, forceUpdate);
        axiosInstance.on(Endpoint.AuthMeV2, forceUpdate);

        return () => {
            axiosInstance.off(Endpoint.AuthChallengeVerify, forceUpdate);
            axiosInstance.off(Endpoint.AuthMeV2, forceUpdate);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    try {
        const authorized = JSON.parse(cookies[authorizedCookie] ?? false) as boolean;

        return [authorized, setAuthorized] as const;
    } catch {
        return [false, setAuthorized] as const;
    }
}