import {
    StyledTopBlock,
    StyledCaption,
    StyledBottomBlock,
    StyledH3,
} from "src/affiliate/components/affiliate-landing/section-three/commission-plans/assets/styles/commision-card.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

interface ICommissionCardProps {
    height: string;
    bsv: string;
    percentage: string;
}

const CommissionCard = ({ height, bsv, percentage }: ICommissionCardProps) => (
    <>
        <FlexContainer
            width="16%"
            tabletWidth="32%"
            mobileWidth="46%"
            mobilePadding={bsv === "500" || bsv === "5000" ? "32px 0 0 0" : ""}
            flexDirection="column"
            justifyContent="flex-end"
        >
            <StyledTopBlock height="54px">
                <StyledCaption>
                    {bsv}
                    <span className="large bold"> BSV</span>
                </StyledCaption>
            </StyledTopBlock>
            <StyledBottomBlock height={height}>
                <StyledH3>{percentage}</StyledH3>
            </StyledBottomBlock>
        </FlexContainer>
    </>
);

export default CommissionCard;
