import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { string, ValidationError } from "yup";
import Modal from "src/components/modal/Modal";
import styles from "./assets/styles.scss";
import Input from "src/components/input/Input";
import Button from "src/components/button/Button";
import { axiosInstance } from "src/core/http/axios-instance";
import { successToast } from "src/helpers/toast";
import { setNickname } from "src/core/auth/auth.reducer";
import useDebouncedCallback from "src/helpers/useDebouncedCallback";
import { useAppDispatch } from "src/redux/reducer";

const cx = classNames.bind(styles);

const validationSchema = string()
    .min(4, "Username is too short")
    .max(10, "Username is too long")
    .matches(/^[a-zA-Z1-9.]*$/, "Username can only contain alphanumeric characters and periods")
    .trim()
    .lowercase();

async function _checkUsername(username: string) {
    try {
        const nickname = validationSchema.validateSync(username);

        const { result } = await axiosInstance.post<{ nickname: string | undefined }, { result: string }>("/v1/mypage/check/nickname", { nickname });
        return result;
    } catch (err) {
        if (err instanceof ValidationError) {
            return err.message;
        }
        throw err;
    }
}

async function _updateUsername(username: string) {
    const nickname = validationSchema.validateSync(username);
    await axiosInstance.post("/v1/mypage/modify/nickname", { nickname });
}

interface IChangeUsernameProps {
    username: string;
}

const ChangeUsername = ({ username }: IChangeUsernameProps) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [newUsername, setNewUsername] = useState(username);
    const {
        mutate: checkUsername,
        data: usernameCheckResult,
        reset: resetCheckResult,
    } = useMutation({ mutationFn: (newUsername: string) => _checkUsername(newUsername) });
    const { mutate: updateUsername, isPending } = useMutation({
        mutationFn: (newUsername: string) => _updateUsername(newUsername),
        onSuccess: (_, newUsername) => {
            successToast("Username updated successfully");
            dispatch(setNickname(validationSchema.validateSync(newUsername) || ""));
            setIsOpen(false);
        },
    });

    useDebouncedCallback(
        () => {
            if (username !== newUsername && newUsername) {
                checkUsername(newUsername);
            }
        },
        newUsername,
        400,
    );

    useEffect(() => {
        resetCheckResult();
    }, [newUsername, resetCheckResult]);

    // reset on modal close
    useEffect(() => {
        if (!isOpen) {
            resetCheckResult();
            setNewUsername(username);
        }
    }, [isOpen, username, resetCheckResult]);

    let errorMessage: string | undefined;
    let successMessage: string | undefined;

    switch (usernameCheckResult) {
        case "invalid_text":
            errorMessage = "This username is not valid";
            break;
        case "used":
            errorMessage = "This username is not available";
            break;
        case "available":
            successMessage = "Username available";
            break;
        default:
            errorMessage = usernameCheckResult as string;
            break;
    }

    return (
        <>
            <button type="button" className={cx("xsmall")} onClick={() => setIsOpen(true)}>
                Change
            </button>
            <Modal
                isOpen={isOpen}
                header="Change Username"
                onClose={() => !isPending && setIsOpen(false)}
                dark
                backgroundColor="#1F0D53"
                className={cx("change-username-modal")}
                footerContent={(
                    <Button
                        className={cx("save-button")}
                        onClick={() => updateUsername(newUsername)}
                        isDisabled={!successMessage}
                        isLoading={isPending}
                    >
                        Save
                    </Button>
                )}
            >
                <Input
                    className={cx("input-container")}
                    value={newUsername}
                    onChange={newValue => setNewUsername(newValue.toString())}
                    error={errorMessage}
                    success={successMessage}
                />
                <p className={cx("small")}>Username must be no longer than 10 characters.</p>
                <p className={cx("small")}>Username can only contain alphanumeric characters (A–Z, 0–9) and periods (".").</p>
            </Modal>
        </>
    );
};

export default ChangeUsername;
