import { PictureContainer } from "src/affiliate/components/reusable/assets/styles/picture.styled";

interface IPictureProps {
    img: string
}

const Picture = ({ img }: IPictureProps) => (
    <PictureContainer>
        <img src={img} alt="section_image" />
    </PictureContainer>
);

export default Picture;
