import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import { games } from "src/games/config";
import Modal from "src/components/modal/Modal";
import Detail from "src/components/detail/Detail";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { CryptoCurrency } from "src/core/currency/currency.model";

interface IBetDetails {
    area: string;
    balance: number;
    game_id: number;
    game_result: string;
    payout_balance?: number;
    payout_txid?: string;
    status: string;
    txid: string;
    txid_for_url: string;
}

interface IBetDetailPresenterProps {
    isOpen: boolean;
    onClose: () => void;
    betAmount: number;
    gameCode: string;
    multiplier: string | number;
    asset: CryptoCurrency;
    isWin: boolean;
    betDetails: IBetDetails;
};

const cx = classNames.bind(styles);

const BetDetailPresenter = ({
    isOpen,
    onClose,
    betAmount,
    gameCode,
    multiplier: payoutRate = "",
    isWin,
    asset,
    betDetails: {
        area,
        game_id: gameId,
        game_result: gameResult,
        payout_balance: payoutBalance,
        payout_txid: payoutTxId,
        status,
        txid: txId,
        txid_for_url: txIdForUrl,
    },
}: IBetDetailPresenterProps) => {
    const formattedPayoutRate = `${Number(payoutRate)?.toFixed(2) || "0.00"}x`;
    const formattedResult = gameResult && games[gameCode]?.formatResult(gameResult);

    let statusText: string = "";
    let statusClassName: string = "";
    let winLossText: string | number | undefined;
    let winLossClassName: string | null = "";

    if (!area) {
        winLossText = "-";
    } else if (isWin) {
        winLossClassName = "win";
        winLossText = payoutBalance;
    } else {
        winLossClassName = "loss";
        winLossText = -betAmount;
    }
    if (status === "fail") {
        statusClassName = "red";
        statusText = "Failed Bet";
        winLossClassName = null;
    } else if (["pending", "waiting"].includes(status)) {
        statusClassName = "yellow";
        statusText = "Pending Bet";
        winLossClassName = null;
    }

    return (
        <>
            <Modal
                header="Bet Details"
                dark
                isOpen={isOpen}
                onClose={onClose}
                className={cx("bet-details-modal")}
                backgroundColor="#1F0D53"
            >
                <img src={games[gameCode].thumbnail} alt={gameCode} />
                <div className={cx("pick-result")}>
                    <Detail label="Round ID:" content={gameId} className={cx("roundId")} contentClassName="small" inline />
                    <Detail label="Result:" content={formattedResult || "-"} contentClassName="small" inline />
                </div>
                <Detail
                    label="Bet"
                    className={cx("bet")}
                    content={(
                        <span className={cx("small", "text-icon")}>
                            {betAmount}
                            <CurrencyIcon currency={asset} />
                        </span>
                    )}
                />
                <Detail label="Multiplier" content={isWin ? formattedPayoutRate : "0.00x"} contentClassName="small" />
                <Detail
                    label="Payout"
                    className={cx("payout")}
                    content={(
                        <span className={cx("small", "bold", "text-icon", winLossClassName)}>
              {winLossText}
                            <CurrencyIcon currency={CryptoCurrency.BSV} />
            </span>
                    )}
                />
                <Detail
                    label="Bet TxID"
                    className={cx("full-width")}
                    contentClassName={cx("xsmall", statusClassName)}
                    content={statusText || txId}
                    copiable={!statusText}
                    href={`https://whatsonchain.com/tx/${txIdForUrl}`}
                />
                {status !== "fail" && (
                    <Detail
                        label="Payout TxID"
                        className={cx("full-width")}
                        content={payoutTxId || "-"}
                        contentClassName={cx("xsmall")}
                        copiable={!!payoutTxId}
                        href={payoutTxId && `https://whatsonchain.com/tx/${payoutTxId}`}
                    />
                )}
            </Modal>
        </>
    );
};

export default BetDetailPresenter;
