import classNames from "classnames/bind";
import styles from "./assets/styles.scss";

import ImgLogoBeGambleAware from "./assets/images/logos/begambleaware.png?sizes[]=120";
import ImgLogoGA from "./assets/images/logos/ga.png";

import ImgLogoProvablyFair from "./assets/images/logos/provably-fair.svg";
import ImgLogoResponsibleGaming from "./assets/images/logos/responsible-gaming.svg";
import ImgLogoSafeSecure from "./assets/images/logos/safe-secure.svg";
import ImgLogoPeergame from "./assets/images/logos/peergame-light.svg";

import ImgIconDiscord from "./assets/images/icons/discord.svg";
import ImgIconTelegram from "./assets/images/icons/telegram.svg";
import ImgIconTwitter from "./assets/images/icons/twitter.svg";
import ImgIconBitcointalk from "./assets/images/icons/bitcointalk.svg";
import ImgIconAdult from "./assets/images/icons/eighteenplus.png";
import BottomFooterLinks from "src/components/footer/components/BottomFooterLinks";

const cx = classNames.bind(styles);

const currentYear = new Date().getFullYear();

const Footer = () => {

    return (
        <>
            <footer>
                <div className={cx("footer-bottom-section", "container")}>
                    <div className={cx("footer-left-block")}>
                        <div className={cx("footer-left-block-top")}>
                            <div className="flex-shrink-0 w-[50px] mr-4 mt-4">
                                <img src={ImgLogoPeergame} alt="Peergame logo" className="logo" />
                            </div>
                            <div>
                                <h6>© Peergame, {currentYear}</h6>
                                <p className="xsmall">
                                    Peergame provides 100% fairness and transparency. To be a user of our site, you must be over 18
                                    years old. If igaming and online betting are not allowed in your region for legal, please avoid
                                    using our services.
                                </p>
                                <div className={cx("footer-left-block-bottom")}>
                                    <img src={ImgLogoProvablyFair} alt="" />
                                    <img src={ImgLogoResponsibleGaming} alt="" />
                                    <img src={ImgLogoSafeSecure} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx("footer-menu-block")}>
                        <BottomFooterLinks />
                    </div>

                    <div className={cx("footer-right-block")}>
                        <h6>Join us</h6>
                        <div className={cx("logo-box")}>
                            <a
                                href="https://discord.gg/cH4jbGS"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Peergame's Discord"
                            >
                                <img src={ImgIconDiscord} alt="Peergame's Discord icon" />
                            </a>
                            <a
                                href="https://twitter.com/PeergameEng"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Peergame's Twitter"
                            >
                                <img src={ImgIconTwitter} alt="Peergame's Twitter icon" />
                            </a>
                            <a href="https://t.me/peergame" target="_blank" rel="noopener noreferrer" title="Peergame's Telegram">
                                <img src={ImgIconTelegram} alt="" />
                            </a>
                            <a
                                href="https://bitcointalk.org/index.php?topic=5257254"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Perrgame's Bitcointalk"
                            >
                                <img src={ImgIconBitcointalk} alt="Perrgame's Bitcointalk icon" />
                            </a>
                        </div>
                        <div className={cx("footer-right-block-card")}>
                            <img src={ImgIconAdult} alt="" />
                            <div>
                                <p className="xsmall">
                                    Gambling can be addictive.
                                    <br />
                                    Play responsibly.
                                </p>
                            </div>
                        </div>
                        <div className={cx("footer-right-block-bottom")}>
                            <a
                                href="https://www.begambleaware.org"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Be Gambling Aware"
                            >
                                <img src={ImgLogoBeGambleAware} alt="Be Gambling Aware icon" />
                            </a>
                            <a
                                href="https://www.gamblersanonymous.org.uk"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Gamblers Anonymous"
                            >
                                <img src={ImgLogoGA} alt="Gamblers Anonymous icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
