import React, { useState, ReactNode } from "react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import Loader from "src/components/loader2/Loader";
import { axiosInstance } from "src/core/http/axios-instance";
import RaceDetails from "src/components/stats-table/Races/RaceDetails";
import { IRacesLeaderboardRankingResponse } from "src/components/stats-table/Races/races.model";

const cx = classNames.bind(styles);

async function getSettings() {
    const { data } = await axiosInstance.get<IRacesLeaderboardRankingResponse>("/v1/leaderboard/setting");

    return data;
}

export default function Races() {
    const [currentType, setCurrentType] = useState("weekly");
    const { data: settings, isLoading } = useQuery({
        queryKey: ["settings", "races"],
        queryFn: () => getSettings(),
        refetchOnWindowFocus: true,
    });

    function toggleOpen(raceType: string) {
        if (raceType === currentType) {
            setCurrentType(raceType === "weekly" ? "daily" : "weekly");
        } else {
            setCurrentType(raceType);
        }
    }

    let raceComponents: ReactNode;

    if (!isLoading) {
        raceComponents = ["weekly", "daily"].map(raceType => (
            <RaceDetails
                key={raceType}
                raceType={raceType}
                settings={settings?.curr[raceType]}
                isOpen={currentType === raceType}
                toggleOpen={() => toggleOpen(raceType)}
            />
        ));
    } else {
        raceComponents = <Loader />;
    }

    return <div className={cx("race-table-container")}>{raceComponents}</div>;
}
