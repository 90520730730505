import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import GameThumbnail from "src/components/game-thumbnail/GameThumbnail";
import { useMobileOnly } from "src/helpers/responsive";
import { categories } from "src/components/games-list/constants/categories-list";
import useGamesList from "src/games/hooks/useGamesList";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);

export default function GamesList() {
    const isMobileOnly = useMobileOnly();
    const params = useParams<{ category: string | undefined }>();

    const gameCategoryFromUrl = params?.category || ""
    const { games } = useGamesList(gameCategoryFromUrl);

    return (
        <>
            <div className={cx("category-selection")}>
                {categories.map(({ name, getTitle, icon }) => (
                    <div key={name}>
                        <Link
                            to={`/games/${name}`}
                            className={cx({ selected: (name === "" && gameCategoryFromUrl === "") || gameCategoryFromUrl === name })}
                        >
                            {icon}
                        </Link>
                        <span className={cx("small")}>{getTitle(isMobileOnly)}</span>
                    </div>
                ))}
            </div>
            <div className={cx("games")}>
                {games.map(({
                    url, provider, thumbnailSrc, title,
                }) => (
                    <GameThumbnail key={title} url={url} provider={provider} thumbnailSrc={thumbnailSrc} title={title} />
                ))}
            </div>
        </>
    );
}