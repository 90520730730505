import { StringParam, useQueryParam, withDefault } from "use-query-params";
import classNames from "classnames/bind";
import Dropdown from "src/components/dropdown/Dropdown";
import styles from "./styles.scss";
import TransactionsTable from "src/containers/Account/Transactions/TransactionsTable";

const cx = classNames.bind(styles);

const taskOptions = [
    { label: "Deposits", value: "deposit" },
    { label: "Withdrawals", value: "withdraw" },
];
const statusOptions = [
    { label: "All Transactions", value: "" },
    { label: "Completed", value: "completed" },
    { label: "Pending", value: "pending" },
];

export default function Transactions() {
    const [task, setTask] = useQueryParam("task", withDefault(StringParam, taskOptions[0].value));
    const [status, setStatus] = useQueryParam("status", withDefault(StringParam, statusOptions[0].value));

    return (
        <>
            <div className={cx("header")}>
                <h3>Transactions</h3>
                <div className={cx("filters")}>
                    <Dropdown
                        items={taskOptions}
                        selectedValue={task}
                        onChange={newValue => setTask(newValue.toString())}
                    />
                    <Dropdown
                        items={statusOptions}
                        selectedValue={status}
                        onChange={newValue => setStatus(newValue.toString())}
                    />
                </div>
            </div>
            <TransactionsTable task={task} status={status} />
        </>
    );
}
