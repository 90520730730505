import { useMemo } from "react";
import { deckCount, getNextResult, shuffle } from "containers/FairnessCalculation/helper";

export default function useShuffledCards(randomNumber) {
    return useMemo(() => {
        if (randomNumber) {
            const deck = shuffle(randomNumber, deckCount);
            return getNextResult(deck);
        }
    }, [randomNumber]);
}
