import styled, { css } from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { Caption, H3 } from "src/affiliate/styled/typography.styled";

export const StyledFlexContainer = styled(FlexContainer)<{ gradientDirection?: boolean }>`
    width: ${({ width }) => width || "100%"};
    background-image: ${({ gradientDirection = false }) => gradientDirection
        ? css`linear-gradient(to bottom, #1a0949, #22096c)`
        : css`linear-gradient(to top, #1a0949, #22096c)`};
    border-radius: 10px 10px 0 0;
    flex-direction: column;
    padding: ${({ padding }) => padding || "24px 0 32px 24px"};
`;

export const StyledP = styled(Caption)<{ campaignBlogCard?: boolean }>`
    margin: 0 0 16px 0;
    ${({ campaignBlogCard }) => campaignBlogCard
        ? css`
            color: #c7baf1;
        `
        : ""};
`;

export const StyledH3Wrapper = styled(H3)<{ campaignBlogCard?: boolean }>`
    display: flex;
    align-items: center;
    gap: 2px;

    img {
        width: 18px;
        margin-left: 4px;
    }

    ${({ campaignBlogCard }) => campaignBlogCard
        ? css`
            color: #ffffff;

            img {
                width: 12px;
                margin-left: 4px;
            }
        `
        : ""}
`;