import { ICurrencyAccount } from "src/core/currency/currency.model";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { getCryptoCurrencyName, formatFiat, formatCrypto } from "src/core/currency/currency.function";
import { FC } from "react";
import { useConvertToUsd } from "src/core/currency/hooks/useConvertToUSD";
import cn from "classnames";
import { brandThinScrollbar } from "src/components/scrollbar/scrollbar.const";
import CurrencyListElement from "src/feature/wallet/components/currency-list-element/CurrencyListElement";

interface IWalletListOfCurrenciesProps {
    currencies: ICurrencyAccount[]
}

export const WalletListOfCurrencies = ({ currencies }: IWalletListOfCurrenciesProps) => {
    return (
        <ul className={cn("flex flex-col gap-[20px] sm:max-h-[330px] py-2", brandThinScrollbar)}>
            {currencies.map(account => (
                <CurrencyListElement key={account.currencyTicker} alignValues="left" {...account} />
            ))}
        </ul>
    )
}