export default function PrivacyPolicy() {
    return (
        <>
            <p>
                <strong>This Privacy Policy is incorporated and made a part of Peergame terms of service.</strong>
                <br />
                <br />
                PLEASE READ THIS DOCUMENT CAREFULLY.
                IT CONTAINS IMPORTANT INFORMATION YOU SHOULD KNOW BEFORE CONTINUING
                TO USE OUR WEBSITE OR SERVICES (As defined in the Terms of Services).
                BY CONTINUING TO USE THE PEERGAME WEBSITE AND SERVICES YOU WILL BE DEEMED TO HAVE ACCEPTED THIS PRIVACY POLICY.
            </p>
            <h5>Introduction</h5>
            <p>
                This end user agreement (the "Agreement") should be read by you (the "User" or "you")
                in its entirety prior to your use of Peergame’s service or products. Please note
                that the Agreement constitutes a legally binding agreement between you and Peergame
                (referred to herein as "Peergame", "us" or "we") which owns and operates the Internet site
                found and games described at Peergame (the "Service").
            </p>
            <br />
            <p>
                It is understood and presumed that by the fact of the use of the Website and its services or by clicking “I'm
                at least 18 years old and accept with Terms of use and Privacy policy” button the User gives Consent, that
                he/she/it has fully read, understood and accepted this Policy. If any User does not agree with this Policy in
                general or any part of it, such User should withhold from using the Website and its services.
            </p>
            <br />
            <p>
                Peergame respects and protects the privacy of visitors to our website and our customers who use our services.
                By using our website, you hereby consent to our Privacy Policy and agree to its terms. If you have additional
                questions or require more information about our Privacy Policy, please contact us.
            </p>
            <h5>Information collection</h5>
            <p>
                If you contact us directly, we may receive additional information about you such as your name, email address,
                phone number, the contents of the message and/or attachments you may send us, and any other information you
                may choose to provide.
                <br />
                We may also collect information how the Service is accessed and used. This data may include following
                information.
                <br />
                <br />
                1. Your computer's Internet Protocol address (e.g. IP address)
                <br />
                2. Browser type and its version
                <br />
                3. The pages in our website that you visit
                <br />
                4. The time and date of your visit and the time spent on our website
                <br />
                5. Unique device identifiers and other diagnostic data
                <br />
                6. Cookies You can choose to disable cookies through your individual browser options. However, if you do not
                accept cookies, you may not be able to use some portions of our service.
                <br />
                7. For know-your-customer (KYC) identity verification.
                <br />
                8. To establish, exercise or defend any legal claims.
                <br />
                9. To comply with Peergame.com’s contractual, legal, and statutory obligations.
                <br />
                <br />
            </p>
            <h5>Information usage</h5>
            <p>We use the information we collect in various ways, including to:</p>
            <p>
                1. Provide, operate, and maintain our website
                <br />
                2. Improve, personalize, and expand our website
                <br />
                3. Understand and analyze how you use our website
                <br />
                4. Develop new products, services, features, and functionality
                <br />
                5. Communicate with you, either directly or through one of our partners, including for customer service, to
                provide you with updates and other information relating to the webste, and for marketing and promotional
                purposes
                <br />
                6. Send you emails
                <br />
                7. Find and prevent fraud
                <br />
                <br />
                If you do not provide Peergame.com with the Personal Information we request, we may be unable to process your
                application to become a member, provide you with our services or respond to your inquiry or complaint.
            </p>
            <h5>Log files</h5>
            <p>
                Peergame follows a standard procedure of using log files. These files log visitors when they visit websites.
                All hosting companies do this and a part of hosting services' analytics. The information collected by log
                files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time
                stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is for analyzing trends, administering the site,
                tracking users' movement on the website, and gathering demographic information.
            </p>
            <h5>Cookies</h5>
            <p>
                Like any other website, Peergame uses 'cookies'. These cookies are used to store information including
                visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is
                used to optimize the users' experience by customizing our web page content based on visitors' browser type
                and/or other information.
            </p>
            <h5>Advertising Partners Privacy Policies</h5>
            <p>
                You may consult this list to find the Privacy Policy for each of the advertising partners of Peergame.
                <br />
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used
                in their respective advertisements and links that appear on Peergame, which are sent directly to users'
                browser. They automatically receive your IP address when this occurs. These technologies are used to measure
                the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on
                websites that you visit.
                <br />
                Note that Peergame has no access to or control over these cookies that are used by third-party advertisers.
            </p>
            <h5>GDPR Data Protection Rights</h5>
            <p>
                We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled
                to the following:
                <br />
                The right to access – You have the right to request copies of your personal data. We may charge you a small
                fee for this service.
                <br />
                The right to rectification – You have the right to request that we correct any information you believe is
                inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                <br />
                The right to erasure – You have the right to request that we erase your personal data, under certain
                conditions.
                <br />
                The right to restrict processing – You have the right to request that we restrict the processing of your
                personal data, under certain conditions.
                <br />
                The right to object to processing – You have the right to object to our processing of your personal data,
                under certain conditions.
                <br />
                The right to data portability – You have the right to request that we transfer the data that we have collected
                to another organization, or directly to you, under certain conditions.
                <br />
                If you would like to exercise any of these rights, please contact us.
            </p>
        </>
    );
}
