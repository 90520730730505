const CloseIcon = () => (
    <>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5794 1.84014L1.83965 14.5799C1.78399 14.6356 1.7085 14.6668 1.62979 14.6668C1.55108 14.6668 1.47559 14.6356 1.41993 14.5799C1.36428 14.5242 1.33301 14.4488 1.33301 14.37C1.33301 14.2913 1.36428 14.2158 1.41993 14.1602L14.1597 1.42042C14.2154 1.36476 14.2908 1.3335 14.3696 1.3335C14.4483 1.3335 14.5238 1.36476 14.5794 1.42042C14.6351 1.47608 14.6663 1.55157 14.6663 1.63028C14.6663 1.70899 14.6351 1.78448 14.5794 1.84014Z"
                fill="#F0ECFF"
            />
            <path
                d="M1.83965 1.42042L14.5794 14.1602C14.607 14.1877 14.6288 14.2205 14.6437 14.2565C14.6587 14.2925 14.6663 14.3311 14.6663 14.37C14.6663 14.409 14.6587 14.4476 14.6437 14.4836C14.6288 14.5196 14.607 14.5523 14.5794 14.5799C14.5519 14.6075 14.5191 14.6293 14.4831 14.6442C14.4471 14.6592 14.4085 14.6668 14.3696 14.6668C14.3306 14.6668 14.292 14.6592 14.256 14.6442C14.22 14.6293 14.1873 14.6075 14.1597 14.5799L1.41993 1.84014C1.36428 1.78448 1.33301 1.70899 1.33301 1.63028C1.33301 1.55157 1.36428 1.47608 1.41993 1.42042C1.47559 1.36476 1.55108 1.3335 1.62979 1.3335C1.7085 1.3335 1.78399 1.36476 1.83965 1.42042Z"
                fill="#F0ECFF"
            />
        </svg>
    </>
);

export default CloseIcon;