import classNames from "classnames/bind";
import Modal from "src/components/modal/Modal";
import Detail from "src/components/detail/Detail";
import styles from "./assets/styles.scss";

import IconLink from "./assets/link.svg?inline";
import { games } from "src/games/config";
import { CryptoCurrency } from "src/core/currency/currency.model";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";

const cx = classNames.bind(styles);

interface IBetDetailsProps {
    isOpen: boolean;
    onClose: () => void;
    isWin: boolean;
    status: string;
    betAmount: number;
    payout?: number | null;
    payoutRate: string;
    gameCode: string;
    txId: string;
    payoutTxId?: string | null;
    playerPick: string;
    result: string;
    asset: CryptoCurrency;
    txIdForUrl: string;
}

const BetDetails = ({
    isOpen,
    onClose,
    isWin,
    status,
    betAmount,
    payout,
    payoutRate,
    gameCode,
    txId,
    payoutTxId,
    playerPick,
    result,
    asset,
    txIdForUrl,
}: IBetDetailsProps) => {
    let statusText: string | undefined;
    let statusClassName: string | undefined;
    let winLossText: string | number;
    let winLossClassName: string | undefined;

    if (!result && !["slots", "table", "casual"].includes(gameCode)) {
        winLossText = "-";
    } else if (isWin || payout) {
        winLossClassName = "win";
        winLossText = payout ?? "-";
    } else {
        winLossClassName = "loss";
        winLossText = -betAmount;
    }
    if (status === "fail") {
        statusClassName = "red";
        statusText = "Failed Bet";
        winLossClassName = undefined;
    } else if (["pending", "waiting"].includes(status)) {
        statusClassName = "yellow";
        statusText = "Pending Bet";
        winLossClassName = undefined;
    }

    return (
        <>
            <span className={cx("bet-details-button", "bold", "small", statusClassName || winLossClassName)}>
                {statusText || winLossText}
                {result && <IconLink />}
            </span>
            <Modal
                header="Bet Details"
                dark
                isOpen={isOpen}
                onClose={onClose}
                className={cx("bet-details-modal")}
                backgroundColor="#1F0D53"
            >
                <img src={games[gameCode].thumbnail} alt={gameCode} />
                <div className={cx("pick-result")}>
                    <Detail label="My Pick:" content={playerPick} contentClassName="small" inline />
                    <Detail label="Result:" content={result || "-"} contentClassName="small" inline />
                </div>
                <Detail
                    label="Bet"
                    className={cx("bet")}
                    content={
                        <span className={cx("small", "text-icon")}>
                            {betAmount}
                            <CurrencyIcon currency={asset} />
                        </span>
                    }
                />
                <Detail label="Multiplier" content={payoutRate} contentClassName="small" />
                <Detail
                    label="Payout"
                    className={cx("payout")}
                    content={
                        <span className={cx("small", "bold", "text-icon", winLossClassName)}>
                            {winLossText}
                            <CurrencyIcon currency={CryptoCurrency.BSV} />
                        </span>
                    }
                />
                <Detail
                    label="Bet TxID"
                    className={cx("full-width")}
                    contentClassName={cx("xsmall", statusClassName)}
                    content={statusText || txId}
                    copiable={!statusText}
                    href={`https://whatsonchain.com/tx/${txIdForUrl}`}
                />
                {status !== "fail" && (
                    <Detail
                        label="Payout TxID"
                        className={cx("full-width")}
                        content={payoutTxId || "-"}
                        contentClassName={cx("xsmall")}
                        copiable={!!payoutTxId}
                        href={payoutTxId ? `https://whatsonchain.com/tx/${payoutTxId}` : undefined}
                    />
                )}
            </Modal>
        </>
    );
}

export default BetDetails;
