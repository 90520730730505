import { toast } from "react-toastify";
import Notify from "src/affiliate/components/reusable/Notify";

function toastNotify(message: string, delay = 2000, error = false) {
    return toast(<Notify message={message} error={error} />, {
        autoClose: delay,
        hideProgressBar: true,
        closeButton: false,
        position: "top-right",
    });
}

export default toastNotify;
