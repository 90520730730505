import styled from "styled-components";

export const StyledButton = styled.button<{ width?: string; }>`
    width: ${({ width }) => width || "100%"};
    height: 50px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.error};
    color: ${({ theme }) => theme.color.white};

    &:hover:enabled {
        background-color: #d10a52;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    transition: all 0.3s ease;
`;

export const StyledBorderedButton = styled.button<{
    width?: string;
    hoverTextColor: string;
}>`
    width: ${({ width }) => width || "100%"};
    height: 50px;
    background-color: transparent;
    border-radius: 2px;
    border: solid 1px #c7baf1;
    cursor: pointer;
    color: #c7baf1;

    &:hover {
        background-color: rgba(34, 9, 108, 0.5);
        color: ${({ hoverTextColor }) => hoverTextColor};
        border: solid 1px #c7baf1;
    }

    transition: all 0.3s ease;
`;