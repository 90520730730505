import bsvIcon from "../reusable/assets/bsv-icon.svg";
import { StyledTh, StyledTr, StyledTable, StyledTd } from "src/affiliate/components/commission-plans/assets/styles/commission-table.styled";

interface ICommissionTableProps {
    data: {
        tier: string;
        wagered: string;
        commission_rate: number;
    }[];
    tierName: string;
}

const CommissionTable = ({ data = [], tierName }: ICommissionTableProps) => (
    <>
        <StyledTable>
            <thead>
            <StyledTr className="xlarge">
                <StyledTh>Level</StyledTh>
                <StyledTh>Commission Rates</StyledTh>
                <StyledTh>
                    <div className="flex items-center gap-2">
                        Wagered
                        <img src={bsvIcon} alt="bsv icon" />
                    </div>
                </StyledTh>
            </StyledTr>
            </thead>
            <tbody>
            {data.map(({ tier, wagered, commission_rate: commissionRate }) => (
                <StyledTr key={tier} selected={tierName === tier} className="xlarge">
                    <StyledTd>Level {tier}</StyledTd>
                    <StyledTd fontSize fontWeight color>
                        {commissionRate * 100}%
                    </StyledTd>
                    <StyledTd>{wagered}</StyledTd>
                </StyledTr>
            ))}
            </tbody>
        </StyledTable>
    </>
);

export default CommissionTable;
