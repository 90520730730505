import React, {useRef} from "react";
import {animated} from "@react-spring/web";
import PropTypes from "prop-types";
import {v4 as uuidv4} from "uuid";

export default function Animated001ChipSVG({applyRotation, ...rest}) {
    const rotation = useRef(applyRotation ? Math.floor(Math.random() * 360) : 0);
    const randomId = uuidv4();

    return (
        <animated.svg {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 50">
            <g clipPath={`url(#clip-chip-0.01-${randomId})`} filter={`url(#filter-chip-0.01-${randomId})`}>
                <path
                    fill="#4B0AB5"
                    d="M40.97 40.97c9.373-9.372 9.373-24.568 0-33.94-9.372-9.373-24.568-9.373-33.94 0-9.373 9.372-9.373 24.568 0 33.94 9.372 9.373 24.568 9.373 33.94 0z"
                />
                <path
                    style={{transform: `rotate(${rotation.current}deg)`}}
                    fill="#CEB0FF"
                    d="M9.866 4.601a24.13 24.13 0 00-2.721 2.313l-.022.021a24.131 24.131 0 00-2.34 2.685l4.827 4.887 2.539-2.506L14.69 9.49 9.866 4.6zM.31 20.121a24.121 24.121 0 00-.309 3.549v.035a24.207 24.207 0 00.218 3.56l6.87.085.046-3.567.046-3.573-6.87-.088zM9.37 33.119l-4.951 4.761a24.126 24.126 0 004.952 5.148l4.95-4.763-2.473-2.57-2.478-2.576zm10.62 7.65l-.177 6.868c2.358.413 4.765.475 7.141.184l.174-6.87-3.566-.09-3.572-.092zm18.146-6.902l-2.603 2.44-2.606 2.444 4.697 5.012a24.14 24.14 0 005.211-4.885l-4.698-5.01-.001-.001zm2.855-12.77l-.138 3.565-.137 3.57 6.864.265a24.05 24.05 0 00.402-3.541v-.022a24.23 24.23 0 00-.126-3.575l-6.866-.262zM39.122 5.362l-5.071 4.633 2.405 2.634 2.41 2.64 5.073-4.632a24.13 24.13 0 00-4.817-5.274zM24 0c-1.197 0-2.392.088-3.576.265l.002 6.87h4.017l.439.022 3.569.183.353-6.86A24.11 24.11 0 0024 0z"
                />
                <path
                    fill="#D7BFFF"
                    d="M37.218 37.217c7.3-7.3 7.3-19.135 0-26.435-7.3-7.3-19.136-7.3-26.435 0-7.3 7.3-7.3 19.135 0 26.435 7.3 7.3 19.135 7.3 26.435 0z"
                />
                <path
                    fill="#EFE6FF"
                    d="M35.45 35.467c6.335-6.336 6.337-16.607.002-22.94-6.334-6.335-16.605-6.334-22.94.001-6.336 6.336-6.337 16.607-.003 22.941s16.605 6.333 22.941-.002z"
                />
                <path
                    style={{transform: `rotate(${rotation.current}deg)`}}
                    fill="#1E024B"
                    d="M17.569 27.39v-5.065c0-1.18-.308-1.77-.923-1.77a.71.71 0 00-.431.13.92.92 0 00-.274.307c-.059.119-.107.27-.143.451a4.54 4.54 0 00-.062.451c-.004.124-.006.267-.006.431v5.066c0 .14.004.28.013.417.014.132.039.284.075.458.037.168.087.316.15.444a.928.928 0 00.267.314c.119.087.256.13.41.13.192 0 .354-.059.486-.177.132-.123.228-.285.287-.486.06-.2.098-.387.116-.56.023-.174.035-.354.035-.54zm-4.239 0v-5.065c0-.547.064-1.048.192-1.504.132-.46.328-.868.588-1.223.26-.356.603-.634 1.032-.834.433-.2.934-.301 1.504-.301.565 0 1.061.1 1.49.3.433.201.78.481 1.039.841.26.356.453.762.581 1.217.132.456.198.957.198 1.504v5.066a5.16 5.16 0 01-.205 1.476c-.137.456-.34.861-.608 1.217-.265.355-.61.638-1.04.848a3.331 3.331 0 01-1.455.307c-.552 0-1.044-.102-1.477-.307a2.745 2.745 0 01-1.039-.841 3.896 3.896 0 01-.602-1.217 5.356 5.356 0 01-.198-1.483zM20.792 31v-2.167h2.222V31h-2.222zm7.284-3.61v-5.065c0-1.18-.308-1.77-.923-1.77a.71.71 0 00-.43.13.92.92 0 00-.274.307c-.06.119-.107.27-.143.451a4.507 4.507 0 00-.062.451 11.73 11.73 0 00-.007.431v5.066c0 .14.005.28.014.417.014.132.039.284.075.458.037.168.087.316.15.444a.929.929 0 00.267.314c.119.087.255.13.41.13a.7.7 0 00.486-.177c.132-.123.227-.285.287-.486.059-.2.098-.387.116-.56.023-.174.034-.354.034-.54zm-4.238 0v-5.065c0-.547.064-1.048.191-1.504.132-.46.328-.868.588-1.223.26-.356.604-.634 1.032-.834.433-.2.935-.301 1.504-.301.565 0 1.062.1 1.49.3.433.201.78.481 1.04.841.26.356.453.762.58 1.217.133.456.199.957.199 1.504v5.066c0 .524-.069 1.016-.205 1.476-.137.456-.34.861-.609 1.217-.264.355-.61.638-1.039.848a3.33 3.33 0 01-1.456.307c-.551 0-1.043-.102-1.476-.307a2.744 2.744 0 01-1.04-.841 3.892 3.892 0 01-.6-1.217 5.352 5.352 0 01-.2-1.483zM32.16 31v-9.14h-1.244V20.05a5.6 5.6 0 01.513-.267c.214-.105.38-.189.499-.253.118-.064.25-.168.396-.314.146-.146.269-.321.37-.526h1.818V31H32.16z"
                />
            </g>
            <defs>
                <filter
                    id={`filter-chip-0.01-${randomId}`}
                    width="48"
                    height="52"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="2"/>
                    <feColorMatrix values="0 0 0 0 0.244163 0 0 0 0 0.045191 0 0 0 0 0.570833 0 0 0 1 0"/>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <clipPath id={`clip-chip-0.01-${randomId}`}>
                    <path fill="#fff" d="M0 0H48V50H0z"/>
                </clipPath>
            </defs>
        </animated.svg>
    );
}
Animated001ChipSVG.propTypes = {
    applyRotation: PropTypes.bool,
};
Animated001ChipSVG.defaultProps = {
    applyRotation: false,
};
