import styled, { css } from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledFlexContainer = styled(FlexContainer)<{ openCloseMenu?: boolean }>`
    width: 258px;
    height: 595px;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    background-color: #1a0949;
    padding: 24px;
    z-index: 1;

    @media only screen and (max-width: 1157px) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        ${({ openCloseMenu }) => openCloseMenu
            ? css`
                transform: translateX(0px);
            `
            : css`
                transform: translateX(-600px);
            `};
        ${({ openCloseMenu }) => openCloseMenu
            ? css`
                opacity: 1;
            `
            : css`
                opacity: 0;
            `};
        transition: all 0.3s ease-in;
        z-index: 369;
        width: 360px;
        height: auto;
        overflow-y: hidden;
        padding: 50px 0 0 0;
        border-radius: 0;
        justify-content: flex-start;
        align-items: center;
    }
}

@media only screen and (max-width: 568px) {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    ${({ openCloseMenu }) => openCloseMenu
        ? css`
            transform: translateX(0px);
        `
        : css`
            transform: translateX(-600px);
        `};
    ${({ openCloseMenu }) => openCloseMenu
        ? css`
            opacity: 1;
        `
        : css`
            opacity: 0;
        `};
    transition: all 0.3s ease-in;
    bottom: 0;
    z-index: 369;
    width: 100%;
    height: 100vh;
    padding: 0;
    overflow-y: hidden;
    border-radius: 0;
    justify-content: center;
    align-items: center;
}
`;

export const StyledLine = styled.div`
    width: 210px;
    height: 1px;
    opacity: 0.2;
    background-color: #c7baf1;
    margin: 16px 0 32px 0;
`;

export const StyledMenuTrigger = styled.div<{ zIndex: boolean }>`
    display: none;

    @media only screen and (max-width: 1157px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 38px;
        background-color: #f0ecff;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 56px;
        border-radius: 0 10px 10px 0;
        z-index: ${({ zIndex }) => (zIndex ? "370" : "369")};
    }
    @media only screen and (max-width: 568px) {
        display: flex;
        width: 54px;
        height: 38px;
        background-color: #f0ecff;
        cursor: pointer;
        position: fixed;
        left: 0;
        top: 56px;
        border-radius: 0 10px 10px 0;
        z-index: ${({ zIndex }) => (zIndex ? "370" : "369")};
    }
`;