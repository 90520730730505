import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";
import Header from "src/components/header/Header";
import Footer from "src/components/footer/Footer";
import THEME from "src/affiliate/constants/themes.constants";
import SideNav from "src/affiliate/components/side-nav/SideNav";
import GlobalStyle from "src/affiliate/normalize";
import Protected from "src/affiliate/components/reusable/Protected";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { ReactNode } from "react";

interface ILayoutProps {
    children: ReactNode;
    backGround?: string;
    transparent?: boolean;
    sideNav?: boolean;
    allowedRoutes?: boolean;
}

const Layout = ({
    backGround = "#12033d",
    transparent = false,
    sideNav = false,
    allowedRoutes = false,
    children,
}: ILayoutProps) => (
    <>
        <Helmet
            bodyAttributes={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                style: `background:${backGround}`,
            }}
        />
        <Header key="header" classes={transparent ? ["transparent"] : []} />
        <ThemeProvider theme={THEME}>
            <GlobalStyle />
            {sideNav ? (
                <Protected>
                    <FlexContainer position="relative" mobileFlexDirection="column">
                        <FlexContainer flexDirection="column" padding="24px 0 100px 24px" mobilePadding="0" tabletPadding="0">
                            <SideNav />
                        </FlexContainer>
                        {children}
                    </FlexContainer>
                </Protected>
            ) : (
                <> {allowedRoutes ? <> {children} </> : <Protected>{children}</Protected>}</>
            )}
        </ThemeProvider>
        <Footer />
    </>
);

export default Layout;
