const pageDescription = "Peergame uses tracking technologies to help us develop and advertise our services. Peergame"
    + " uses cookies, pixels, web beacons, scripts, tags, API, and other tracking technologies on all of our websites."
    + " In this notice, all of these technologies will be referred to collectively as cookies.";

export default function Cookies() {
    return (
        <>
            <p>{pageDescription}</p>
            <p>
                Cookies are placed on your device to allow us to collect information about you and your online behavior, as
                well as the device you are using. We do this so that we can improve our performance and customize your
                experience. We also use this information to collect statistics about our services, perform analytics, deliver
                content tailored to your interests, and administer services to our Users, advertisers, publishers, customers,
                and partners. We also allow third parties to collect information about you when they are providing these
                services to us.
            </p>

            <h5>What are cookies?</h5>
            <p>
                A cookie is a small text file that a website saves on your computer or mobile device when you visit the
                website. Cookies are then sent back to the originating website on each subsequent visit or another website
                that recognizes that cookie to develop a record of the user's online activity. Almost all websites use some
                form of cookies.
            </p>

            <p>
                Cookies are widely used to make websites work efficiently. They allow you to navigate between pages
                efficiently, remember your preferences, and make the interaction between you and the site smoother and more
                efficient. They are also used to help ensure that the advertisements you see online are relevant to you and
                your interests.
            </p>

            <h5>Storing cookies</h5>
            <p>
                We place cookies when you visit or access our websites. Peergame sets some of these cookies, but we also allow
                selected third parties to remember cookies on our pages too. These are generally used for analytics,
                performance, and advertising targeting. While we specify the information these cookies collect and use for the
                purposes we have engaged these third parties, we do not have control over their further use of the cookies or
                data gathered from these cookies. Therefore you should review their privacy and cookie policies to understand
                how they may use your information.
            </p>

            <p>
                Cookies may be stored either for the duration of your visit to our pages or repeat visits. Certain cookies,
                session cookies, last for as long as your browser remains open and expire when you close it. Others are known
                as persistent cookies, which expire at a fixed point in time unless you manually delete them from your
                browser.
            </p>

            <h5>What types of cookies do we use?</h5>
            <p>There are three main types of cookies:</p>

            <h6>Required Cookies</h6>
            <p>
                Cookies may be essential or non-essential. Essential cookies are used solely to carry out or facilitate the
                transmission of communications over a network or are strictly necessary to provide an online service that you
                have requested. For instance, strictly necessary cookies are essential to log in and navigate around and use
                the website.
                <br />
                By law, we do not need to obtain your consent to use these cookies.
            </p>

            <h6>Functionality Cookies</h6>
            <p>
                Functionality cookies allow our websites to remember choices you make and provide enhanced and personalized
                features. For example, these cookies are used for authentication, recognize when you are logged in, and
                support our websites' features.
                <br />
                Performance cookies collect information about your online activity, like the duration of your visit to our
                website, including behavioral data and content engagement metrics. These cookies are used for analytics,
                research, and to perform statistics, although this is usually based on the aggregated information.
            </p>

            <h6>Marketing or Advertising Cookies</h6>
            <p>
                These are used to deliver tailored offers and advertisements to you based on your derived interests and
                perform email marketing campaigns. They can also be used to limit the number of times you see an ad and help
                measure the advertising campaign's effectiveness. They are usually placed by our advertisers and provide them
                with insights about the people who see and interact with their ads, visit their websites, or use their app.
            </p>

            <h5>Consenting to cookies</h5>
            <p>
                The first time you visit our pages, you'll be met by a notice which asks you to give us your preferences
                regarding our use of cookies. You won't be able to avoid this message without providing a preference. You can
                change your mind at any time by clicking "Cookies Preferences" in the website footer. This will take you to a
                screen where you can make changes to the cookies you consent to. If you change your preferences, either to
                give support or withdraw it, this change will be actioned on the next page that you visit. You don't have to
                tell us your preference every time you visit our site. We will place a cookie that remembers the choices you
                have selected. This cookie will be set by TrustArc, a third party that helps collect and implement your cookie
                preferences.
            </p>

            <h5>Turning off cookies via your web browser</h5>
            <p>
                Most web browsers provide general information about cookies, enable you to see which cookies are stored on
                your device, allow you to delete them all or on an individual basis, and will allow you to block or allow
                cookies for all websites or individually selected websites. You can also usually turn off third-party cookies
                separately. Please note that the settings offered by a browser or device often only apply to that particular
                browser or device. So you may have to select a preference for each browser or device you use. Information
                about cookies is usually found in the "Help" section of the web browser.
            </p>

            <h5>Google Analytics Opt-Out</h5>
            <p>
                To provide website visitors the ability to prevent their data from being used by Google Analytics, Google has
                developed the Google Analytics opt-out browser add-on for the Google Analytics JavaScript (ga.js,
                analytics.js, dc.js). If you want to opt-out, download and install the add-on for your web browser. The Google
                Analytics opt-out add-on is designed to be compatible with Chrome, Internet Explorer 11, Safari, Firefox, and
                Opera. To function, the opt-out add-on must be able to load and execute properly on your browser. For Internet
                Explorer, 3rd-party cookies must be enabled. Learn more about the opt-out and how to install the browser
                add-on here - <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
            </p>

            <h5>Log Files</h5>
            <p>
                Log files are maintained and analyzed of all requests for files on this website's web servers. Log files
                capture your IP address, which is automatically recognized by our web servers.
                <br />
                Aggregated analysis of these log files is used to monitor website usage. These analyses may be made available
                to our staff and partner agencies to allow them to measure, for example, the overall popularity of the site
                and typical user paths through the site.
            </p>
        </>
    );
}
