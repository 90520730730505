import styled from "styled-components";
import { Caption } from "src/affiliate/styled/typography.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";

export const StyledBestCommissions = styled.h3`
    color: #22096c;
    margin-bottom: 16px;
    @media only screen and (max-width: 568px) {
        font-size: 24px;
    }
`;

export const StyledCaption = styled(Caption)`
    color: #1f0d53;
`;

export const StyledImageContainer = styled(FlexContainer)`
    @media only screen and (max-width: 568px) {
        display: none;
    }
`;