import { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { StyledTitle } from "src/affiliate/styled/typography.styled";
import DropDown from "src/affiliate/components/reusable/drop-down/DropDown";
import useDropdown from "src/affiliate/hooks/use-dropdown";
import usePagination from "src/affiliate/hooks/use-pagination";
import useCampaign from "src/affiliate/hooks/use-campaign";
import useFetch from "src/helpers/useFetch";
import Layout from "src/affiliate/components/Layout";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import DataCard from "src/affiliate/components/reusable/DataCard";
import Table from "src/affiliate/components/reusable/Table";
import Loader from "src/components/loader/Loader";

interface IUsersResponse {
    referrals: number;
    referred_user_list: any[];
    total_pages: number
}

const defaultUserResponseValues = {
    referrals: 0,
    referred_user_list: [],
    total_pages: 0,
}

const sortBy = [
    { name: "Wagered", value: "WAGERED" },
    { name: "Commissions", value: "COMMISSIONS" },
    { name: "Created", value: "CREATED" },
];

const headerTitles = {
    user_id: "User ID",
    created_at: "Created",
    wagered: " Wagered",
    commissions: "Commissions",
};

const ReferredUsers = () => {
    const [page, setPage] = useState(0);
    const {
        value, onClickHandler, trigger, setTrigger,
    } = useDropdown({ name: "Created", value: "CREATED" });
    const {
        value: campaignNameValue,
        onClickHandler: campaignNameOnClickHandler,
        trigger: campaignNameTrigger,
        setTrigger: campaignNameSetTrigger,
    } = useDropdown({ name: "All Campaigns", value: "ALL" });

    const { campaignList, loadedCampaigns } = useCampaign("/api/v1/affiliate/referrer/campaign");

    const campaignName = useMemo(() => {
        const defaultVal = { name: "All Campaigns", value: "ALL" };
        let filteredCampaigns: { name: string; value: string; }[] = [];
        if (loadedCampaigns) {
            filteredCampaigns = campaignList.map(item => ({ name: item.name, value: item.code }));
        }
        return [defaultVal, ...filteredCampaigns];
    }, [loadedCampaigns, campaignList]);

    const {
        pagination, prevPage, nextPage, changePage, currentPage,
    } = usePagination(0, page);
    const { data, loaded } = useFetch<IUsersResponse>(
        `/api/v1/affiliate/referrer/users?size=10&page=${currentPage}&sort_by=${value.value}&campaign_code=${campaignNameValue.value}`,
    );
    const { referrals, referred_user_list: referredUserList, total_pages: totalPages } = loaded && data ? data : defaultUserResponseValues;
    useEffect(() => {
        setPage(() => totalPages);
    }, [totalPages]);
    return (
        <>
            <Helmet>
                <title>Referred-users</title>
                <meta property="og:title" content="Peergame referred-users" />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
                />
            </Helmet>
            <Layout sideNav transparent>
                {loaded ? (
                    <FlexContainer
                        flexDirection="column"
                        padding="42px 0 178px 38px"
                        tabletPadding="58px 24px 333px 24px"
                        mobilePadding="22px 16px 112px 16px"
                        width="800px"
                        mobileWidth="100%"
                        tabletWidth="100%"
                    >
                        <FlexContainer alignItems="flex-end" justifyContent="space-between" mobileFlexDirection="column">
                            <FlexContainer flexDirection="column" width="52%" tabletWidth="62%" mobileWidth="100%">
                                <FlexContainer mobileWidth="100%" mobileJustifyContent="center" mobileMargin="0 0 28px 0">
                                    <StyledTitle
                                        tabletMargin="0 0 0 40px"
                                        mobileMargin="0"
                                        mobileFontSize="20px"
                                    >
                                        Referred Users
                                    </StyledTitle>
                                </FlexContainer>
                                <FlexContainer
                                    margin="32px 0 0 0"
                                    tabletMargin="24px 0 0 0"
                                    mobileMargin="0"
                                    justifyContent="space-between"
                                >
                                    <FlexItem width="49%">
                                        <DropDown
                                            overflowY
                                            label="Campaign Name"
                                            value={campaignNameValue}
                                            onClickHandler={campaignNameOnClickHandler}
                                            listItems={campaignName}
                                            trigger={campaignNameTrigger}
                                            setTrigger={campaignNameSetTrigger}
                                        />
                                    </FlexItem>
                                    <FlexItem width="49%">
                                        <DropDown
                                            label="Sort by"
                                            value={value}
                                            onClickHandler={onClickHandler}
                                            listItems={sortBy}
                                            trigger={trigger}
                                            setTrigger={setTrigger}
                                        />
                                    </FlexItem>
                                </FlexContainer>
                            </FlexContainer>

                            <FlexItem width="21%" tabletWidth="26%" mobileWidth="100%" mobileMargin="16px 0 0 0">
                                <DataCard
                                    name="Referrals" value={loaded ? referrals : 0} gradientDirection
                                />
                            </FlexItem>
                        </FlexContainer>
                        <FlexItem margin="24px 0 0 0" mobileMargin="24px 0 0 0">
                            <Table
                                headerTitles={headerTitles}
                                bodyValues={referredUserList}
                                rowKey="user_id"
                                pagination={pagination}
                                nextPage={nextPage}
                                prevPage={prevPage}
                                changePage={changePage}
                                withPagination={totalPages > 1}
                                textAlign="end"
                            />
                        </FlexItem>
                    </FlexContainer>
                ) : (
                    <Loader />
                )}
            </Layout>
        </>
    );
};

export default ReferredUsers;
