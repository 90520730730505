import axios from "axios";
// eslint-disable-next-line no-duplicate-imports
import type { AxiosRequestConfig, AxiosResponse, AxiosInstance as AxiosInstanceType, CreateAxiosDefaults } from "axios";
import EventEmitter from "eventemitter3";
import { CallbackFn } from "@ntropy/utils/src/typescript-helpers";

type IAxiosInstanceExtraConfig = {

} & ({} | {
    tokenLocalStorageKey?: string
    tokenHeaderKey?: string
})

/* eslint-disable max-len */
class AxiosInstance<TEndpoint extends string = string> {
    instance: AxiosInstanceType;
    tokenLocalStorageKey?: string;
    tokenHeaderKey?: string;
    #eventEmitter = new EventEmitter<TEndpoint | string>();

    get tokenValue() {
        if (!this.tokenLocalStorageKey) {
            return undefined;
        }

        const rawValue = window.localStorage.getItem(this.tokenLocalStorageKey);

        if (!rawValue) {
            return undefined;
        }

        try {
            return JSON.parse(rawValue!) as string;
        } catch {
            return undefined;
        }
    }

    constructor(baseUrl: string, config: CreateAxiosDefaults & IAxiosInstanceExtraConfig = {}) {
        this.tokenLocalStorageKey = "tokenLocalStorageKey" in config ? config.tokenLocalStorageKey : undefined;
        this.tokenHeaderKey = "tokenHeaderKey" in config ? config.tokenHeaderKey : undefined;

        this.instance = axios.create({
            baseURL: baseUrl,
            timeout: 1000 * 20,
            withCredentials: true,
            ...config,
        })

        this.instance.interceptors.response.use(
            (response: AxiosResponse) => response,
            error => Promise.reject(error.response ? { status: error.response.status, ...error.response.data } : error),
        );
    }

    #prepareConfig = (config?: AxiosRequestConfig): AxiosRequestConfig | undefined => {
        const tokenValue = this.tokenValue;

        if (!tokenValue || !this.tokenHeaderKey) {
            return config;
        }

        return {
            ...config ?? {},
            headers: {
                ...config?.headers ?? {},
                [this.tokenHeaderKey!]: tokenValue,
            },
        }
    }

    get = async <TResponse>(url: TEndpoint | string, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>["data"]> => {
        const response = await this.instance.get<TResponse>(url, this.#prepareConfig(config));

        this.#eventEmitter.emit(url);

        return response.data;
    };

    post = async <TPayload, TResponse = {}>(url: TEndpoint | string, data?: TPayload, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>["data"]> => {
        const response = await this.instance.post<TResponse>(url, data, this.#prepareConfig(config));

        this.#eventEmitter.emit(url);

        return response.data;
    };

    put = async <TPayload, TResponse = {}>(url: TEndpoint | string, data?: TPayload, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>["data"]> => {
        const response = await this.instance.put<TResponse>(url, data, this.#prepareConfig(config));

        this.#eventEmitter.emit(url);

        return response.data;
    };

    patch = async <TPayload, TResponse = {}>(url: TEndpoint | string, data?: TPayload, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>["data"]> => {
        const response = await this.instance.patch<TResponse>(url, data, this.#prepareConfig(config));

        this.#eventEmitter.emit(url);

        return response.data;
    };

    delete = async <TResponse>(url: TEndpoint | string, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>["data"]> => {
        const response = await this.instance.delete<TResponse>(url, this.#prepareConfig(config));

        this.#eventEmitter.emit(url);

        return response.data;
    };

    on = (url: TEndpoint | string, callback: CallbackFn) => {
        this.#eventEmitter.on(url, callback);
    };

    off = (url: TEndpoint | string, callback: CallbackFn) => {
        this.#eventEmitter.off(url, callback);
    };

    once = (url: TEndpoint | string, callback: CallbackFn) => {
        this.#eventEmitter.once(url, callback);
    };
}

export default AxiosInstance;